import { useState } from 'react'
import { Formik, Field } from 'formik'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { jwtDecode } from 'jwt-decode'

import './Login.scss'
import BackButton from '../../reusableComponents/BackButton/BackButton'
import { ReactComponent as TenturLogo2Icon } from '../../icons/tentur_logo2.svg'
import Input from '../../reusableComponents/Input/Input'
import Button from '../../reusableComponents/Button/Button'
import { useLoginMutation } from '../../redux/authService/authApiSlice'
import {
	resetAuth,
	setAccessToken,
	setAuthorized,
	setRefreshToken,
	setAccessTokenTimeExpiration,
	setRefreshTokenTimeExpiration
} from '../../redux/authService/authSlice'
import { isValidEmail, setItemInLocalStorage } from '../../utils/functions'
import ErrorLink from '../../reusableComponents/ErrorLink/ErrorLink'

function Login() {
	const { t } = useTranslation()
	const location = useLocation()
	const [logIn] = useLoginMutation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const queryParams = new URLSearchParams(location.search)
	const redirectTournament = queryParams.get('tournament')
	const redirectPath = queryParams.get('redirect')
	const [loginOrEmail, setLoginOrEmail] = useState('')

	// Валидируем Email / Логин
	async function validateEmailOrLogin(valueEmailOrLogin) {
		if (valueEmailOrLogin.length > 0) {
			if (valueEmailOrLogin.indexOf('@') !== -1) {
				if (!isValidEmail(valueEmailOrLogin)) {
					return t('Invalid email')
				} else {
					setLoginOrEmail({ email: valueEmailOrLogin })
				}
			} else {
				setLoginOrEmail({ login: valueEmailOrLogin })
			}
		} else {
			return t('Enter email or login')
		}
	}

	// Валидируем пароль
	function validatePassword(valuePassword) {
		if (valuePassword.length === 0) {
			return t('Enter password')
		}

		// else if (valuePassword?.length < 8) {
		// 	return 'Длина пароля должна быть не меньше 8 символов'
		// }
	}

	return (
		<main className="login">
			<div className="login__back-wrapper">
				<BackButton nav={-1} />
			</div>

			<div className="login__logo-wrapper">
				<TenturLogo2Icon className="tentur-logo__icon" />
			</div>

			{
				redirectTournament &&
				<ErrorLink />
			}

			<div className="login__form">
				<h1 className="login__form-title">
					Вход
				</h1>

				<p className="login__form-description">
					Войдите для доступа к своим турнирам
				</p>

				<Formik
					initialValues={{
						emailOrLogin: '',
						password: '',
					}}
					onSubmit={async (values, { setFieldError }) => {
						try {
							let body = {}

							if ('email' in loginOrEmail) {
								body = { email: loginOrEmail.email, password: values.password }
							} else if ('login' in loginOrEmail) {
								body = { username: loginOrEmail.login, password: values.password }
							}

							const response = await logIn(body).unwrap()

							dispatch(setRefreshToken(response?.refresh))
							dispatch(setAccessToken(response?.access))
							dispatch(setAuthorized(true))

							const accessExpiresAtUTC = jwtDecode(response?.access).exp * 1000
							const refreshTokenExpiresAtUTC = jwtDecode(response?.refresh).exp  * 1000
							dispatch(setAccessTokenTimeExpiration(accessExpiresAtUTC))
							dispatch(setRefreshTokenTimeExpiration(refreshTokenExpiresAtUTC))

							if (redirectPath) {
								navigate(`/communities?redirect=${redirectPath}`)
							} else {
								navigate('/communities')
							}
						} catch (e) {
							if (e?.status === 401) {
								setFieldError('password', t('Incorrect password'))
								setFieldError('emailOrLogin', t('Incorrect email or login'))
							}

							console.log('Login catch error', e)

							dispatch(resetAuth())
							setItemInLocalStorage('wipe_reason', 'login error')
						}
					}}
				>
					{({ values, errors, touched, handleSubmit, handleChange, setFieldError }) => (
						<form
							onSubmit={handleSubmit}
							noValidate
						>
							<Field
								as={Input}
								type="text"
								validate={validateEmailOrLogin}
								onChange={handleChange}
								value={values.emailOrLogin}
								placeholder="Email или логин"
								label="Email / Логин"
								required={true}
								error={errors.emailOrLogin}
								touched={touched.emailOrLogin}
								name="emailOrLogin"
								onClick={() => {
									setFieldError('emailOrLogin', '')
									setFieldError('password', '')
								}}
							/>

							<Field
								as={Input}
								type="password"
								validate={validatePassword}
								onChange={handleChange}
								value={values.password}
								placeholder="Пароль"
								label="Пароль"
								required={true}
								error={errors.password}
								touched={touched.password}
								name="password"
								onClick={() => {
									setFieldError('password', '')
									setFieldError('emailOrLogin', '')
								}}
							/>

							<Button
								title="Войти"
								type="submit"
							/>
						</form>
					)}
				</Formik>

				{/* <Link
					to="/password-recovery"
					className="login__reset-password"
				>
					Я не помню пароль
				</Link> */}
			</div>
		</main>
	)
}

export default Login