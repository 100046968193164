import { createSlice } from '@reduxjs/toolkit'

import { getSessionStorageItem, parseObject, setItemInSessionStorage } from '../../utils/functions'

const matchSlice = createSlice({
	name: 'match',
	initialState: {
		match: parseObject('match', getSessionStorageItem) || {},
		matchSession: parseObject('match', getSessionStorageItem) || {},
		tiebreak: false,
		activeSetIndex: 0,
		shownScoreModal: false
	},
	reducers: {
		setMatchData(state, action) {
			state.match = action.payload
		},
		setMatchSession(state, action) {
			setItemInSessionStorage('match', JSON.stringify(action.payload))
			state.match = action.payload
		},
		setTiebreak(state, action) {
			state.tiebreak = action.payload
		},
		setActiveSetIndex(state, action) {
			state.activeSetIndex = action.payload
		},
		shownScoreModal(state, action) {
			state.shownScoreModal = action.payload
		},
	}
})

export const {
	setMatchData,
	setTiebreak,
	setActiveSetIndex,
	setMatchSession
} = matchSlice.actions
export default matchSlice.reducer