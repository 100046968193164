import { memo } from 'react'

import TournamentMenu from '../TournamentMenu/TournamentMenu'
import StagesMenu from '../StagesMenu/StagesMenu'

function CombineTournamentMenu({
	structure
}) {
	return (
		<TournamentMenu>
			<StagesMenu
				structure={structure}
			/>
		</TournamentMenu>
	)
}

export default memo(CombineTournamentMenu)