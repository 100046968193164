import './CategoryForm.scss'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useUpdateCategoryMutation, useAddCategoryMutation } from '../../redux/categoriesService/categoriesApiSlice.js'
import Header from '../../reusableComponents/Header/Header.jsx'
import Input from '../../reusableComponents/Input/Input.jsx'
import Button from '../../reusableComponents/Button/Button.jsx'
import BottomMenu from '../../reusableComponents/BottomMenu/BottomMenu.jsx'

const CategoryForm = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const updatedCategory = JSON.parse(sessionStorage.getItem('updatedCategory'))
	const communityUid = localStorage.getItem('communityUid')
	const [addCategory] = useAddCategoryMutation()
	const [updateCategory] = useUpdateCategoryMutation()

	const [name, setName] = useState(updatedCategory?.name ? updatedCategory?.name : '')
	const [nameErr, setNameErr] = useState(false)
	const [err, setErr] = useState(false)

	const handleSubmit = async (e) => {
		e.preventDefault()

		if (!name) {
			setNameErr(true)
			return
		}

		try {
			const body = {
				'name': name,
				'community_uid': communityUid
			}

			let response

			if (updatedCategory) {
				response = await updateCategory({
					community_uid: communityUid,
					body,
					category_uid: updatedCategory?.uid
				}).unwrap()
			} else {
				response = await addCategory({ community_uid: communityUid, body }).unwrap()
			}

			if (response?.error?.status === 401) {
				navigate('/')
			} else if (response?.error) {
				setErr(true)
				return
			} else {
				navigate('/account/categories')
			}
		} catch (e) {
			console.log('Category creation and updating catch error', e)

			setErr(true)
		}
	}

	return (
		<>
			<Header
				title={updatedCategory ? t('Edition') : t('Add category')}
				backButton={true}
			/>

			<div className="category-form__container">
				<form onSubmit={handleSubmit}>
					<div className="category-form__input">
						<label className="category-form__input-label">
							{t('Name')}
						</label>

						<Input
							value={name}
							onChange={(e) => setName(e.target.value)}
							onClick={() => setNameErr(false)}
							type="text"
							borderColor={nameErr ? '#F20505' : ''}
							required={false}
						/>

						{
							nameErr &&
								<p className="category-form__error">
									{t('Enter name')}
								</p>
						}
					</div>

					{
						err && 
							<p className="category-form__main-error">
								{t('Something went wrong. Try later please')}
							</p>
					}

					<div className="category-form__submit">
						<Button type={'submit'} title={t('Save')} />
					</div>
				</form>
			</div>

			<BottomMenu />
		</>
	)
}

export default CategoryForm
