import { apiSlice } from '../apiSlice'

export const categoriesApi = apiSlice.injectEndpoints({
	reducerPath: 'categoriesApi',
	tagTypes: ['Categories'],
	endpoints: (build) => ({
		getCategories: build.query({
			query: ({community_uid}) => `tennis/communities/${community_uid}/categories/`,
			providesTags: ['Categories']
		}),
		addCategory: build.mutation({
			query: ({ body }) => ({
				url: 'tennis/categories/',
				method: 'POST',
				body
			}),
			invalidatesTags: ['Categories']
		}),
		updateCategory: build.mutation({
			query: ({ body, category_uid }) => ({
				url: `tennis/categories/${category_uid}/`,
				method: 'PATCH',
				body
			}),
			invalidatesTags: ['Categories']
		}),
		removeCategory: build.mutation({
			query: ({ category_uid }) => ({
				url: `tennis/categories/${category_uid}/`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Categories']
		}),
	})
})

export const {
	useGetCategoriesQuery,
	useAddCategoryMutation,
	useUpdateCategoryMutation,
	useRemoveCategoryMutation
} = categoriesApi