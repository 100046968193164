import { BUILD, DRAWS, GENERATION, SCHEDULE, NODE_TYPE_IN, excludedErrors } from './constants'

function processParticipants(array) {
	function hasDuplicates(arr) {
		const seen = new Set()
		for (const item of arr) {
			if (seen.has(item.uid)) {
				return true
			}
			seen.add(item.uid)
		}
		return false
	}

	if (hasDuplicates(array)) {
		return removeDuplicates(array)
	} else {
		return array
	}
}

function removeDuplicates(arr) {
	const seen = new Set()
	return arr.filter(item => {
		if (seen.has(item.uid)) {
			return false
		}
		seen.add(item.uid)
		return true
	})
}

function getExtraPath(location, number) {
	let extraPath = ''

	const currentPathParts = location?.pathname.split('/')
	const screenPath = `/${currentPathParts[1]}/${currentPathParts[2]}/${number}`

	if (currentPathParts?.includes(DRAWS) || currentPathParts?.includes(SCHEDULE)) {
		return screenPath
	} else {
		extraPath =
			currentPathParts?.includes(BUILD)
				? BUILD : currentPathParts?.includes(GENERATION)
					? GENERATION : extraPath

		return screenPath + '/' + extraPath
	}
}

function levelsHaveGroups(levels) {
	if (!levels) return
	return levels.some(lvl => lvl?.groups?.length > 0)
}

function itemsChangedOrder(participants, immutableState) {
	let newArray = participants
	let savedArray = immutableState

	if (participants.length > immutableState.length) {
		newArray = newArray.slice(0, immutableState.length)
	} else if (immutableState.length > participants.length) {
		savedArray = savedArray.slice(0, participants.length)
	}

	return checkParticipantsOrder(newArray, savedArray)
}

function checkParticipantsOrder(participants, immutableState) {
	const orderIsChanged = (
		immutableState?.length > 0 &&
		immutableState.some((state, i) => {
			if (!participants[i]?.player1) {
				return state?.uid !== participants[i]?.uid
			} else {
				return state.player1.uid !== participants[i].player1.uid
			}
		})
	)

	return orderIsChanged
}

function getActiveSet(sets, activeSetIndex) {
	return { ...sets[activeSetIndex] }
}

function formNodesForRequest(editedLevels, playersList) {
	const editedNodes = []

	editedLevels?.forEach(lvl => {
		lvl?.groups?.forEach(grp => {
			grp?.nodes?.forEach(n => {
				editedNodes.push(n)
			})
		})
	})

	const updatedNodes = editedNodes.map(editedNode => {
		const matchingPlayer = playersList.find(player => editedNode.name && player.name === editedNode.name)
		return {
			uid: editedNode.nodeUid,
			depends_on_uid: matchingPlayer?.nodeUid || null
		}
	})

	return { nodes: updatedNodes }
}

function getParticipantsWithNewPlayer(player, participants) {
	const newPlayer = {
		first_name: player.first_name,
		last_name: player.last_name,
		middle_name: player.middle_name,
		display_name: null,
		gender: player.gender,
		uid: player.uid,
		birth_date: player.birth_date,
		birth_year: player.birth_year,
	}

	return { participants: [...participants, newPlayer], newPlayer }
}

// Функция для обработки групп и игроков
function processGroup(group, players, doubles) {
	if (Object.keys(group).length === 0) {
		return []
	}

	// Получаем имена игроков по группам
	const gridArr = group.nodes.flatMap(node => {
		let playerObject

		if (node.type === NODE_TYPE_IN) {
			if (node.double_uid === null && node.player_uid === null) {
				// Если нет double_uid и player_uid
				return node
			} else if (players) {
				// Одиночный
				playerObject = players?.find(player => player.uid === node.player_uid)

				if (playerObject) {
					playerObject = { ...playerObject, name: node.name }
				}
			} else {
				// Парный
				playerObject = doubles?.find(doublesPlayer => doublesPlayer.uid === node.double_uid)

				if (playerObject) {
					playerObject = { ...playerObject, name: node.name }
				}
			}

			if (playerObject) {
				// Возвращаем объект с установленным nodeUid
				return {
					...playerObject,
					nodeUid: node?.uid
				}
			}
		}

		return null // Возвращаем null для элементов, которые не прошли фильтр
	}).filter(player => player !== null)

	return gridArr
}

function isExeptedError(errorData = {}) {
	if (errorData?.detail || errorData?.email) {
		const errorText = errorData?.detail?.replace(' ', '').toLowerCase()
			|| errorData?.email[0]?.replace(' ', '').toLowerCase() || ''

		const exeptions = excludedErrors

		return exeptions.some(item => item.replace(' ', '').toLowerCase() === errorText)
	}

	return false
}

// Заменяем на нужный нам формат Phone
function replacePhone(phoneNuber) {
	if (phoneNuber.startsWith('8')) {
		return `+7${phoneNuber.slice(1)}`

	} else if (phoneNuber.startsWith('9')) {
		return `+7${phoneNuber}`

	} else if (phoneNuber.startsWith('+7')) {
		return `${phoneNuber}`
	}
}

export {
	processParticipants,
	getExtraPath,
	levelsHaveGroups,
	itemsChangedOrder,
	getActiveSet,
	formNodesForRequest,
	getParticipantsWithNewPlayer,
	processGroup,
	isExeptedError,
	replacePhone
}