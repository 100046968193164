function placeParticipants(playersList, addedNode, replacedNode) {
	return playersList.map(item => {
		if ((replacedNode.playerUid && item.playerUid === replacedNode.playerUid)
			|| (replacedNode.doubleUid && item.doubleUid === replacedNode.doubleUid)
		) {
			// если uid участников равны в удаляемой ноде и ноде из списка
			return {
				...item,
				placed: false
			}
		} else if ((addedNode.playerUid && item.playerUid === addedNode.playerUid)
			|| (addedNode.doubleUid && item.doubleUid === addedNode.doubleUid)
		) {
			// если uid участников равны в добавляемой ноде и ноде из списка
			return {
				...item,
				placed: true
			}
		} else if (!replacedNode.playerUid && !replacedNode.doubleUid && replacedNode.name && (
			(replacedNode.dependsOnUid && item.nodeUid === replacedNode.dependsOnUid
				&& replacedNode.dependsOnUid !== addedNode.nodeUid)
			||
			(replacedNode.name && item.name === replacedNode.name
				&& replacedNode.name !== addedNode.name)
		)) {
			// если удаляемая нода не имеет участника и она равна ноде в списке, но не равна добавляемой ноде
			return {
				...item,
				placed: false
			}
		} else if (!addedNode.playerUid && !addedNode.doubleUid && addedNode.name && (
			(addedNode.dependsOnUid && item.nodeUid === addedNode.dependsOnUid
				&& addedNode.dependsOnUid !== replacedNode.nodeUid)
			||
			(addedNode.name && item.name === addedNode.name
				&& addedNode.name !== replacedNode.name)
		))  {
			// если добавляемая нода не имеет участника и она равна ноде в списке, но не равна удаляемой ноде
			return {
				...item,
				placed: true
			}
		} else if (!replacedNode.playerUid && !replacedNode.doubleUid
			&& !replacedNode.name
			&& ((!addedNode.playerUid && !addedNode.doubleUid && addedNode.name === item.name)
				|| (addedNode.playerUid && addedNode.playerUid === item.playerUid)
				|| (addedNode.doubleUid && addedNode.doubleUid === item.doubleUid))) {
			// если удаляемая нода пустая абсолютно, а добавляемая нода равна ноде из списка
			return {
				...item,
				placed: true
			}
		} else if (!addedNode.playerUid && !addedNode.doubleUid && !addedNode.name && replacedNode.name === item.name) {
			// если добавляемая нода пустая абсолютно, а удаляемая нода равна ноде из списка
			return {
				...item,
				placed: false
			}
		}else {
			return item
		}
	})
}

export {
	placeParticipants
}