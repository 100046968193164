import { memo, useState, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import './StageGeneration.scss'
import { ReactComponent as PlusWhiteIcon } from '../../../icons/plus_white.svg'
import { ReactComponent as RemoveIcon } from '../../../icons/remove.svg'
import RoundedButton from '../../../reusableComponents/RoundedButton/RoundedButton'
import { selectInitialStage, selectStageCondition, selectTournament, selectTournamentOptions } from '../../../redux/selectors'
import {
	useCreateStageMutation,
	useGenerateStageMutation
} from '../../../redux/stageService/stageApiSlice'
import {
	setPlayersList,
	setCurrentStageNumber
} from '../../../redux/stageService/stageSlice'
import { formNodes } from '../../../utils/functions'

import { CREATE, FILLED } from '../../../utils/constants'

import { calculateNumberOfGroups, calculateNumberOfPlayers } from './externalFunctions'

function StageGeneration() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const { stageNumber } = useParams()
	const tournament = useSelector(selectTournament)
	const { type, doubles, players, stages_count, uid: tournamentUid } = tournament
	const { settings = {}, stage: options = {} } = useSelector(selectTournamentOptions)
	const { stageStatus, stageExists } = useSelector(selectStageCondition)
	const initialStage = useSelector(selectInitialStage)

	const [createStage] = useCreateStageMutation()
	const [generateStage] = useGenerateStageMutation()

	const [requestIsActive, setRequestIsActive] = useState({ generateStage: false })
	const [groupType, setGroupType] = useState(1)
	const [count, setCount] = useState(0)

	const autogenIsAllowed = (players && players?.length  > 1) || (doubles && doubles?.length > 1)
	const participantsCount = initialStage?.rating_initial?.length || players?.length || doubles?.length || 0

	useEffect(() => {
		if (initialStage && Object.keys(initialStage).length !== 0) {
			const { rating_initial } = initialStage
			const formedNodes = formNodes(rating_initial, players || doubles, type, initialStage)

			dispatch(setPlayersList(formedNodes))
		}
	}, [initialStage, stageNumber, type, doubles, players, dispatch])

	useEffect(() => {
		if (groupType === 1) {
			setCount(calculateNumberOfGroups(participantsCount))
		} else {
			setCount(calculateNumberOfPlayers(participantsCount))
		}
	}, [participantsCount, players, doubles, initialStage, groupType])

	const limitIsReached = useMemo(() => {
		const preLimitParticipants = settings?.maxParticipants - 1
		const participantsCount = players ? players?.length : doubles?.length

		if (count > preLimitParticipants || count >= participantsCount) {
			return true
		} else {
			return false
		}
	}, [settings?.maxParticipants, count, players, doubles])

	const handleGroupType = useCallback((number) => {
		if (number === 1) {
			setGroupType(1)
		} else if (number === 2) {
			setGroupType(2)
		}
	}, [])

	const handleMinus = useCallback(() => {
		if (count <= 1) {
			return
		} else {
			setCount(prev => --prev)
		}
	}, [count])

	const handlePlus = useCallback(() => {
		if (limitIsReached) {
			return
		}

		setCount(prev => ++prev)
	}, [limitIsReached])

	async function addStage() {
		try {
			const response = await createStage({ tournament_uid: tournamentUid }).unwrap()

			dispatch(setCurrentStageNumber(response.order_number))
		}
		catch (e) {
			console.log('authomatical creation in stageCreation catch error', e)
		}
	}

	const generateStageSubmit = (async () => {
		if (!autogenIsAllowed || count < 1) {
			return
		}

		setRequestIsActive(prev => { return { ...prev, generateStage: true } })

		if (!stageExists) {
			await addStage()
		}

		try {
			let body = null

			if (groupType === 1) {
				body = {
					'generate_method': 'equal',
					'groups_count': count
				}
			} else if (groupType === 2) {
				body = {
					'generate_method': 'unequal',
					'group1_participants_count': count
				}
			}

			const response = await generateStage({ tournament_uid: tournamentUid, stageNumber, body }).unwrap()
			setRequestIsActive(prev => { return { ...prev, generateStage: false } })

			if (options?.modes?.tabsAreVisible) {
				navigate(`/${tournamentUid}/structure/${response?.order_number}`)
			} else {
				const path = location.pathname.split('/')[2]
				navigate(`/${tournamentUid}/${path}/${stageNumber}`)
			}
		}
		catch (e) {
			console.log('authomatical creation in stageCreation catch error', e)
			setRequestIsActive(prev => { return { ...prev, generateStage: false } })
		}
	})

	if (!stageExists && options?.modes?.tabsAreVisible) {
		// предотвращет двойной рендер когда нет ни одного этапа
		// и режим турнира позволяет использовать вкладки структуры
		return
	}

	return (
		<div className={`stage-generation ${stages_count > 0 ? 'stage-generation--background' : ''}`}>
			<main className="stage-generation__main">
				<div className="stage-generation__wrapper">
					<div className="stage-generation__container">

						<div className="stage-generation__radio-wrapper">
							<div className="stage-generation__radio-element">
								<input
									id="radio-1"
									type="radio"
									name="stageType"
									value="1"
									checked={groupType === 1 ? true : false}
									onChange={() => handleGroupType(1)}
								/>

								<label
									htmlFor="radio-1"
									className="stage-generation__radio-lable"
								>
									{t('Групповой')}
								</label>
							</div>

							<div className="stage-generation__radio-element">
								<input
									id="radio-2"
									type="radio"
									name="stageType"
									value="2"
									checked={groupType === 2 ? true : false}
									onChange={() => handleGroupType(2)}
								/>

								<label
									htmlFor="radio-2"
									className="stage-generation__radio-lable"
								>
									{t('Основной')}
								</label>
							</div>
						</div>

						<div className="stage-generation__counter-groupe">
							<p className="counter-groupe__name">
								{
									groupType === 1 ?
										'Количество групп'
										:
										'Кол-во участников в 1й группе'
								}
							</p>

							<div className="counter-groupe__container">
								<button
									className="counter-groupe__counter-button"
									onClick={() => handleMinus()}
								>
									<RemoveIcon className="remove-icon" />
								</button>

								<p className="counter-groupe__counter">
									{count}
								</p>

								<button
									className="counter-groupe__counter-button"
									onClick={() => handlePlus()}
								>
									<PlusWhiteIcon className="plus-white__icon" />
								</button>
							</div>
						</div>
					</div>

					{!autogenIsAllowed &&
						<p className="stage-generation__warning-text">Для автогенерации групп требуется более 1 участника</p>
					}

					<div className="stage-generation__button-wrapper">
						<RoundedButton
							title={
								stageStatus === FILLED
									? 'Пересоздать группы' : stageStatus === CREATE
										? 'Создать этап' : 'Создать группы'
							}
							onClick={generateStageSubmit}
							border
							loading={requestIsActive.generateStage}
						/>
					</div>
				</div>
			</main>
		</div>
	)
}

export default memo(StageGeneration)