/* eslint-disable indent */
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import './OlympicPlusGridPrint.scss'
import { selectTournament } from '../../redux/selectors'
import { TYPE_SINGLES, FINAL_COLUMN_NAME, RESULT_COLUMN_NAME } from '../../utils/constants'
import { generateGroupOrLevelsName } from '../../utils/functions'

import { formGrid } from '../../extendedComponents/OlympicPlusGrid/externalFunctions'

function OlympicPlusGrid({ group }) {
	const { t } = useTranslation()
	const { type, players, doubles } = useSelector(selectTournament)

	const grid = useMemo(() => {
		return formGrid(group)
	}, [group])

	const participantsMap = useMemo(() => {
		return new Map((players || doubles)?.map(item =>
			[item.uid, item]
		))
	}, [players, doubles])

	function getMatch(match) {
		const { nodeOne, nodeTwo } = match
		const uidOne = nodeOne?.player_uid || nodeOne?.double_uid
		const uidTwo = nodeTwo?.player_uid || nodeTwo?.double_uid

		if (type === TYPE_SINGLES) {
			const singleOne = participantsMap.get(uidOne)?.formedName
			const singleTwo = participantsMap.get(uidTwo)?.formedName

			return (
				<div className="olympicplus-grid__match-sides--print">
					<p className="olympicplus-grid__match-participant--print">
						{ singleOne }
					</p>

					<p className="olympicplus-grid__match-participant--print">
						{ singleTwo }
					</p>
				</div>
			)
		} else {
			const doubleOne = {
				firstPlayer: participantsMap.get(uidOne)?.player1.formedName,
				secondPlayer: participantsMap.get(uidOne)?.player2.formedName
			}
			const doubleTwo = {
				firstPlayer: participantsMap.get(uidTwo)?.player1.formedName,
				secondPlayer: participantsMap.get(uidTwo)?.player2.formedName
			}

			return (
				<div className="olympicplus-grid__match-sides--print">
					<div className="olympicplus-grid__match-dobles--print">
						<>
							<p className="olympicplus-grid__match-double-player--print">
								{ doubleOne.firstPlayer }
							</p>

							<p className="olympicplus-grid__match-double-player--print">
								{ doubleOne.secondPlayer }
							</p>
						</>
					</div>

					<div className="olympicplus-grid__match-dobles--print">
						<>
							<p className="olympicplus-grid__match-double-player--print">
								{ doubleTwo.firstPlayer }
							</p>

							<p className="olympicplus-grid__match-double-player--print">
								{ doubleTwo.secondPlayer }
							</p>
						</>
					</div>
				</div>
			)
		}
	}

	function matchStyle(column, columnIndex, cell ,index) {
		let style = {}

		if (index > 0 && column.roundName !== RESULT_COLUMN_NAME) {
			style = {
				backgroundColor: cell?.winner ? '' : 'var(--palette-live-grey-16)'
			}
		} else if (index === 0) {
			style = {
				backgroundColor: ''
			}
		}

		return style
	}

	const maxMatchesInColumn = Math.max(...grid.map(column => column.matches.length))

	return (
		<div className="olympic-plus__grid-wrapper">
			<p className="olympic-plus__grid-group">
				Группа { generateGroupOrLevelsName(group.order_number - 1) }
			</p>

			{/* {
				stage?.levels.length > 1 && (
					<p style={{
						color: 'var(--palette-live-grey-0)',
						fontSize: 'var(--font-size-21)',
						fontWeight: 'var(--font-weight-medium)',
						margin: '10px 0 10px 0'
					}}>
						Уровень { levelIndex + 1 }
					</p>
				)
			} */}

			<table className="olympic-plus__grid-table">
				<thead>
					<tr>
							{
									grid.map((column, columnIndex) => (
											<th key={columnIndex}>
													{column.roundName !== RESULT_COLUMN_NAME ?
															column.roundName > 1 ?
																	`1/${column.roundName}`
																	:
																	t(FINAL_COLUMN_NAME)
															:
															t(column.roundName)
													}
											</th>
									))
							}
					</tr>
				</thead>

				<tbody>
					{
						Array.from({ length: maxMatchesInColumn / 2 }).map((_, rowIndex) => (
							<tr key={rowIndex}>
								{
									grid.map((column, columnIndex) => (
										<td key={columnIndex} className="olympic-td__wrapper" style={matchStyle(column, rowIndex, column.matches[rowIndex], columnIndex)}>
											{
													column.matches[rowIndex] &&
															columnIndex === 0 && column.roundName !== RESULT_COLUMN_NAME ?
																	getMatch(column.matches[rowIndex])
																	:
																	column.roundName === RESULT_COLUMN_NAME ?
																			<table>
																					<tbody>
																							<tr>
																									<td className="olympic-td__element olympic-td__element--width"></td>
																									<td className="olympic-td__element olympic-td__element"></td>
																							</tr>

																							<tr>
																									<td className="olympic-td__element olympic-td__element--background olympic-td__element--width olympic-td__element--bottom-border"></td>
																									<td className="olympic-td__element olympic-td__element--bottom-border"></td>
																							</tr>
																					</tbody>
																			</table>
																			:
																			''
											}
										</td>
									))
								}
							</tr>
						))
					}
				</tbody>
			</table>
		</div>
	)
}

export default OlympicPlusGrid