import { apiSlice } from '../apiSlice'

export const matchApi = apiSlice.injectEndpoints({
	reducerPath: 'matchApi',
	tagTypes: ['Match'],
	endpoints: (build) => ({
		getMatch: build.query({
			query: (uid) => ({
				url: `tennis/matches/${uid}/`,
				method: 'GET'
			}),
			providesTags: ['Match']
		}),
		saveSet: build.mutation({
			query: ({uid, body}) => ({
				url: `tennis/matches/${uid}/`,
				method: 'PUT',
				body
			}),
			invalidatesTags: ['Match', 'Stage', 'Results']
		}),
		updateMatch: build.mutation({
			query: ({uid, body}) => ({
				url: `tennis/matches/${uid}/`,
				method: 'PUT',
				body
			}),
			invalidatesTags: ['Stage', 'Match', 'Results']
		})
	})
})

export const {
	useGetMatchQuery,
	useSaveSetMutation,
	useUpdateMatchMutation
} = matchApi