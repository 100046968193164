import { memo } from 'react'
import { useNavigate } from 'react-router-dom'

import './BackButton.scss'
import { ReactComponent as BackIcon } from '../../icons/arrow_back.svg'

function BackButton({ nav }) {
	const navigate = useNavigate()

	return (
		<button
			className="back-button"
			onClick={() => navigate(nav)}
		>
			<BackIcon className="back-button__icon" />
		</button>
	)
}

export default memo(BackButton)