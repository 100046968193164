import React, { useMemo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import './NotPlayingGrid.scss'
import { selectTournament } from '../../redux/selectors'
import { TYPE_SINGLES, RESULT_COLUMN_NAME, NODE_TYPE_IN } from '../../utils/constants'
import { generateGroupOrLevelsName } from '../../utils/functions'
import { formGrid } from '../OlympicPlusGrid/externalFunctions'
import { ReactComponent as NoPlayIcon } from '../../icons/no_play.svg'
import { processGroup } from '../../utils/functions2'
import Tooltip from '../../reusableComponents/Tooltip/Tooltip'

function NotPlayingGrid({ group }) {
	const [grid, setGrid] = useState([])
	const { type, players, doubles } = useSelector(selectTournament)

	const gridTables = useMemo(() => {
		return formGrid(group)
	}, [group])

	useEffect(() => {
		const gridArr = processGroup(group, players, doubles)
		setGrid(gridArr)
	}, [doubles, players, group])

	const participantsMap = useMemo(() => {
		return new Map((players || doubles)?.map(item =>
			[item.uid, item]
		))
	}, [players, doubles])

	function getPlayerName(player) {
		if (type === TYPE_SINGLES) {
			const participant = participantsMap.get(player.uid)

			return (
				<Tooltip
					content={`${participant?.last_name} ${participant?.first_name || ''} ${participant?.middle_name || ''}`}
					disable={participant?.formedName ? false : true}
				>
					{participant?.formedName || player.name}
				</Tooltip>
			)
		} else {
			const playerOne = participantsMap.get(player.uid)?.player1
			const playerTwo = participantsMap.get(player.uid)?.player2

			return <>
				<Tooltip
					content={`${playerOne?.last_name} ${playerOne?.first_name || ''} ${playerOne?.middle_name || ''}`}
					disable={playerOne?.formedName ? false : true}
				>
					<p className="no-play-grid__match-double-player-wrapper">
						{ playerOne?.formedName || player.name }
					</p>
				</Tooltip>

				<Tooltip
					content={`${playerTwo?.last_name} ${playerTwo?.first_name || ''} ${playerTwo?.middle_name || ''}`}
					disable={playerTwo?.formedName ? false : true}
				>
					<p className="no-play-grid__match-double-player-wrapper">
						{ playerTwo?.formedName || '' }
					</p>
				</Tooltip>
			</>
		}
	}

	return (
		<div className="no-play-grid">
			<div className="no-play-grid__table-wrapper">
				{
					gridTables.map((column, columnIndex) => (
						column.roundName !== RESULT_COLUMN_NAME &&
							<div key={columnIndex} className="no-play-grid__table-container">
								<div className="no-play-grid__table">
									<div className="no-play-grid__row-header">
										<h5 className="no-play-grid__header">
											{ generateGroupOrLevelsName(group.order_number - 1) }
										</h5>

										<NoPlayIcon className="no-play-icon" />
									</div>

									<div>
										{
											grid.map((player, index) => (
												<div key={player.uid} className="no-play-grid__row-table">
													<div className="no-play-grid__match-player-wrapper">
														{ getPlayerName(player) || <span className="empty-player"></span> }
													</div>

													<p className="no-play-grid__index">
														{ index + 1 }
													</p>

													{/* <p className="no-play-grid__mnemonics">
														{ player?.name || '' }
													</p> */}
												</div>
											))
										}
									</div>
								</div>
							</div>
					))
				}
			</div>
		</div>
	)
}

export default NotPlayingGrid