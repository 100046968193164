import './SliderButtons.scss'

import { ReactComponent as Plus } from '../../icons/match_plus.svg'
import { ReactComponent as Minus } from '../../icons/match_minus.svg'

function SliderButtons({setTouchedSlider, slider, side, prevButtonClick, nextButtonClick}) {
	return (
		<div className="keen-slider__buttons">
			<button
				onClick={(e) => {
					e.stopPropagation() || slider?.prev()
					setTouchedSlider(prev => { return { ...prev, [side]: true } })
				}}
				className="slider__button"
			>
				<Minus className="slider__button-icon" />
			</button>

			<button
				onClick={(e) => {
					e.stopPropagation() || slider?.next()
					setTouchedSlider(prev => { return { ...prev, [side]: true } })
				}}
				className="slider__button"
			>
				<Plus className="slider__button-icon" />
			</button>

		</div>
	)
}

export default SliderButtons