import { memo } from 'react'

import './RoundedBlock.scss'

function RoundedBlock({ children, background }) {
	return (
		<div className={`rounded-block ${background ? 'rounded-block--background' : ''}`}>
			{ children }
		</div>
	)
}

export default memo(RoundedBlock)