import { memo } from 'react'

import './ProfileBlock.scss'
import Loader from '../Loader/Loader'

function ProfileBlock({ photo, firstName, lastName, loading, email }) {
	const firstLetter = firstName?.slice(0, 1)
	const secondLetter = lastName?.slice(0, 1)

	function getLetters() {
		if (firstName && lastName) {
			return firstLetter + secondLetter
		} else if (firstName && !lastName) {
			return firstLetter
		} else if (!firstName && lastName) {
			return secondLetter
		} else if (email) {
			return email.slice(0, 2)
		}
	}

	function getNameOrEmail() {
		if (firstName && lastName) {
			return firstName + ' ' + lastName
		} else if (firstName && !lastName) {
			return firstName
		} else if (!firstName && lastName) {
			return lastName
		} else if (email) {
			return email
		}
	}

	return (
		<div className="profile-block">
			{
				loading ?
					<Loader />
					:
					<>
						{
							photo ?
								<img src={photo} className="profile__photo" alt="" />
								:
								<p className="profile__photo-letters">
									{ getLetters() }
								</p>
						}

						<p className="profile__subtitle">
							{ getNameOrEmail() }
						</p>
					</>
			}
		</div>
	)
}

export default memo(ProfileBlock)