import { TYPE_SINGLES } from '../../utils/constants'

function formArraysForRequests(doneActions, type) {
	const { add, remove } = doneActions
	let added = []
	let removed = []

	// собираем uid добавленных
	if (add?.length > 0) {
		if (type === TYPE_SINGLES) {
			added = add.map(item => item.uid)
		} else {
			const array = add.map(item => {
				return {
					player1: item.player1.uid,
					player2: item.player2.uid
				}
			})

			added = array
		}
	}

	// собираем uid удаленных
	if (remove?.length > 0) {
		const array = remove.map(item => item.uid)
		removed = array
	}

	return {
		added,
		removed
	}
}

function saveIsAllowed(doneActions, permissions) {
	// сравниваем что сделал юзер и что ему разрешено в текущем состоянии турнира
	const { add, remove, seed } = doneActions
	const { add: addEnable, remove: removeEnable, seed: seedEnable } = permissions

	if (!removeEnable && remove.length > 0) {
		return false
	} else if (!addEnable && add.length > 0) {
		return false
	} else if (!seedEnable && seed) {
		return false
	}

	return true
}

function updateDoublesUids(savedParticipants, updatedParticipants) {
	// заменяем фэйковые uid пар на настоящие

	return savedParticipants.map(el => {
		const newDouble = updatedParticipants.find(item =>
			el.player1.uid === item.player1_uid && el.player2.uid === item.player2_uid
		)

		if (newDouble) {
			return {
				...el,
				uid: newDouble.uid
			}
		} else {
			return el
		}
	})
}

function filterRemovedParticipants(savedParticipants, updatedParticipants) {
	return savedParticipants.filter(item => updatedParticipants.some(el => item.uid === el.uid))
}

function isParticipantsListsEqual(doneActions) {
	const { add, remove, seed, doublesAreChanged } = doneActions

	if (add.length > 0 || remove.length > 0 || seed || doublesAreChanged) {
		return true
	}

	return false
}

export {
	isParticipantsListsEqual,
	saveIsAllowed,
	formArraysForRequests,
	updateDoublesUids,
	filterRemovedParticipants
}