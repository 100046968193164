import { TYPE_SINGLES } from '../../utils/constants'
import { getNewUid, moldSearchableWord } from '../../utils/functions'

function validateNewPLayersList(players, tournamentList, type, communityPlayers) {
	let currentList = [...players]
	const emptyField = 'Заполните поле'
	const isBusy = 'Участник уже есть в турнире'
	let isValid = true
	const foundInCommunity = {}

	for (let i = 0; i < currentList.length; i++) {
		const player = {...currentList[i]}

		if (i === currentList.length - 1) {
			// пропускаем последний элемент, так как он добавочный и всегда пустой
			continue
		}

		if (player.value.replace(' ', '').length < 1) {
			player.error = emptyField
			isValid = false
			currentList[i] = player
			continue
		}

		const foundPlayers = findPlayerInCommunity(player.value, communityPlayers)

		if (foundPlayers.length > 0) {
			// создаем объект массивов найденых игроков, где ключи это индексы игроков
			foundInCommunity[`${player.index}`] = foundPlayers
			player.inCommunity = true

			const existsInTournament = findPlayerInTournament(foundInCommunity, tournamentList, type, player.index)

			if (existsInTournament.length > 0) {
				player.error = isBusy
				player.isBusy = true
				isValid = false
			}
		}

		currentList[i] = player
	}

	currentList = currentList.slice(0, -1)

	return { checkedList: currentList, isValid, foundInCommunity }
}

function findPlayerInTournament(existInCommunity, tournamentList, type, playerIndex) {
	const foundedPlayers = existInCommunity[`${playerIndex}`]

	return tournamentList.filter(item => foundedPlayers.some(el => {
		if (type === TYPE_SINGLES) {
			return item.uid === el.uid
		} else {
			return item.player1.uid === el.uid || item.player2.uid === el.uid
		}
	}))
}

function getDoubles(players) {
	const pairs = []
	let withoutPair = []

	for (let i = 0; i < players.length; i += 2) {
		if (i + 1 < players.length) {
			const newPair = {
				uid: getNewUid(),
				player1:players[i],
				player2: players[i + 1]
			}
			pairs.push(newPair)
		} else {
			withoutPair = players[i]
		}
	}

	return { pairs, withoutPair }
}

function findPlayerInCommunity(player, listOfPlayers) {
	const [word1, word2, word3] = player.split(' ').map(moldSearchableWord)

	return listOfPlayers.filter(item => {
		const lastName = moldSearchableWord(item?.last_name)
		const firstName = moldSearchableWord(item?.first_name)
		const middleName = moldSearchableWord(item?.middle_name)

		if (word1 && !word2 && !word3) {
			return lastName === word1 || firstName === word1 || middleName === word1

		} else if (word1 && word2 && !word3) {
			const itemStr1 = moldSearchableWord(`${lastName}${firstName}`)
			const itemStr2 = moldSearchableWord(`${lastName}${middleName}`)
			const itemStr3 = moldSearchableWord(`${firstName}${lastName}`)
			const itemStr4 = moldSearchableWord(`${firstName}${middleName}`)
			const itemStr5 = moldSearchableWord(`${middleName}${firstName}`)
			const itemStr6 = moldSearchableWord(`${middleName}${lastName}`)

			const searchStr = moldSearchableWord(`${word1}${word2}`)

			return itemStr1 === searchStr
				|| itemStr2 === searchStr
				|| itemStr3 === searchStr
				|| itemStr4 === searchStr
				|| itemStr5 === searchStr
				|| itemStr6 === searchStr
		} else {
			const itemStr1 = moldSearchableWord(`${lastName}${firstName}${middleName}`)
			const itemStr2 = moldSearchableWord(`${lastName}${middleName}${firstName}`)
			const itemStr3 = moldSearchableWord(`${firstName}${lastName}${middleName}`)
			const itemStr4 = moldSearchableWord(`${firstName}${middleName}${lastName}`)
			const itemStr5 = moldSearchableWord(`${middleName}${firstName}${lastName}`)
			const itemStr6 = moldSearchableWord(`${middleName}${lastName}${firstName}`)

			const searchStr = moldSearchableWord(`${word1}${word2}${word3}`)

			return itemStr1 === searchStr
				|| itemStr2 === searchStr
				|| itemStr3 === searchStr
				|| itemStr4 === searchStr
				|| itemStr5 === searchStr
				|| itemStr6 === searchStr
		}
	})
}

export {
	validateNewPLayersList,
	getDoubles
}
