import { apiSlice } from '../apiSlice'

export const communitiesApi = apiSlice.injectEndpoints({
	reducerPath: 'communitiesApi',
	tagTypes: ['Communities'],
	endpoints: (build) => ({
		getCommunities: build.query({
			query: () => '/tennis/communities/',
			providesTags: ['Communities']
		}),
		createCommunity: build.mutation({
			query: (body) => ({
				url: '/tennis/communities/',
				method: 'POST',
				body
			}),
			invalidatesTags: ['Communities']
		})
	})
})

export const {
	useGetCommunitiesQuery,
	useCreateCommunityMutation
} = communitiesApi
