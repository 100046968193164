import { memo } from 'react'

import './RadioButton.scss'

function RadioButton({ value, name, onChange, checked, label, id, borderBottom, borderTop }) {
	return (
		<>
			<div className={`radio-button ${borderBottom ? 'radio-button--bottom' : ''} ${borderTop ? 'radio-button--top' : ''}`}>
				<input
					type="radio"
					className="radio-button__input"
					id={id}
					value={value}
					name={name}
					onChange={onChange}
					checked={checked}
				/>

				<label htmlFor={id}>
					{ label }
				</label>
			</div>
		</>
	)
}

export default memo(RadioButton)