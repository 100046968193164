import { useEffect, useRef, useState } from 'react'
import { useKeenSlider } from 'keen-slider/react'

import './Slider.scss'

const centeredSlidesOption = 'center'
const autoSlidesOption = 'auto'

export default function Slider(props) {
	const perspective = props.perspective || centeredSlidesOption
	const sliderSize = 8
	const slides = props.length
	const slideDegree = 360 / sliderSize
	const slidesPerView = 3
	const [sliderState, setSliderState] = useState(null)
	const size = useRef(0)
	const options = useRef({
		...props.options,
		slides: {
			number: slides,
			origin: props.loop ? centeredSlidesOption : autoSlidesOption,
			perView: slidesPerView,
		},

		vertical: true,

		loop: props.loop,
		dragSpeed: (val) => {
			const height = size.current
			return (
				val *
				(height /
					((height / 2) * Math.tan(slideDegree / 1.6 * (Math.PI / 180))) /
					slidesPerView)
			)
		},
		created: (s) => {
			props.setSlider(slider)
			size.current = s.size
			props.setLoaded(true)
		},
		updated: (s) => {
			size.current = s.size
		},
		detailsChanged: (s) => {
			setSliderState(s.track.details)
		},
		rubberband: !props.loop,
		mode: 'free-snap',
	})

	const [sliderRef, slider] = useKeenSlider(options.current)

	const [radius, setRadius] = useState(0)

	useEffect(() => {
		if (slider.current) setRadius(slider.current.size / 2)
	}, [slider])

	function slideValues() {
		if (!sliderState) return []
		const offset = props.loop ? 1 / 2 - 1 / slidesPerView / 2 : 0

		const values = []
		for (let i = 0; i < slides; i++) {
			const distance = sliderState
				? (sliderState.slides[i].distance - offset) * slidesPerView
				: 0
			const rotate =
				Math.abs(distance) > sliderSize / 2
					? 180
					: distance * (360 / sliderSize) * -1
			const style = {
				transform: `rotateX(${rotate}deg) translateZ(${radius}px)`,
				WebkitTransform: `rotateX(${rotate}deg) translateZ(${radius}px)`,
			}
			const value = props.setValue
				? props.setValue(i, sliderState.abs + Math.round(distance))
				: i
			values.push({ style, value })
		}
		return values
	}

	return (
		<div
			className={'slider keen-slider slider--perspective-' + perspective}
			ref={sliderRef}
		>
			<div
				className="slider__shadow-top"
				style={{
					transform: `translateZ(${radius}px)`,
					WebkitTransform: `translateZ(${radius}px)`,
				}}
			/>
			<div className="slider__inner">
				<div className="slider__slides" style={{ width: props.width + 'px' }}>
					{slideValues().map(({ style, value }, idx) => (
						<div className="slider__slide" style={style} key={idx}>
							<span>{value}</span>
						</div>
					))}
				</div>
				{props.label && (
					<div
						className="slider__label"
						style={{
							transform: `translateZ(${radius}px)`,
							WebkitTransform: `translateZ(${radius}px)`,
						}}
					>
						{props.label}
					</div>
				)}
			</div>
			<div
				className="slider__shadow-bottom"
				style={{
					transform: `translateZ(${radius}px)`,
					WebkitTransform: `translateZ(${radius}px)`,
				}}
			/>
		</div>
	)
}
