import './Categories.scss'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useGetCategoriesQuery, useRemoveCategoryMutation } from '../../redux/categoriesService/categoriesApiSlice.js'
import Header from '../../reusableComponents/Header/Header.jsx'
import { ReactComponent as MinusDelete } from '../../icons/round_cross_grey.svg'
import AddButton from '../../reusableComponents/AddButton/AddButton.jsx'
import BottomMenu from '../../reusableComponents/BottomMenu/BottomMenu.jsx'

const Categories = () => {
	const { t } = useTranslation()
	const communityUid = localStorage.getItem('communityUid')
	const [removeCommunityCategories] = useRemoveCategoryMutation()
	const { data = {}, isLoading } = useGetCategoriesQuery({ community_uid: communityUid })
	const navigate = useNavigate()

	const deleteCommunityCategories = async (categoryUid) => {
		try {
			const response = await removeCommunityCategories({
				communyty_uid: communityUid,
				category_uid: categoryUid
			}).unwrap()
		} catch (e) {
			console.log('Categories catch error, e')
		}
	}

	if (isLoading) return <h1>Loading...</h1>

	const goToCategory = (item) => {
		sessionStorage.setItem('updatedCategory', JSON.stringify(item))
		navigate('/account/category/form')
	}

	const handleAddButton = () => {
		sessionStorage.removeItem('updatedCategory')
	}

	return (
		<>
			<Header
				className="header-container"
				backButton={true}
				subtitle={t('Categories')}
			/>

			<AddButton to={'/account/category/form'} onClick={handleAddButton} />

			<div className="categories__container">
				<div className="categories__block">
					{
						data?.map((item, index) => (
							<div className="categories__wrapper" key={index}>
								<div
									onClick={() => goToCategory(item)}
									className="categories__name-container"
								>
									<div className="categories__number-container">
										<p className="categories__number">
											{index + 1}
										</p>
									</div>

									<p className="categories__name">
										{t(item.name)}
									</p>
								</div>

								<MinusDelete
									className="categories__delete-button"
									onClick={() => deleteCommunityCategories(item.uid)}
								/>
							</div>
						))
					}
				</div>
			</div>
			<BottomMenu />
		</>
	)
}

export default Categories
