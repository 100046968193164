import { Fragment, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import './StageParticipantsList.scss'
import { TYPE_DOUBLES, TYPE_SINGLES } from '../../../utils/constants'
import { selectChosenPlayerInList, selectPlayersList, selectTournament } from '../../../redux/selectors'
import { setChosenPlayerInList } from '../../../redux/stageService/stageSlice'

function StageParticipantsList({ isFormTab }) {
	const dispatch = useDispatch()
	const playersList = useSelector(selectPlayersList)
	const chosenPlayerInList = useSelector(selectChosenPlayerInList)
	const { type } = useSelector(selectTournament)

	const choosePlayerInParticipantsList = useCallback((n, index) => {
		if (!isFormTab) return

		const participantIsChosen = Object.keys(chosenPlayerInList).length !== 0
		if (!participantIsChosen) {
			dispatch(setChosenPlayerInList({ player: n, index }))
		} else if (participantIsChosen && chosenPlayerInList.index !== index) {
			dispatch(setChosenPlayerInList({ player: n, index }))
		} else {
			dispatch(setChosenPlayerInList({}))
		}
	}, [dispatch, chosenPlayerInList, isFormTab])

	function listParticipantStyle(item, index) {
		let style = {}

		if (!item?.placed && isFormTab) {
			style = {
				...style,
				color: 'var(--palette-live-blue)'
			}
		}

		if (index === chosenPlayerInList.index && isFormTab) {
			style = {
				...style,
				border: '1px solid var(--palette-live-blue)',
				borderRadius: '11px'
			}
		}

		return style
	}
	return (
		<>
			{
				playersList?.map((item, index) =>
					<Fragment key={index}>
						{
							type === TYPE_SINGLES &&
								<Fragment key={index}>
									{
										item?.playerUid ?
											<div
												className="participants__container single"
												key={index}
												style={listParticipantStyle(item, index)}
												onClick={() => choosePlayerInParticipantsList(item, index)}
											>
												<p className="participants-index">
													{ index + 1 }
												</p>

												<div>
													<p className="participants-name">
														{ item?.participant.formedName }
													</p>
												</div>

												<p className="participants-rating">
													[{ item?.rating }]
												</p>
											</div>
											:
											<div
												className="participants__container single"
												key={index}
												style={listParticipantStyle(item, index)}
												onClick={() => choosePlayerInParticipantsList(item, index)}
											>
												<p className="participants-index">
													{ index + 1 }
												</p>

												<p className="participants-name">
													{ item?.name || '---' }
												</p>
											</div>
									}
								</Fragment>
						}

						{
							type === TYPE_DOUBLES &&
								<Fragment key={index}>
									{
										item?.doubleUid ?
											<div
												className="participants__container"
												key={index}
												style={listParticipantStyle(item, index)}
												onClick={() => choosePlayerInParticipantsList(item, index)}
											>
												<p className="participants-index">
													{ index + 1 }
												</p>

												<div>
													<p className="participants-name">
														{ item?.participant.firstPlayerName }
													</p>

													<p className="participants-name">
														{ item?.participant.secondPlayerName }
													</p>
												</div>

												<p className="participants-rating">
													[{ item?.name }]
												</p>
											</div>
											:
											<div
												className="participants__container single"
												key={index}
												style={listParticipantStyle(item, index)}
												onClick={() => choosePlayerInParticipantsList(item, index)}
											>
												<p className="participants-index">
													{ index + 1 }
												</p>

												<p className="participants-name">
													{ item?.name || '---' }
												</p>
											</div>
									}
								</Fragment>
						}
					</Fragment>
				)
			}
		</>
	)
}

export default StageParticipantsList