import React from 'react'
import './Switcher.scss'

const Switcher = ({ checked, onChange, label, name }) => {
	return (
		<div className="switcher">
			<label className="switcher__label">
				{ label }
			</label>

			<label class="switch">
				<input
					type="checkbox"
					checked={checked}
					id={name}
					onChange={onChange}
				/>

				<span class="switcher-slider"></span>
			</label>
		</div>
	)
}

export default Switcher
