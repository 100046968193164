import { apiSlice } from '../apiSlice'

export const userApiSlice = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		registration: build.mutation({
			query: (body) => ({
				url: '/users/',
				method: 'POST',
				body,
			})
		}),
		getUserInfo: build.query({
			query: () => '/users/me/',
			providesTags: ['User'],
		}),
		updateUserInfo: build.mutation({
			query: (body) => ({
				url: '/users/me/',
				method: 'PATCH',
				body,
			}),
			invalidatesTags: ['User']
		}),
		changeUserPassword: build.mutation({
			query: (body) => ({
				url: '/users/me/password/',
				method: 'POST',
				body,
			}),
			invalidatesTags: ['User']
		})
	})
})

export const {
	useRegistrationMutation,
	useGetUserInfoQuery,
	useUpdateUserInfoMutation,
	useChangeUserPasswordMutation
} = userApiSlice