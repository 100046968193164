import './StageGroupIcon.scss'
import { ReactComponent as RoundRobinIcon } from '../../icons/round_robin.svg'
import { ReactComponent as OlympicIcon } from '../../icons/olympic.svg'
import { ReactComponent as OlympicPlusIcon } from '../../icons/olympic_plus.svg'
import { ReactComponent as OlympicThreeIcon } from '../../icons/olympic_three.svg'
import { ReactComponent as NotPlayingIcon } from '../../icons/no_play.svg'
import { ReactComponent as DropOutIcon } from '../../icons/drop_out.svg'
import {
	GROUP_TYPE_OLYMPIC_PLUS,
	GROUP_TYPE_RR,
	GROUP_TYPE_NOT_PLAYING,
	GROUP_TYPE_DROP_OUT,
	GROUP_TYPE_OLYMPIC,
	GROUP_TYPE_OLYMPIC_THREE
} from '../../utils/constants'

function StageGroupIcon({ type, styleProp }) {
	const style = {
		width: styleProp?.width || '24px',
		height: styleProp?.height || '24px',
		fill: styleProp?.fill || 'var(--palette-live-grey-0)'
	}

	return (
		<>
			{
				type === GROUP_TYPE_RR ?
					<RoundRobinIcon style={style}/>
					:
					type === GROUP_TYPE_OLYMPIC_PLUS ?
						<OlympicPlusIcon style={style}/>
						:
						type === GROUP_TYPE_NOT_PLAYING ?
							<NotPlayingIcon style={style}/>
							:
							type === GROUP_TYPE_DROP_OUT ?
								<DropOutIcon style={style}/>
								:
								type === GROUP_TYPE_OLYMPIC ?
									<OlympicIcon style={style}/>
									:
									type === GROUP_TYPE_OLYMPIC_THREE ?
										<OlympicThreeIcon style={style}/>
										:
										null
			}
		</>
	)
}

export default StageGroupIcon