import { memo } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import './TournamentStructure.scss'
import { selectTournament, selectTournamentOptions } from '../../redux/selectors'
import CombineTournamentMenu from '../../reusableComponents/CombineTournamentMenu/CombineTournamentMenu'
import HeaderTournament from '../../reusableComponents/HeaderTournament/HeaderTournament'
import { useGetStageQuery } from '../../redux/stageService/stageApiSlice'
import { useProcessStageData } from '../../hooks/stageHooks'
import { getParameters } from '../../utils/functions'
import StageStructure from '../../extendedComponents/Stage/StageStructure/StageStructure'
import StructureHeader from '../../extendedComponents/Stage/StructureHeader/StructureHeader'
import Loader from '../../reusableComponents/Loader/Loader'
import { FULFILLED } from '../../utils/constants'

function TournamentStructure({
	tournamentStartDate,
	category,
	tournamentRefetch
}) {
	const { stages_count, name } = useSelector(selectTournament)
	const { stage: options = {} } = useSelector(selectTournamentOptions)
	const { tournamentParams, stageNumber } = useParams()
	const { tournamentUid } = getParameters(tournamentParams)
	const noRequest = !stages_count || stages_count < 1
		|| stageNumber < 1 || stageNumber > stages_count

	const { data: stageData = {}, status } = useGetStageQuery({
		tournament_uid: tournamentUid,
		stageNumber
	}, {
		skip: noRequest,
		refetchOnMountOrArgChange: true
	})

	// определяем состояние турнира
	useProcessStageData(stageData, status)

	return (
		<div className="tournament-structure">
			<HeaderTournament
				date={tournamentStartDate}
				category={category}
				background
			/>

			<main className="tournament-structure__main">
				{(status !== FULFILLED && stages_count > 0) || !name ?
					<Loader />
					:
					<>
						{options?.showParticipantsList &&
							<StructureHeader />
						}

						<StageStructure
							tournamentRefetch={tournamentRefetch}
						/>
					</>
				}
			</main>

			<CombineTournamentMenu structure />
		</div>
	)
}

export default memo(TournamentStructure)