export const sortArrayOfObjectsByName = (array) => {
	const players = array.slice()
	return  players.sort(compareNames)
}

function compareNames(a, b) {
	const lastName = a.last_name.toLowerCase()
		.localeCompare(b.last_name.toLowerCase())

	if (lastName !== 0) {
		return lastName
	}

	const firstName = a.first_name
		.toLowerCase()
		.localeCompare(b.first_name.toLowerCase())

	if (firstName !== 0) {
		return firstName
	}

	if (a.middle_name && b.middle_name) {
		const middleName = a.middle_name
			.toLowerCase()
			.localeCompare(b.middle_name.toLowerCase())

		if (middleName !== 0) {
			return middleName
		}
	}

	return 0
}