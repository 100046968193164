import { memo } from 'react'

import './Header.scss'
import BackButton from '../BackButton/BackButton'
import EditButton from '../EditButton/EditButton'

function Header({ title, subtitle, communityName, back, backUrl, edit, editUrl, border, background }) {
	return (
		<header
			className={`header ${!border ? '' : 'header--bottom'}`}
			style={{backgroundColor: background ? 'var(--palette-live-grey-11)' : ''}}
		>
			<div className="header__button">
				{
					back &&
						<BackButton
							nav={backUrl}
						/>
				}
			</div>
			
			<div className="header__section">
				{
					communityName &&
						<p className="header__community-name">
							{ communityName }
						</p>
				}

				<h1 className="header__title">
					{ title }
				</h1>
			</div>

			<div className="header__button">
				{
					edit &&
						<EditButton
							nav={editUrl}
						/>
				}
			</div>
		</header>
	)
}

export default memo(Header)