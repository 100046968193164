import { Outlet, Navigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { selectAuth } from '../../redux/selectors'

function RequireAuth() {
	const location = useLocation()
	const { authorized } = useSelector(selectAuth)

	return (
		<>
			{
				authorized ?
					<Outlet />
					:
					<Navigate to={'/auth?redirect=' + location.pathname} />
			}
		</>
	)
}

export default RequireAuth