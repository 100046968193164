import { memo } from 'react'

import './Loader.scss'
import { ReactComponent as RollingIcon } from '../../icons/rolling.svg'

function Loader() {
	return (
		<div className="loading">
			<RollingIcon className="loading__rolling-icon" />
		</div>
	)
}

export default memo(Loader)