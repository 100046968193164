import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { checkTokenExpiration, resetAuthorization } from '../utils/functions'
import { CONNECTION_ERROR } from '../utils/constants'
import { isExeptedError } from '../utils/functions2'

import { setError } from './errorService/errorSlice'
import { refreshToken } from './authService/refreshApiSlice'

async function basicRequest(args, api, extraOptions, access, guestCode) {
	const baseQuery = fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers) => {

			if (guestCode) {
				headers.set('x-auth-code', guestCode)
			} else if (access) {
				headers.set('authorization', `Bearer ${access}`)
			}

			return headers
		}
	})

	const response = await baseQuery(args, api, extraOptions)
	return response
}

const baseQueryWithReauth = async (args, api, extraOptions) => {
	const { dispatch, getState } = api
	const state = getState()
	const {
		authorized,
		refresh,
		guestCode,
		accessExpiresAt,
		refreshTokenExpiresAt
	} = state.auth
	let { access } = state.auth
	const { communityUid } = state.community

	if (authorized && (!access || !refresh)) {
		resetAuthorization(dispatch, communityUid, 'no_token/s')
		return { error: 401 }
	}

	if (authorized && !checkTokenExpiration(accessExpiresAt)) {
		const response = await refreshToken(
			refresh, api, extraOptions, dispatch, refreshTokenExpiresAt, communityUid
		)

		if (!response.success) {
			resetAuthorization(dispatch, communityUid, 'refresh_token_invalid')
			return { error: 401 }
		} else {
			access = response.access
		}
	}

	try {
		const result = await basicRequest(args, api, extraOptions, access, guestCode)

		if (result?.error) {
			const { error } = result || {}
			const { status, originalStatus, data = {} } = error || {}

			const isException = isExeptedError(data)

			if ((status !== 401 || originalStatus === 500 || status === CONNECTION_ERROR) && !isException) {
				dispatch(setError(error))
			} else if (status === 401 && authorized) {
				resetAuthorization(dispatch, communityUid, 'refresh_token_invalid')
				return
			}
		}

		console.log(result)

		return result
	} catch (e) {
		console.log('basic api catch error', e)
	}
}

export const apiSlice = createApi({
	baseQuery: baseQueryWithReauth,
	endpoints: builder => ({})
})