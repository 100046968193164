import { createSlice } from '@reduxjs/toolkit'

import { getLocalStorageItem, parseObject, setItemInLocalStorage } from '../../utils/functions'

import communityOptions from './communityOptions.json'

const communitySlice = createSlice({
	name: 'community',
	initialState: {
		community: {},
		communityName: getLocalStorageItem('communityName'),
		communityUid: getLocalStorageItem('communityUid'),
		options: parseObject('communityOptions', getLocalStorageItem) || {},
	},
	reducers: {
		setCommunity(state, action) {
			const options = communityOptions[action.payload.tier]
			setItemInLocalStorage('communityOptions', JSON.stringify(options))

			state.options = options
			state.community = action.payload
		},
		setCommunityUid(state, action) {
			setItemInLocalStorage('communityUid', action.payload)
			state.communityUid = action.payload
		},
		setCommunityName(state, action) {
			setItemInLocalStorage('communityName', action.payload)
			state.communityName = action.payload
		}
	}
})

export const {
	setCommunity,
	setCommunityName,
	setCommunityUid
} = communitySlice.actions
export default communitySlice.reducer