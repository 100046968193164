import { memo } from 'react'

import './Button.scss'
import { ReactComponent as RollingIcon } from '../../icons/rolling.svg'

function Button({
	title,
	border,
	background,
	onClick,
	type,
	marginBottom,
	marginRight,
	loading
}) {
	return (
		<button
			className="button"
			style={{
				borderColor: border ? 'var(--palette-live-bg-1-white)' : 'var(--palette-live-blue)',
				backgroundColor: background === 'white' ? 'var(--palette-live-bg-1-white)' : 'var(--palette-live-blue)',
				color: background === 'white' ? 'var(--palette-live-blue)' : 'var(--palette-live-bg-1-white)',
				marginBottom: marginBottom ? '20px' : '0',
				marginRight: marginRight ? '20px' : '0'
			}}
			onClick={onClick}
			type={type}
		>
			{ loading ? <RollingIcon className="button-icon-loading"/> : title }
		</button>
	)
}

export default memo(Button)