import { memo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import './BottomMenu.scss'
import { ReactComponent as TennisIcon } from '../../icons/tournaments.svg'
import { ReactComponent as UsersIcon } from '../../icons/players.svg'
import { ReactComponent as CommunitiesIcon } from '../../icons/community.svg'
import { ReactComponent as AccountIcon } from '../../icons/account.svg'

function BottomMenu() {
	const navigate = useNavigate()
	const location = useLocation()
	const authorized = localStorage.getItem('authorized')

	function getPath() {
		const parts = location?.pathname?.split('/')
		return parts[1]
	}

	function goToAccountPage() {
		let link = '/login'

		if (authorized === 'true') {
			link = '/account'
		}

		navigate(link)
	}

	return (
		<footer className="bottom-menu">
			<button
				className="bottom-menu__button"
				onClick={() => navigate('/tournaments')}
			>
				<TennisIcon
					className={`tennis-icon ${getPath() === 'tournaments' ? 'change-color-icon' : ''}`}
				/>

				<span
					className={`block-name ${getPath() === 'tournaments' ? 'change-color-name' : ''}`}
				>
					Турниры
				</span>
			</button>

			<button
				className="bottom-menu__button"
				onClick={() => navigate('/players')}
			>
				<UsersIcon
					className={`users-icon ${getPath() === 'players' || getPath() === 'player' ? 'change-color-icon' : ''}`}
				/>

				<span
					className={`block-name ${getPath() === 'players' || getPath() === 'player' ? 'change-color-name' : ''}`}
				>
					Игроки
				</span>
			</button>

			<button
				className="bottom-menu__button"
				onClick={() => navigate('/community')}
			>
				<CommunitiesIcon
					className={`communities-icon ${getPath() === 'community' ? 'change-color-icon' : ''}`}
				/>

				<span
					className={`block-name ${getPath() === 'community' ? 'change-color-name' : ''}`}
				>
					Сообщество
				</span>
			</button>

			<button
				className="bottom-menu__button"
				onClick={goToAccountPage}
			>
				<AccountIcon
					className={`account-icon ${getPath() === 'account' || getPath() === 'account' ? 'change-color-icon' : ''}`}
				/>

				<span
					className={`block-name ${getPath() === 'account' || getPath() === 'account' ? 'change-color-name' : ''}`}
				>
					Аккаунт
				</span>
			</button>
		</footer>
	)
}

export default memo(BottomMenu)