import { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import './Tooltip.scss'

function Tooltip({ children, content, position = 'bottom', disable }) {
	const tooltipRef = useRef(null)
	const [isVisible, setIsVisible] = useState(false)
	const [coords, setCoords] = useState({ top: 0, left: 0 })

	const showTooltip = (e) => {
		const rect = e.target.getBoundingClientRect()
		const extraHeight = 5

		setCoords({
			top: rect.top + rect.height + extraHeight,
			left: rect.left
		})

		setIsVisible(true)
	}

	const hideTooltip = () => setIsVisible(false)

	const handleClickOutside = (event) => {
		if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
			hideTooltip()
		}
	}

	const handleScroll = () => {
		hideTooltip()
	}

	useEffect(() => {
		if (isVisible) {
			document.addEventListener('click', handleClickOutside)
			window.addEventListener('scroll', handleScroll, true)
		}

		return () => {
			document.removeEventListener('click', handleClickOutside)
			window.removeEventListener('scroll', handleScroll, true)
		}
	}, [isVisible])

	return (
		<div
			className="tooltip-container"
			onMouseEnter={showTooltip}
			onMouseLeave={hideTooltip}
			onClick={showTooltip}
			ref={tooltipRef}
		>
			{children}

			{isVisible && !disable && ReactDOM.createPortal(
				<div className={`tooltip-box tooltip-${position}`} style={{ top: coords.top, left: coords.left }}>
					{content}
				</div>,
				document.getElementById('tooltip-root')
			)}
		</div>
	)
}

export default Tooltip
