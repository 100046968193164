import { RR_RANKING_SYST_GROUP } from '../../../utils/constants'

function getColumnsWithSameResults(group, rankingSystem) {
	const columns = { matches: false, sets: false, games: false, matchesAreFinished: true }

	if (!group?.matches.every(match => match.is_finished === true)) {
		return {
			...columns,
			matchesAreFinished: false
		}
	}

	const results = group.results
	const minNumberOfParticipants = 2

	if (results.length < minNumberOfParticipants || results[0].sort_key.length < 1) {
		return columns
	}

	const columnsArray = Array.from({ length: results[0].sort_key.length }, (_, index) => index)

	columnsArray.forEach((clm) => {
		if (clm > 0 && !columns.matches) {
			return
		}

		const columnHasSameResults = results.some((obj) => {
			return results.some((item) => {
				return checkItems(item, obj, clm)
			})
		})

		if (columnHasSameResults) {
			columns.matches = clm === 0 ? true : columns.matches

			columns.sets = clm === 1 || (rankingSystem !== RR_RANKING_SYST_GROUP && columns.matches)
				? true : columns.sets

			columns.games = clm === 2 || (rankingSystem !== RR_RANKING_SYST_GROUP && columns.sets)
				? true : columns.games
		}
	})

	return columns
}

function checkItems(item1, item2, clm) {
	if (item1.node_uid !== item2.node_uid) {

		if (clm === 0 && item1.match_points_lost === item2.match_points_lost
			&& item1.match_points_won === item2.match_points_won) {
			return true

		} else if (clm === 1) {
			if (item1.match_points_lost !== item1.set_points_lost || item1.match_points_won !== item1.set_points_won) {
				return true
			} else {
				return false
			}

		} else if (clm === 2) {
			if (item1.set_points_lost === item2.set_points_lost && item1.set_points_won === item2.set_points_won) {
				return true
			} else {
				return false
			}

		} else {
			return false
		}

	} else {
		return false
	}
}

function convertInPercent(item, column) {
	const result = item.sort_key

	if (column === 'set' && result[1] >= 0) {
		return (result[1] * 100).toFixed(1) + '%'
	} else if (column === 'game' && result[2] >= 0) {
		return (result[2] * 100).toFixed(1) + '%'
	} else {
		return
	}
}

function includeInDisput(result, group) {
	const results = group.results

	const isIncluded = results.some((item) => {
		return (
			item.node_uid !== result.node_uid
			&& item.match_points_lost === result.match_points_lost
			&& item.match_points_won === result.match_points_won
		)
	})

	return isIncluded
}

function getSetsOrGamesResult(participant, column) {
	if(column === 'set') {
		if (participant.set_points_lost_disputant) {
			return `${ participant.set_points_won_disputant } – ${ participant.set_points_lost_disputant }`
		} else {
			return `${ participant.set_points_won } – ${ participant.set_points_lost }`
		}
	} else {
		if (participant.game_points_lost_disputant) {
			return `${ participant.game_points_won_disputant } – ${ participant.game_points_lost_disputant }`
		} else {
			return `${ participant.game_points_won } – ${ participant.game_points_lost }`
		}
	}
}

export {
	includeInDisput,
	convertInPercent,
	getColumnsWithSameResults,
	getSetsOrGamesResult
}