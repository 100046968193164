import { createSlice } from '@reduxjs/toolkit'

const roundRobinSlice = createSlice({
	name: 'roundRobin',
	initialState: {
		roundRobinGroupList: [],
		showResultEditorModal: false
	},
	reducers: {
		setResultModalList(state, action) {
			state.roundRobinGroupList = action.payload
		},
		setShowResultEditorModal(state, action) {
			state.showResultEditorModal = action.payload
		}
	}
})

export const {
	setResultModalList,
	setShowResultEditorModal
} = roundRobinSlice.actions
export default roundRobinSlice.reducer