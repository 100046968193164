import './Info.scss'
import ArrowLinkBlock from '../../reusableComponents/ArrowLinkBlock/ArrowLinkBlock'
import Header from '../../reusableComponents/Header/Header'

const FILE_DATA = [
	{
		title: 'Описание функциональных характеристик веб сервиса Тентур',
		url: 'Description_of_the_functional_characteristics_of_the_Tentur_web_service.pdf'
	},
	// {
	// 	title: 'Лицензионный договор на использование Сервиса Теннисный Турнир',
	// 	url: 'license_agreement_tentur.pdf'
	// },
	// {
	// 	title: 'Инструкция по эксплуатации Сервиса Теннисный Турнир',
	// 	url: 'Instructions_for_operating_web_service_Tentur.pdf'
	// },
	{
		title: 'Политика обработки персональных данных',
		url: 'Personal_data_processing_policy_SL_Cosmos.pdf'
	},
	{
		title: 'Положение о конфиденциальности',
		url: 'Privacy_statement_for_web_sites_SL_Space.pdf'
	},
	// {
	// 	title: 'Информация по установке программного обеспечения',
	// 	url: 'Installing_the_software.pdf'
	// }
]

function openPdfInNewWindow(pdfUrl) {
	window.open(pdfUrl, '_blank')
}

function Info() {
	return (
		<>
			<Header
				title="Информация о сайте"
				back
				backUrl={-1}
				border
			/>

			<main className="info">
				{
					FILE_DATA?.map((data, id) => 
						<ArrowLinkBlock
							key={id}
							to={'#'}
							borderBottom
							onClick={() => openPdfInNewWindow(data.url)}
						>
							<p className="info__title">
								{ data.title }
							</p>
						</ArrowLinkBlock>
					)
				}
			</main>
		</>
	)
}

export default Info