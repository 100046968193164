export function getCategoryOfTournament(community, tournament) {
	const category = community?.categories?.find(c =>
		c?.uid === tournament?.category_uid
	)
	return category?.name
}

// Функция форматирования даты
export function dateFormatting(originalDate) {
	if (!originalDate || isNaN(new Date(originalDate))) {
		return null
	}

	// Создание объекта Date из строки
	const dateObject = new Date(originalDate)

	// Массивы для отображения названий месяцев и дней недели
	const months = [
		'ЯНВАРЯ', 'ФЕВРАЛЯ', 'МАРТА', 'АПРЕЛЯ', 'МАЯ', 'ИЮНЯ',
		'ИЮЛЯ', 'АВГУСТА', 'СЕНТЯБРЯ', 'ОКТЯБРЯ', 'НОЯБРЯ', 'ДЕКАБРЯ'
	]

	const daysOfWeek = [
		'воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'
	]

	// Извлечение месяца, дня месяца и дня недели из объекта Date
	const month = months[dateObject.getMonth()]
	const dayOfMonth = dateObject.getDate()
	const dayOfWeek = daysOfWeek[dateObject.getDay()]

	// Форматирование строки для вывода
	return `${dayOfMonth} ${month}, ${dayOfWeek}`
}

















// export const getCategoryOfTournament = (community, tournament) => {
// 	const category = community?.categories?.find(c =>
// 		c?.uid === tournament?.category_uid
// 	)
// 	return category?.name
// }