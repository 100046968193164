import React, { useEffect, useState } from 'react'
import { Route, Routes, useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'

import './StageStructure.scss'
import { selectInitialStage, selectStageCondition, selectTournament } from '../../../redux/selectors'
import { setImmutableProcessedLevels, setProcessedLevelsForBuildMode, setPlayersList, setProcessedStage, setTotalQuantityNodesInGroups } from '../../../redux/stageService/stageSlice'
import StageForm from '../StageForm/StageForm'
import PreStageRating from '../PreStageRating/PreStageRating'
import { formLevels, formNodes } from '../../../utils/functions'
import StageBuild from '../StageBuild/StageBuild'
import StageGeneration from '../StageGeneration/StageGeneration'
import NoContent from '../../../reusableComponents/NoContent/NoContent'
import { FILLED } from '../../../utils/constants'
import RemoveStageButton from '../../../reusableComponents/RemoveStageButton/RemoveStageButton'

function StageStructure({
	preStage,
	tournamentRefetch
}) {
	const dispatch = useDispatch()
	const params = useParams()
	const { stageNumber } = params
	const { type, players, doubles } = useSelector(selectTournament)
	const { stageStatus, stageExists } = useSelector(selectStageCondition)
	const initialStage = useSelector(selectInitialStage)

	const [initialParticipantsPreStage, setInitialParticipantsPreStage] = useState([])

	useEffect(() => {
		if (initialStage && Object.keys(initialStage).length !== 0) {
			const { levels, rating_initial } = initialStage

			// Формируем уровень
			const formedLevels = formLevels(levels, players || doubles, type, stageNumber, initialStage)
			// Формируем игроков
			const formedNodes = formNodes(rating_initial, players || doubles, type, initialStage)

			dispatch(setPlayersList(formedNodes))

			// устанаваливем начальное количество нод в группах
			dispatch(setTotalQuantityNodesInGroups(formedLevels.totalNodesQuantity))

			const stageState = { ...initialStage, levels: formedLevels.levels }
			dispatch(setProcessedStage(stageState))

			dispatch(setImmutableProcessedLevels(formedLevels.levels))
			dispatch(setProcessedLevelsForBuildMode(formedLevels.levels))

			setInitialParticipantsPreStage(formedNodes)
		}
	}, [stageNumber, type, doubles, players, dispatch, initialStage])

	return (
		<div className="stage-structure__container">
			<Routes>
				<Route path="/" element={
					stageStatus === FILLED ?
						<StageForm />
						:
						<NoContent
							structure
						/>
				} />

				<Route path="build" element={
					<StageBuild />
				} />

				<Route path="/generation" element={
					<StageGeneration />
				} />

				<Route path="/pre-stage" element={
					<PreStageRating
						initialParticipantsPreStage={initialParticipantsPreStage}
						setInitialParticipantsPreStage={setInitialParticipantsPreStage}
						preStage={preStage}
					/>
				} />
			</Routes>

			{stageExists && <RemoveStageButton tournamentRefetch={tournamentRefetch} />}
		</div>
	)
}

export default StageStructure