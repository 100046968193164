import { useNavigate } from 'react-router-dom'

import './EditButton.scss'
import { ReactComponent as EditIcon } from '../../icons/edit_note.svg'

function EditButton({ nav }) {
	const navigate = useNavigate()

	return (
		<button
			className="edit-button"
			onClick={() => navigate(nav)}
		>
			<EditIcon className="edit-button__icon" />
		</button>
	)
}

export default EditButton