import { useState, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import './TournamentInfo.scss'
import { ReactComponent as Edition } from '../../icons/edit_blue.svg'
import { useRemoveTournamentMutation } from '../../redux/communityService/communityApiSlice'
import { RR_RANKING_SYST_EQUAL, RR_RANKING_SYST_GROUP, TYPE_DOUBLES, TYPE_SINGLES } from '../../utils/constants'
import { selectAuth, selectTournament, selectTournamentOptions } from '../../redux/selectors'
import RoundedButton from '../../reusableComponents/RoundedButton/RoundedButton'
import Popup from '../../reusableComponents/Popup/Popup'
import StringButton from '../../reusableComponents/StringButton/StringButton'
import { useUpdateTournamentMutation } from '../../redux/singleTournamentService/singleTournamentApiSlice'
import { getParameters } from '../../utils/functions'
import TournamentMenu from '../../reusableComponents/TournamentMenu/TournamentMenu'
import HeaderTournament from '../../reusableComponents/HeaderTournament/HeaderTournament'
import LabelBlock from '../../reusableComponents/LabelBlock/LabelBlock'

function TournamentInfo({ community, categories, tournamentStartDate }) {
	const { t } = useTranslation()
	const { tournamentParams } = useParams()
	const { tournamentUid } = getParameters(tournamentParams)
	const navigate = useNavigate()
	const { authorized } = useSelector(selectAuth)
	const {
		venue_uid,
		venue,
		start_date,
		type,
		description,
		round_robin_ranking_system,
		status,
		name,
		created_by_uid
	} = useSelector(selectTournament)
	const options = useSelector(selectTournamentOptions)

	const [removeTournamet] = useRemoveTournamentMutation()
	const [updateTournament] = useUpdateTournamentMutation()

	const [removeConfirmation, setRemoveConfirmation] = useState(false)
	const [requestIsActive, setRequestIsActive] = useState(false)

	const tournamentVenue = useMemo(() => {
		if (Object.keys(community).length !== 0) {
			const tournamentVenues = community?.venues?.find(venue => {
				return venue.uid === venue_uid
			})
			return {
				label: tournamentVenues?.name,
				value: tournamentVenues?.uid,
				address_string: tournamentVenues?.address_string
			}
		} else {
			return { address_string: venue?.address_string }
		}
	}, [venue_uid, venue, community])

	// Конвертер даты
	const tournamentDate = useMemo(() => {
		// Разбиваем строку на компоненты (год, месяц, день)
		if (start_date) {
			const [year, month, day] = start_date.split('-')

			// Преобразуем компоненты в формат "dd.mm.yyyy"
			const formattedDate = `${day}.${month}.${year}`

			return formattedDate
		}
	}, [start_date])

	function getOrg() {
		if (created_by_uid) {
			const org = community?.managers.find(item => item.uid === created_by_uid)
			return `${org.last_name} ${org.first_name}`
		} else {
			return ''
		}
	}

	async function handleTournamentStatus() {
		setRequestIsActive(true)
		const body = {
			status: 'finished'
		}

		if (status === 'finished') {
			body.status = 'in_progress'
		}

		try {
			const result = await updateTournament({ tournament_uid: tournamentUid, body }).unwrap()

			setTimeout(() => {
				setRequestIsActive(false)
			}, 500)
		} catch (e) {
			console.log('handleTournamentStatus catched error', e)
			setRequestIsActive(false)
		}
	}

	async function deleteTournament() {
		try {
			const response = await removeTournamet({ tournament_uid: tournamentUid }).unwrap()

			navigate('/tournaments')
		} catch (e) {
			console.log('deleteTournamet catch error', e)
		}
	}

	return (
		<>
			<HeaderTournament
				name={name}
				date={tournamentStartDate}
				categories={categories}
				copyLink={authorized ? true : false}
			/>

			<div className="tournament-info__wrapper">
				<div className="tournament-info__container">
					{
						<div className="tournament-info__header">
							<p className="tournament-info__header-title">
								{ t('Information') }
							</p>

							{
								authorized &&
									<Edition
										className="tournament-info__header-edition"
										onClick={() => { navigate('/form/' + tournamentUid) }}
									/>
							}
						</div>
					}

					{
						<div className="form-description__container">
							<LabelBlock
								label={'Место'}
								content={tournamentVenue?.address_string}
								borderBottom
							/>

							<LabelBlock
								label={t('Start date')}
								content={tournamentDate}
								borderBottom
							/>

							<LabelBlock
								label="Организатор"
								content={getOrg()}
								borderBottom
							/>

							<LabelBlock
								label={t('Tournament type')}
								content={type === TYPE_SINGLES ?
									t('Single')
									:
									type === TYPE_DOUBLES &&
									t('Double')}
								borderBottom
							/>

							<LabelBlock
								label={t('A method for determining places in a round-robin group when several participants have equal points')}
								content={round_robin_ranking_system === RR_RANKING_SYST_GROUP ?
									t('By the percentage of all sets/games won')
									:
									round_robin_ranking_system === RR_RANKING_SYST_EQUAL &&
									t('By the percentage of sets/games won in games with each other')}
								borderBottom
							/>

							<LabelBlock
								label={`Режим ${options?.name}`}
							/>
						</div>
					}

					<div className="form-description__container">
						<LabelBlock
							label={'Доп. информация'}
							content={description ? description : 'Нет информации'}
						/>
					</div>

					{
						authorized &&
							<div className="tournamet-info__tournament-delition">
								<RoundedButton
									title={t(status !== 'finished' ? 'Finish tournament' : 'Open tournament')}
									onClick={handleTournamentStatus}
									border
									loading={requestIsActive}
								/>

								<StringButton
									title={t('Remove tournament')}
									onClick={() => setRemoveConfirmation(true)}
								/>
							</div>
					}

					{
						removeConfirmation &&
							<Popup>
								<p className="tournament-info__confirmation">
									{ t('Are you sure you want to remove tournament?') }
								</p>

								<div className="tournament-info__confirmation-buttons">
									<RoundedButton
										title={t('Cancel')}
										onClick={() => setRemoveConfirmation(false)}
										background={'white'}
										border
									/>

									<RoundedButton
										title={t('Remove')}
										onClick={deleteTournament}
										border
									/>
								</div>
							</Popup>
					}
				</div>
			</div>

			<TournamentMenu />
		</>
	)
}

export default memo(TournamentInfo)