import React, { memo } from 'react'
import './Textarea.scss'
import { useState } from 'react'

function Textarea(
	{
		value,
		placeholder,
		onChange,
		name,
		id
	}) {
	const [focused, setFocused] = useState(false)

	function handleFocus() {
		setFocused(true)
	}

	function handleBlur() {
		setFocused(false)
	}

	return (
		<div className="textarea__container">
			<div
				className={
					`textarea__block ${focused ?
						'textarea__block--focus'
						:
						''
					}`
				}
			>
				<div className="textarea__wrapper">
					<textarea
						value={value}
						className={
							`textarea__element ${focused ? 'textarea__element--focus' : ''}`
						}
						onChange={onChange}
						name={name}
						id={id}
						onFocus={() => handleFocus()}
						onBlur={() => handleBlur()}
					/>

					<label className="textarea-label">
						{placeholder}
					</label>
				</div>
			</div>
		</div>
	)
}

export default memo(Textarea)