export const strengthLevels = [
	{
		value: '1.5',
		label: '1.5'
	},
	{
		value: '2',
		label: '2'
	},
	{
		value: '2.5',
		label: '2.5'
	},
	{
		value: '3',
		label: '3'
	},
	{
		value: '3.5',
		label: '3.5'
	},
	{
		value: '4',
		label: '4'
	},
	{
		value: '4.5',
		label: '4.5'
	},
	{
		value: '5',
		label: '5'
	},
	{
		value: '5.5',
		label: '5.5'
	},
	{
		value: '6',
		label: '6'
	},
	{
		value: '6.5',
		label: '6.5'
	}
]