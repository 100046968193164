import { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useSelector } from 'react-redux'
import SmoothCollapse from 'react-smooth-collapse'
import { useLocation } from 'react-router-dom'

import './StructureHeader.scss'
import { selectProcessedStage, selectShowPrestageRating, selectTournament, selectTournamentOptions } from '../../../redux/selectors'
import { ReactComponent as EditNoteIcon } from '../../../icons/edit_note.svg'
import { ReactComponent as ArrowBackIcon } from '../../../icons/arrow_back.svg'
import { ReactComponent as ArrowDownIcon } from '../../../icons/arrow_down.svg'
import ParticipantsList from '../StageParticipantsList/StageParticipantsList'
import { getParameters } from '../../../utils/functions'

function StructureHeader() {
	const navigate = useNavigate()
	const params = useParams()
	const location = useLocation()
	const { tournamentParams, stageNumber } = params
	const wildcardValue = params['*']
	const { tournamentUid } = getParameters(tournamentParams)
	const { stages_count } = useSelector(selectTournament)
	const stageData = useSelector(selectProcessedStage)
	const { stage: options = {} } = useSelector(selectTournamentOptions)
	const showPrestageRating = useSelector(selectShowPrestageRating)
	const listId = stageData.order_number
	const [showParticipantsList, setShowParticipantsList] = useState({ id: null, opened: false })

	useEffect(() => {
		setShowParticipantsList({ id: listId, opened: false })
	}, [listId])

	const checkURLForBuild = () => {
		const prestageRatingIsActive = showPrestageRating && showPrestageRating?.uid === tournamentUid && showPrestageRating?.active
		if (!location.pathname.includes('build') && prestageRatingIsActive) {
			return true
		} else {
			return false
		}
	}

	return (
		<div className="structure-header">
			<div className="structure-header__wrapper">
				{
					wildcardValue !== 'pre-stage' ?
						<Fragment>
							{stages_count > 0 &&
								<div className="structure-header__container">

									<div className="structure-header__stage-number-wrapper">
										<p className="structure-header__stage-number">
											Участники
										</p>

										{
											options?.preStageRating?.change && checkURLForBuild() ?
												<div className="structure-header__edit-wrapper">
													<EditNoteIcon
														className="edit-note__icon"
														onClick={() => navigate(`/${tournamentUid}/structure/${stageNumber}/pre-stage`)}
													/>
												</div>
												:
												<></>
										}
									</div>

									<ArrowDownIcon
										className={`arrow-down__icon ${showParticipantsList.id === listId && showParticipantsList.opened
											?
											'arrow--up' : 'arrow--down'
											}`}
										onClick={() => setShowParticipantsList(prev => {
											return {
												...prev,
												opened: !prev.opened
											}
										})}
									/>
								</div>
							}

							<div className={`structure-header__participants ${!showParticipantsList.id === listId || !showParticipantsList.opened ? 'no-border' : ''}`}>
								<SmoothCollapse expanded={showParticipantsList.id === listId && showParticipantsList.opened}>
									<ParticipantsList isFormTab={wildcardValue === ''} />
								</SmoothCollapse>
							</div>
						</Fragment>
						:
						<div className="structure-header__container">
							<ArrowBackIcon
								className="arrow-back__icon"
								onClick={() => navigate('/' + tournamentUid + '/structure/' + stageNumber)}
							/>

							<div className="structure-header__pre-stage">
								<p className="structure-header__pre-stage-title">
									Этап {stageData?.order_number}
								</p>

								<p className="structure-header__pre-stage-description">
									предэтапный рейтинг
								</p>
							</div>
						</div>
				}
			</div>
		</div>
	)
}

export default StructureHeader