// import { useState } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import './TournamentParticipantsList.scss'
import { selectAuth, selectParticipants, selectTournament, selectTournamentOptions } from '../../redux/selectors'
import {
	// setAddedParticipants,
	// setParsedDoubles,
	setParticipants,
	// setRemovedParticipants,
	setSeedRequired
} from '../../redux/tournamentParticipantsService/tournamentParticipantsSlice'
import { ReactComponent as DragIcon } from '../../icons/drag.svg'
import { ReactComponent as EditMan } from '../../icons/edit-person.svg'
// import { ReactComponent as DoubleDndMode } from '../../icons/double_dnd_mode.svg'
// import { ReactComponent as DoubleDndBetweenMode } from '../../icons/double_dnd_between_mode.svg'
// import { ReactComponent as SortIcon } from '../../icons/players_sort.svg'
import { TYPE_SINGLES } from '../../utils/constants'
import { itemsChangedOrder } from '../../utils/functions2'
// import { getNewUid } from '../../utils/functions'
// import Loader from '../../reusableComponents/Loader/Loader'
// import DoublesDragDropList from '../DoublesDragDropList/DoublesDragDropList'

function TournamentParticipantsList({ openOptions }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { authorized } = useSelector(selectAuth)
	const {
		players,
		doubles,
		type,
		participants_count
	} = useSelector(selectTournament)
	const { settings = {} } = useSelector(selectTournamentOptions)
	const {
		participants,
		// parsedDoubles
	} = useSelector(selectParticipants)

	// const [dndBetwenDoubles, setDndBetweenDoubles] = useState(false)
	// const [processing, setProcessing] = useState(false)

	function onDragEnd(result) {
		if (!authorized || !result.destination) {
			return
		}

		const { destination, source } = result

		const updatedParticipants = Array.from(participants)
		const [removed] = updatedParticipants.splice(source.index, 1)
		updatedParticipants.splice(destination.index, 0, removed)

		const dragIsDone = itemsChangedOrder(updatedParticipants, players || doubles)

		dispatch(setParticipants({ participants: updatedParticipants, type }))
		dispatch(setSeedRequired(dragIsDone))
	}

	// function changeDoublesDndMode() {
	// 	if (type !== TYPE_DOUBLES) return

	// 	setDndBetweenDoubles(prev => !prev)
	// 	setProcessing(true)

	// 	if (!dndBetwenDoubles) {
	// 		parseDoubles()
	// 	} else {
	// 		compileDoubles()
	// 	}

	// 	setProcessing(false)
	// }

	// function parseDoubles() {
	// 	// разбиваем пары на одиночных
	// 	const parsedDoubles = participants?.reduce((acc, double) => {
	// 		const player1 = { ...double.player1 }
	// 		player1.doubleUid = double.uid

	// 		const player2 = { ...double.player2 }
	// 		player2.doubleUid = double.uid

	// 		acc.push(player1)
	// 		acc.push(player2)

	// 		return acc
	// 	}, [])

	// 	dispatch(setParsedDoubles(parsedDoubles))
	// }

	// function compileDoubles() {
	// 	// собираем пары вместе. каждые два человека это пара
	// 	const compiledDoubles = []

	// 	for (let i = 0; i < parsedDoubles?.length; i += 2) {
	// 		const player = parsedDoubles[i]
	// 		const nextPlayer = parsedDoubles[i + 1]

	// 		const compiledDouble = {
	// 			player1: player,
	// 			player2: nextPlayer
	// 		}

	// 		if (player?.doubleUid !== nextPlayer?.doubleUid) {
	// 			// если у игрков разные uid от своих пар, то их пары были изменены
	// 			compiledDouble.uid = getNewUid() // добавляем fake uid новой паре

	// 			processPlayer(player)
	// 			processPlayer(nextPlayer)

	// 			dispatch(setAddedParticipants({
	// 				participants: doubles,
	// 				participant: compiledDouble,
	// 				type
	// 			})) // добавляем новую пару

	// 			dispatch(setSeedRequired(true)) // пересеевать нужно всегда
	// 		} else {
	// 			// если игроки из одной и тойже пары, то возвращаем ей ее uid
	// 			compiledDouble.uid = player.doubleUid
	// 		}

	// 		compiledDoubles.push(compiledDouble)
	// 	}

	// 	dispatch(setParticipants({ participants: compiledDoubles }))
	// }

	// function processPlayer(player) {
	// 	const double = participants.find(double => double.uid === player.doubleUid) // ищем бывшую пару текущего игрока

	// 	dispatch(setRemovedParticipants({
	// 		participants: doubles,
	// 		participant: double,
	// 		type
	// 	})) // удаляем бывшую пару
	// }

	function dragItemStyle(provided, snapshot) {
		let style = {
			...provided.draggableProps.style
		}
		const backgroundColor = 'var(--palette-live-blue-3)'

		if (snapshot.isDragging) {
			style = {
				...style,
				backgroundColor,
				webkitBoxShadow: '0px 7px 11px -5px rgba(34, 60, 80, 0.2)',
				mozBoxShadow: '0px 7px 11px -5px rgba(34, 60, 80, 0.2)',
				boxShadow: '0px 7px 11px -5px rgba(34, 60, 80, 0.2)'
			}
		}

		return style
	}

	return (
		<>
			<div
				className="tournament-participants__list-wrapper"
				style={{ border: participants && participants?.length < 1 ? 'none' : '' }}
			>
				<div className="tournament-participants__title">
					<span className="tournament-participants__count">
						{participants_count}

						<span className="tournament-participants__separator"></span>

						<span className="tournament-participants__count-max">
							{settings?.maxParticipants}
						</span>
					</span>

					{t(authorized ? 'Saved' : 'Participate')}

					<div className="tournament-participants__icons">
						{/* <div className="tournament-participants__icon">
								<SortIcon />
							</div>

							<div
								className="tournament-participants__icon"
								onClick={changeDoublesDndMode}
							>
								{type === TYPE_DOUBLES ?
									!dndBetwenDoubles ?
										<DoubleDndMode />
										:
										<DoubleDndBetweenMode />
									:
									<></>
								}
							</div> */}
					</div>
				</div>

				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="participants-list">
						{
							(provided) => (
								<div
									className="tournament-participants-list"
									{...provided.droppableProps}
									ref={provided.innerRef}
								>
									{participants && participants?.map((el, index) =>
										<Draggable
											key={el.uid}
											draggableId={el.uid}
											index={index}
											isDragDisabled={!authorized ? true : false}
										>
											{
												(provided, snapshot) => (
													<div
														className="tournament-participants__item"
														ref={provided.innerRef}
														{...provided.draggableProps}
														style={dragItemStyle(provided, snapshot)}
														onClick={() => openOptions(el)}
													>
														{
															type === TYPE_SINGLES ?
																<>
																	<p className="tournament-participants__number">
																		{index + 1}
																	</p>

																	<div
																		// className={
																		// 	`tournament-participants__name ${
																		// 		el?.display_name && settings?.displayName
																		// 			? 'display-name' : ''
																		// 	}`
																		// }
																		className="tournament-participants__name"
																	>
																		{/* {el?.display_name && settings?.displayName &&
																				<p className="tournament-participants__display-name">
																					{`${el?.display_name}`}
																				</p>
																			} */}

																		<p >
																			{`${el?.last_name} ${el.first_name}`}
																		</p>
																	</div>

																	<EditMan className="tournament_participants__edit-man" />

																	{
																		authorized &&
																		<div
																			className="tournament_participants__block"
																			{...provided.dragHandleProps}
																		>
																			<p className="tournament_participants__strength">
																				{el.strength || ''}
																			</p>

																			<div className="tournament_participants__dnd">
																				<DragIcon />
																			</div>
																		</div>
																	}
																</>
																:
																<>
																	<p className="tournament-participants__number">
																		{index + 1}
																	</p>

																	<div className="tournament-participants__names">
																		<p className="tournament-participants__palyer--first">
																			{`${el?.player1?.last_name} ${el.player1?.first_name}`}
																		</p>

																		<p className="tournament-participants__palyer--second">
																			{`${el?.player2?.last_name} ${el?.player2?.first_name}`}
																		</p>
																	</div>

																	<EditMan className="tournament_participants__edit-man" />

																	<div className="tournament-participants__palyers-strength">
																		<span className="tournament-participants__strength">
																			{el?.player1?.strength || ''}
																		</span>
																		<span className="tournament-participants__strength">
																			{el?.player2?.strength || ''}
																		</span>
																	</div>

																	{
																		authorized &&
																		<div
																			className="tournament_participants__dnd--doubles"
																			{...provided.dragHandleProps}
																		>
																			<DragIcon />
																		</div>
																	}
																</>
														}
													</div>
												)
											}
										</Draggable>
									)}
									{provided.placeholder}
								</div>
							)
						}
					</Droppable>
				</DragDropContext>
			</div>
		</>
	)
}

export default TournamentParticipantsList