import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik, Field } from 'formik'
import { useNavigate } from 'react-router'

import './SettingsAccount.scss'
import Header from '../../reusableComponents/Header/Header'
import BottomMenu from '../../reusableComponents/BottomMenu/BottomMenu'
import Input from '../../reusableComponents/Input/Input'
import Button from '../../reusableComponents/Button/Button'
import { useUpdateUserInfoMutation, useGetUserInfoQuery } from '../../redux/userService/userApiSlice'
import { isValidEmail } from '../../utils/functions'
import Loader from '../../reusableComponents/Loader/Loader'
import { replacePhone } from '../../utils/functions2'

function SettingsAccount() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { data = {}, isLoading } = useGetUserInfoQuery()
	const [updateInfo] = useUpdateUserInfoMutation()

	const { last_name, first_name, middle_name, mobile, email } = useMemo(() => {
		return data
	}, [data])

	function validateFirstName(value, setFieldError) {
		if (value.length > 0) {
			return false
		} else {
			setFieldError('firstName', t('Enter first name'))
			return true
		}
	}

	function validateLastName(value, setFieldError) {
		if (value.length > 0) {
			return false
		} else {
			setFieldError('lastName', t('Enter last name'))
			return true
		}
	}

	function validateEmail(email, setFieldError) {
		if (email.length > 0) {
			if (isValidEmail(email)) {
				return false
			} else {
				setFieldError('email', t('Invalid email'))
				return true
			}
		} else {
			setFieldError('email', t('Enter Email'))
			return true
		}
	}

	function validatePhone(phone, setFieldError) {
		const phoneNumberRegex = /^((\+7|8)?(9\d{9}))$/g

		if (phone && String(phone)?.length >= 10) {
			if (phoneNumberRegex.test(phone)) {
				return false
			} else {
				setFieldError('phone', t('Invalid phone number'))
				return true
			}
		} else if (phone && String(phone)?.length < 10){
			setFieldError('phone', t('Invalid phone number'))
			return true
		} else {
			return false
		}
	}

	function checkLength(value) {
		if (!value) {
			return false
		}

		return value.trim().length > 0
	}

	function buildBody(values) {
		const {
			lastName,
			firstName,
			middleName,
			email,
			phone,
		} = values

		const body = {
			email
		}

		if (checkLength(lastName)) {
			body.last_name = lastName
		}

		if (checkLength(firstName)) {
			body.first_name = firstName
		}

		if (checkLength(middleName)) {
			body.middle_name = middleName
		}

		if (checkLength(phone)) {
			body.mobile = replacePhone(phone)
		}

		return body
	}

	async function submit(values, setFieldError) {
		const phoneErr = validatePhone(values.phone, setFieldError)
		const firstNameErr = validateFirstName(values.firstName, setFieldError)
		const lastNameErr = validateLastName(values.lastName, setFieldError)
		const emailErr = validateEmail(values.email, setFieldError)

		if (phoneErr || firstNameErr || lastNameErr || emailErr) {
			return
		}

		try {
			const body = buildBody(values)
			const response = await updateInfo(body).unwrap()

			if (response?.error) {
				return
			}

			navigate('/account')
		} catch (e) {
			const errorEmailText = e?.data?.email[0]

			if (errorEmailText) {
				setFieldError('email', errorEmailText)
			}

			console.log('Account settings submit catches error', e)
		}
	}

	return (
		<>
			<Header
				back
				title={t('Edit')}
				backUrl={-1}
				border
			/>

			<main className="settings-account">
				{
					isLoading ?
						<Loader />
						:
						<>
							<Formik
								initialValues={{
									lastName: last_name || '',
									firstName: first_name || '',
									middleName: middle_name || '',
									email: email || '',
									phone: mobile || '',
								}}
								onSubmit={async (values, { setFieldError }) => {
									await submit(values, setFieldError)
								}}
							>
								{({ values, errors, touched, handleSubmit, handleChange, setFieldError }) => (
									<form
										onSubmit={handleSubmit}
										noValidate
										className="settings-account__form"
									>
										<Field
											as={Input}
											type="text"
											onChange={handleChange}
											value={values.lastName}
											placeholder={t('Last name')}
											error={errors.lastName}
											touched={touched.lastName}
											name="lastName"
											onClick={() => {
												setFieldError('lastName', '')
											}}
										/>

										<Field
											as={Input}
											type="text"
											onChange={handleChange}
											value={values.firstName}
											placeholder={t('First name')}
											error={errors.firstName}
											touched={touched.firstName}
											name="firstName"
											onClick={() => {
												setFieldError('firstName', '')
											}}
										/>

										<Field
											as={Input}
											type="text"
											onChange={handleChange}
											value={values.middleName}
											placeholder={t('Middle name')}
											error={errors.middleName}
											touched={touched.middleName}
											name="middleName"
										/>

										<Field
											as={Input}
											type="email"
											onChange={handleChange}
											value={values.email}
											placeholder="Email"
											required={true}
											error={errors.email}
											touched={touched.email}
											name="email"
											onClick={() => {
												setFieldError('email', '')
											}}
										/>

										<Field
											as={Input}
											type="tel"
											onChange={handleChange}
											value={values.phone}
											placeholder={t('Phone number')}
											error={errors.phone}
											touched={touched.phone}
											name="phone"
											onClick={() => {
												setFieldError('phone', '')
											}}
										/>

										<div className="settings-account__button">
											<Button
												title={t('Save')}
												type="submit"
											/>
										</div>
									</form>
								)}
							</Formik>
							
							{/* <div className="settings-account__delete-button">
								<StringButton title={t('Delete account')} />
							</div> */}
						</>
				}
			</main>

			<BottomMenu />
		</>
	)
}

export default SettingsAccount