import './ArrowUpDown.scss'
import { ReactComponent as ArrowDownIcon } from '../../icons/arrow_down.svg'

function ArrowUpDown( { isUp, light, onClick } ) {
	function handleClick(e) {
		e.preventDefault()
		onClick()
	}

	return (
		<button
			className="arrow-up-down"
			onClick={handleClick}
		>
			<ArrowDownIcon
				className={isUp ? 'up' : 'down'}
				style={{
					fill: light ? 'var(--palette-live-grey-7)' : ''
				}}
			/>
		</button>
	)
}

export default ArrowUpDown