import { createSlice } from '@reduxjs/toolkit'

import { getLocalStorageItem, parseObject, setItemInLocalStorage } from '../../utils/functions'

const communitiesSlice = createSlice({
	name: 'communities',
	initialState: {
		communities: parseObject('communities', getLocalStorageItem) || []
	},
	reducers: {
		setCommunities(state, action) {
			const comunitites = JSON.stringify(action.payload)
			setItemInLocalStorage('communities', comunitites)
			state.communities = action.payload
		}
	}
})

export const {
	setCommunities,
} = communitiesSlice.actions
export default communitiesSlice.reducer