import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik, Field } from 'formik'

import './PasswordChange.scss'
import Header from '../../reusableComponents/Header/Header'
import BottomMenu from '../../reusableComponents/BottomMenu/BottomMenu'
import Input from '../../reusableComponents/Input/Input'
import Button from '../../reusableComponents/Button/Button'
import { useChangeUserPasswordMutation } from '../../redux/userService/userApiSlice'
import { incorrectOldPassword } from '../../utils/constants'

function PasswordEdition() {
	const { t } = useTranslation()
	const [changePassword] = useChangeUserPasswordMutation()

	const [requestIsActive, setRequestIsActive] = useState(false)

	function validateCurrentPassword(password) {
		if (checkLength(password)) {
			return false
		} else {
			return t('Enter current password')
		}
	}

	function validateNewPassword(values) {
		const { newPassword, oldPassword } = values

		if (checkLength(newPassword)) {
			if (oldPassword !== newPassword) {
				if (newPassword.length >= 8) {
					return false
				} else {
					return t('Password has to be at least 8 symbols')
				}
			} else {
				return t('New password matches with current password')
			}

		} else {
			return t('Write new password')
		}
	}

	function validateDuplicatePassword(values) {
		const { newPassword, duplicatePassword } = values

		if (checkLength(duplicatePassword)) {
			if (newPassword === duplicatePassword) {
				return false
			} else {
				return t('Passwords do not match')
			}
		} else {
			return t('Repeat new password')
		}
	}

	function checkLength(value) {
		return value?.trim().length > 0
	}

	async function submit(values, setFieldError, resetForm) {
		setRequestIsActive(true)

		const oldPasswordErr = validateCurrentPassword(values.oldPassword)
		if (oldPasswordErr) setFieldError('oldPassword', oldPasswordErr)

		const newPasswordErr = validateNewPassword(values)
		if (newPasswordErr) setFieldError('newPassword', newPasswordErr)

		const duplicatedPassword = validateDuplicatePassword(values)
		if (duplicatedPassword) setFieldError('duplicatePassword', duplicatedPassword)

		if (oldPasswordErr || newPasswordErr || duplicatedPassword) {
			setRequestIsActive(false)
			return
		}

		try {
			const body = {
				old: values.oldPassword,
				new: values.newPassword
			}

			const response = await changePassword(body).unwrap()

			if (response?.error) {
				setRequestIsActive(false)
				return
			}

			resetForm()
			setRequestIsActive(false)
		} catch (e) {
			const { detail = '' } = e?.data

			const errorText = detail.replace(' ', '').toLowerCase()
			const exeption = incorrectOldPassword.replace(' ', '').toLowerCase()

			if (errorText === exeption) {
				setFieldError('oldPassword', 'Некорректный текущий пароль')
			}

			setRequestIsActive(false)
			console.log('Password edition submit catches error', e)
		}
	}

	return (
		<>
			<Header
				back
				backUrl={'/account'}
				title={t('Change password')}
				border
			/>

			<main className="password-edition">
				<Formik
					initialValues={{
						oldPassword: '',
						newPassword: '',
						duplicatePassword: ''
					}}
					onSubmit={async (values, { setFieldError, resetForm }) => {
						await submit(values, setFieldError, resetForm)
					}}
				>
					{({ values, errors, touched, handleSubmit, handleChange, setFieldError }) => (
						<form
							onSubmit={handleSubmit}
							noValidate
							className="password-edition__form"
						>
							<Field
								as={Input}
								type="password"
								onChange={(e) => {
									handleChange(e)
									setFieldError('oldPassword', '')
								}}
								onClick={() => {
									setFieldError('oldPassword', '')
								}}
								value={values?.oldPassword}
								placeholder={t('Current password') + ' *'}
								required={true}
								error={errors?.oldPassword}
								touched={touched?.oldPassword}
								name="oldPassword"
							/>

							<Field
								as={Input}
								type="password"
								onChange={(e) => {
									handleChange(e)
									setFieldError('newPassword', '')
								}}
								onClick={() => {
									setFieldError('newPassword', '')
								}}
								value={values?.newPassword}
								placeholder={t('New password') + ' *'}
								required={true}
								error={errors?.newPassword}
								touched={touched?.newPassword}
								name="newPassword"
							/>

							<Field
								as={Input}
								type="password"
								onChange={(e) => {
									handleChange(e)
									setFieldError('duplicatePassword', '')
								}}
								onClick={() => {
									setFieldError('duplicatePassword', '')
								}}
								value={values?.duplicatePassword}
								placeholder={t('Repeat password') + ' *'}
								required={true}
								error={errors?.duplicatePassword}
								touched={touched?.duplicatePassword}
								name="duplicatePassword"
							/>

							<div className="password-edition__button">
								<Button
									title={t('Save')}
									type="submit"
									loading={requestIsActive}
								/>
							</div>
						</form>
					)}
				</Formik>
			</main>

			<BottomMenu />
		</>
	)
}

export default PasswordEdition