import { memo } from 'react'

import './Search.scss'
import { ReactComponent as LoupeIcon } from '../../icons/loupe.svg'

function Search({ onChange, placeholder, value }) {
	return (
		<>
			<div className="search">
				<LoupeIcon className="loupe-icon" />

				<input
					type="search"
					className="search__input"
					onChange={onChange}
					placeholder={placeholder}
					value={value}
				/>
			</div>
		</>
	)
}

export default memo(Search)