import { useState, useCallback, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import './SinglePlayersSearch.scss'
import { setAddedParticipants, setParticipants, setRemovedParticipants, setSeedRequired } from '../../redux/tournamentParticipantsService/tournamentParticipantsSlice'
import Search from '../../reusableComponents/Search/Search'
import Loader from '../../reusableComponents/Loader/Loader'
import { ReactComponent as CheckMark } from '../../icons/checkmark.svg'
import { itemsChangedOrder, processParticipants } from '../../utils/functions2'

import { selectTournament } from '../../redux/selectors'

const SinglePlayersSearch = ({
	participants,
	search,
	searchPlayersList,
	playersLoading,
	limitIsReached
}) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const {
		players,
		doubles,
		type
	} = useSelector(selectTournament)

	const [singlePlayerSearchValue, setSingleInputValue] = useState('')

	const handleSinglePlayerInput = useCallback((value) => {
		setSingleInputValue(value)
		search(value)
	}, [search])

	const handlePlayerInPopupList = useCallback((el) => {
		if (participants?.some(p => p?.uid === el?.uid)) {
			const updatedParticipants = participants?.filter(p => p?.uid !== el?.uid)

			dispatch(setRemovedParticipants({
				participants: players || doubles,
				participant: el,
				type
			}))
			dispatch(setParticipants({ participants: updatedParticipants, type }))
			const isChanged = itemsChangedOrder(updatedParticipants, players || doubles)
			dispatch(setSeedRequired(isChanged))
		} else {
			if (limitIsReached) {
				return
			} else {
				const updatedParticipants = processParticipants([...participants, el])

				dispatch(setParticipants({ participants: updatedParticipants, type }))
				dispatch(setAddedParticipants({
					participants: players || doubles,
					participant: el,
					type
				}))
				const isChanged = itemsChangedOrder(updatedParticipants, players || doubles)
				dispatch(setSeedRequired(isChanged))
			}
		}

	}, [dispatch, participants, type, limitIsReached, doubles, players])

	if ((playersLoading === 'pending') || (playersLoading === 'uninitialized')) {
		return <Loader />
	}

	return (
		<div className="single-players-search">
			<div className="single-players-search__input-wrapper">
				<Search
					placeholder={t('Search player')}
					value={singlePlayerSearchValue}
					onChange={(e) => handleSinglePlayerInput(e.target.value)}
				/>
			</div>

			{
				searchPlayersList?.length === 0 &&
				<span className="single-players-search__line"></span>
			}

			{
				searchPlayersList?.map((el, index) =>
					<div
						key={index}
						className="single-players-search__list-item"
						style={{
							backgroundColor: participants?.find(p => p.uid === el.uid) ?
								'#EBF4FF'
								:
								limitIsReached ?
									'#eeeeee'
									:
									''
						}}
						onClick={() => handlePlayerInPopupList(el, index)}
					>
						<p className="single-players-search__player-name">
							{el.last_name} {el.first_name}
						</p>

						<p className="single-players-search__player-strength">
							{el?.strength || ''}
						</p>

						{/* {
							participants?.find(p => p.uid === el.uid) &&
							<CheckMark />
						} */}
					</div>
				)
			}
		</div>
	)
}

export default memo(SinglePlayersSearch)