export const tiers = [
	{
		label: 'Т1',
		value: 'tier1'
	},
	{
		label: 'Т2',
		value: 'tier2'
	},
	{
		label: 'Т3',
		value: 'tier3'
	}
]