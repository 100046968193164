import { memo } from 'react'

import './LabelBlock.scss'

function LabelBlock({ label, content, borderTop, borderBottom, paddingLeftRight }) {
	return (
		<div
			className={`label-block ${borderBottom
				? 'label-block--bottom' : ''} ${borderTop
					? 'label-block--top' : ''}`}
			style={{
				padding: paddingLeftRight ? '12px' : '12px 0'
			}}
		>
			<label className="label-block__label">
				{label}
			</label>

			<span className="label-block__content">
				{content}
			</span>
		</div>
	)
}

export default memo(LabelBlock)