import { moldPlayersNames } from '../../utils/functions'
import { apiSlice } from '../apiSlice'

export const tournamentApi = apiSlice.injectEndpoints({
	reducerPath: 'tournamentApi',
	tagTypes: ['Tournament'],
	endpoints: (build) => ({
		getTournament: build.query({
			query: ({ tournament_uid }) => `tennis/tournaments/${tournament_uid}/`,
			transformResponse: (res) => {
				const { players, doubles, type } = res
				const formParticipants = moldPlayersNames(players || doubles || [], type)
				const key = res?.players ? 'players' : 'doubles'
				return { ...res, [key]: formParticipants }
			},
			providesTags: ['Tournament']
		}),
		getTournamentResults: build.query({
			query: ({ tournament_uid }) => `tennis/tournaments/${tournament_uid}/results/`,
			providesTags: ['Results']
		}),
		updateTournament: build.mutation({
			query: ({ tournament_uid, body }) => ({
				url: `tennis/tournaments/${tournament_uid}/`,
				method: 'PATCH',
				body
			}),
			invalidatesTags: ['Tournament']
		}),
		addSinglePlayers: build.mutation({
			query: ({ tournament_uid, body }) => ({
				url: `tennis/tournaments/${tournament_uid}/players/`,
				method: 'POST',
				body
			}),
			invalidatesTags: ['Tournament']
		}),
		seedParticipants: build.mutation({
			query: ({ tournament_uid, body }) => ({
				url: `tennis/tournaments/${tournament_uid}/players/seeds/`,
				method: 'PUT',
				body
			}),
			invalidatesTags: ['Tournament']
		}),
		removeParticipant: build.mutation({
			query: ({ tournament_uid, body }) => ({
				url: `tennis/tournaments/${tournament_uid}/players/`,
				method: 'DELETE',
				body
			}),
			invalidatesTags: ['Tournament']
		}),
		addDoubles: build.mutation({
			query: ({ tournament_uid, body }) => ({
				url: `tennis/tournaments/${tournament_uid}/doubles/`,
				method: 'POST',
				body
			}),
			invalidatesTags: ['Tournament']
		}),
		updateDoubleParticipant: build.mutation({
			query: ({ tournament_uid, body }) => ({
				url: `tennis/tournaments/${tournament_uid}/doubles/`,
				method: 'PATCH',
				body
			}),
			invalidatesTags: ['Tournament']
		}),
		seedDoubles: build.mutation({
			query: ({ tournament_uid, body }) => ({
				url: `tennis/tournaments/${tournament_uid}/doubles/seeds/`,
				method: 'PUT',
				body
			}),
			invalidatesTags: ['Tournament']
		}),
		removeDouble: build.mutation({
			query: ({ tournament_uid, body }) => ({
				url: `tennis/tournaments/${tournament_uid}/doubles/`,
				method: 'DELETE',
				body
			}),
			invalidatesTags: ['Tournament']
		})
	})
})

export const {
	useGetTournamentQuery,
	useGetTournamentResultsQuery,
	useAddSinglePlayersMutation,
	useSeedParticipantsMutation,
	useRemoveParticipantMutation,
	useAddDoublesMutation,
	useUpdateDoubleParticipantMutation,
	useSeedDoublesMutation,
	useRemoveDoubleMutation,
	useUpdateTournamentMutation
} = tournamentApi