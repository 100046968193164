import { memo, useState } from 'react'

import './InputLight.scss'
import { ReactComponent as VisibilityIcon } from '../../icons/visibility.svg'
import { ReactComponent as VisibilityOffIcon } from '../../icons/visibility_off.svg'
import { ReactComponent as EditPenIcon } from '../../icons/edit_pen.svg'
import { ReactComponent as ErrorIcon } from '../../icons/error.svg'

function InputLight({
	error,
	touched,
	type,
	value,
	defaultValue,
	placeholder,
	onChange,
	maxLength,
	readOnly,
	name,
	edit,
	onClick,
	styles
}) {
	const [passwordShow, setPasswordShow] = useState(false)
	const [inputId] = useState(`input-${Math.floor(Math.random() * 100)}`)
	const [focusedInputs, setFocusedInputs] = useState([])

	function handleFocus(id) {
		setFocusedInputs([id])
	}

	function handleBlur(id) {
		setFocusedInputs((prevInputs) => prevInputs.filter((input) => input !== id))
	}

	function getStyle() {
		if ((String(value).length > 0) || readOnly) {
			return {
				top: '9px',
				fontSize: '14px',
			}
		}
	}

	function defineOpacity() {
		if (type === 'date' && !focusedInputs.includes(inputId) && !value) {
			return '0'
		} else {
			return '1'
		}
	}

	return (
		<div className={`input-light ${error ? 'error' : ''}`} style={styles ? styles : {}}>
			<div
				className={
					`input__wrapper ${focusedInputs.includes(inputId) ?
						'input__wrapper--focus'
						:
						error && touched ?
							'input__wrapper--error'
							:
							''
					}`
				}
			>
				<div className="input__container">
					<input
						type={type === 'password' ? passwordShow ? 'text' : 'password' : type}
						value={value}
						defaultValue={defaultValue}
						className={
							`input__element ${focusedInputs.includes(inputId) ? 'input__element--focus' : ''}`
						}
						onChange={onChange}
						maxLength={maxLength ? maxLength : 64}
						readOnly={readOnly}
						name={name}
						required
						onFocus={() => handleFocus(inputId)}
						onBlur={() => handleBlur(inputId)}
						style={{ opacity: defineOpacity() }}
						min="1"
						onClick={onClick}
						max="9999-12-31"
					/>

					<label
						style={getStyle()}
						className="input__label"
					>
						{ placeholder }
					</label>
				</div>

				{
					type === 'password' ?
						!passwordShow ?
							<VisibilityIcon onClick={() => setPasswordShow(true)} className="visibility__icon" />
							:
							<VisibilityOffIcon onClick={() => setPasswordShow(false)} className="visibility__icon" />
						:
						edit ?
							<EditPenIcon className="edit-pen__icon" onClick={onClick} />
							: error &&
							<ErrorIcon />
				}
			</div>

			{
				error && touched &&
					<p className="input__error">
						{ error }
					</p>
			}
		</div>
	)
}

export default memo(InputLight)