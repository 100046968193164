import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { useGetCommunitiesQuery } from '../../redux/communitiesService/communitiesApiSlice'

import Loader from '../../reusableComponents/Loader/Loader'

import { selectCommunity } from '../../redux/selectors'
import { setCommunityName, setCommunityUid } from '../../redux/communityService/communitySlice'
import { setCommunities } from '../../redux/communitiesService/communitiesSlice'

const Communities = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const location = useLocation()
	const { communityUid } = useSelector(selectCommunity)
	const { data = [], error, isLoading } = useGetCommunitiesQuery()

	const goToCommunity = useCallback((community) => {
		const queryParams = new URLSearchParams(location.search)
		const redirectPath = queryParams.get('redirect')

		dispatch(setCommunityUid(community.uid))
		dispatch(setCommunityName(community.name))

		navigate(redirectPath || '/tournaments')
	}, [navigate, location, dispatch])

	useEffect(() => {
		if (data.length > 0 && !isLoading && !error) {
			dispatch(setCommunities(data))

			let workCommunity

			if (communityUid) {
				workCommunity = data.find(community => community.uid === communityUid)
				if (!workCommunity) {
					workCommunity = data[0]
				}
			} else {
				workCommunity = data[0]
			}

			goToCommunity(workCommunity)
		}
	}, [data, isLoading, error, goToCommunity, communityUid, dispatch])

	return (
		<>
			{
				isLoading && <Loader />
			}
		</>
	)
}

export default Communities
