import './index.scss'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
// eslint-disable-next-line
import { BrowserRouter, HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import packageJson from '../package.json'

import App from './App.jsx'
import reportWebVitals from './reportWebVitals'
import { store } from './redux/store'
import i18n from './translator/i18next.js'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
	<BrowserRouter>
		<Provider store={store}>
			<p className="version-app__container">
				{ packageJson.version }
			</p>

			<I18nextProvider i18n={i18n}>
				<App />
			</I18nextProvider>
		</Provider>
	</BrowserRouter>
)

reportWebVitals()