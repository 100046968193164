import { memo } from 'react'

import './StringButton.scss'

function StringButton({ onClick, title }) {
	return (
		<button
			onClick={onClick}
			className="string-button"
		>
			{ title }
		</button>
	)
}

export default memo(StringButton)