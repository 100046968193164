import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import './VenueInfo.scss'
import { useGetVenueQuery } from '../../redux/venuesService/venuesApiSlice'
import Header from '../../reusableComponents/Header/Header'
import BottomMenu from '../../reusableComponents/BottomMenu/BottomMenu'
import LabelBlock from '../../reusableComponents/LabelBlock/LabelBlock'
import Loader from '../../reusableComponents/Loader/Loader'

function VenueInfo() {
	const { t } = useTranslation()
	const { uid } = useParams()
	const { data = [], isLoading } = useGetVenueQuery(uid)

	return (
		<>
			<Header
				title={t('Venue')}
				edit
				editUrl={`/venue/form/${uid}`}
				back
				backUrl={'/venues'}
				border
			/>

			<main className="venue-info">
				{
					isLoading ?
						<Loader />
						:
						<>
							<LabelBlock
								borderTop
								borderBottom
								label={t('Name')}
								content={data.name}
							/>

							<LabelBlock
								borderBottom
								label={t('Address')}
								content={data.address_string  || '___'}
							/>

							<LabelBlock
								borderBottom
								label={t('Surface')}
								content={t(data.surface) || '___'}
							/>
						</>
				}
			</main>

			<BottomMenu />
		</>
	)
}

export default VenueInfo