import { createSlice } from '@reduxjs/toolkit'

import { getLocalStorageItem, removeItemInLocalStorage, setItemInLocalStorage } from '../../utils/functions'

const authSlice = createSlice({
	name: 'auth',
	initialState: {
		authorized: getLocalStorageItem('authorized') ? true : false,
		access: getLocalStorageItem('access'),
		refresh: getLocalStorageItem('refresh'),
		guestCode: getLocalStorageItem('guestCode'),
		accessExpiresAt: getLocalStorageItem('accessExpiresAt'),
		refreshTokenExpiresAt: getLocalStorageItem('refreshTokenExpiresAt')
	},
	reducers: {
		setAuthorized(state, action) {
			setItemInLocalStorage('authorized', action.payload)
			state.authorized = action.payload
		},
		setAccessToken(state, action) {
			setItemInLocalStorage('access', action.payload)
			state.access = action.payload
		},
		setAccessTokenTimeExpiration(state, action) {
			setItemInLocalStorage('accessExpiresAt', action.payload)
			state.accessExpiresAt = action.payload
		},
		setRefreshTokenTimeExpiration(state, action) {
			setItemInLocalStorage('refreshTokenExpiresAt', action.payload)
			state.refreshTokenExpiresAt = action.payload
		},
		setRefreshToken(state, action) {
			setItemInLocalStorage('refresh', action.payload)
			state.refresh = action.payload
		},
		setGuestCode(state, action){
			if (action.payload !== null) {
				setItemInLocalStorage('guestCode', action.payload)
			} else {
				removeItemInLocalStorage('guestCode')
			}

			state.guestCode = action.payload
		},
		resetAuth(state) {
			localStorage.clear()
			state.authorized = false
			state.access = null
			state.refresh = null
			state.accessExpiresAt = null
		}
	}
})

export const {
	setAuthorized,
	setAccessToken,
	setRefreshToken,
	setGuestCode,
	resetAuth,
	setAccessTokenTimeExpiration,
	setRefreshTokenTimeExpiration
} = authSlice.actions
export default authSlice.reducer