import './ErrorLink.scss'

import { ReactComponent as WarningIcon } from '../../icons/warning.svg'

function ErrorLink() {
	return (
		<div className="error-link__container">
			<div className="error-link__block">
				<WarningIcon className="warning-icon" />
			</div>

			<div className="error-link__specification">
				<p className="error-link__title">
					Такого турнира нет, или у вас нет доступа
				</p>

				<p className="error-link__description">
					Если вы организатор, пожалуйста, войдите в свой аккаунт
				</p>
			</div>
		</div>
	)
}

export default ErrorLink
