import { memo } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import './SplashScreen.scss'
import { ReactComponent as TenturLogoIcon } from '../../icons/tentur_logo.svg'
import Button from '../../reusableComponents/Button/Button'

import { selectAuth } from '../../redux/selectors'

function SplashScreen() {
	const navigate = useNavigate()
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const redirectPath = queryParams.get('redirect')
	const { authorized } = useSelector(selectAuth)

	function goToLogin() {
		if (redirectPath) {
			navigate('/login?redirect=' + redirectPath)
		} else {
			navigate('/login')
		}
	}

	return (
		<>
			{!authorized ?
				<main className="splash-screen">
					<div className="splash-screen__logo-container">
						<TenturLogoIcon className="tentur-logo__icon" />

						<p className="splash-screen__logo-text">
							Инструмент <br /> для теннисных сеток
						</p>
					</div>

					<div className="splash-screen__button-wrapper">
						<Button
							title="Вход"
							border
							background="color"
							onClick={goToLogin}
							marginBottom
						/>

						<Button
							title="Регистрация"
							background="white"
							onClick={() => navigate('/registration')}
						/>
					</div>

					<Link
						to="/info"
						className="splash-screen__link"
					>
						Информация о сайте
					</Link>
				</main>
				:
				<Navigate to={'/tournaments'}/>
			}
		</>
	)
}

export default memo(SplashScreen)