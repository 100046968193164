import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { CREATE, FILLED, FULFILLED, GENERATE } from '../utils/constants'
import { setInitialStage, setStageCondition } from '../redux/stageService/stageSlice'

function useProcessStageData(stageData, status) {
	const dispatch = useDispatch()

	useEffect(() => {
		if (status !== FULFILLED) {
			return
		}

		if (Object.keys(stageData).length !== 0) {
			dispatch(setStageCondition({
				stageStatus: CREATE,
				stageExists: true
			}))

			if (stageData.levels.length > 0) {
				dispatch(setStageCondition({
					stageStatus: FILLED,
					stageExists: true
				}))
			} else {
				dispatch(setStageCondition({
					stageStatus: GENERATE,
					stageExists: true
				}))
			}

			dispatch(setInitialStage(stageData))
		} else {
			dispatch(setStageCondition({
				stageStatus: CREATE,
				stageExists: false
			}))
		}
	}, [stageData, status, dispatch])

	return
}

export { useProcessStageData }