import { apiSlice } from '../apiSlice'

export const authApiSlice = apiSlice.injectEndpoints({
	reducerPath: 'loginApi',
	tagTypes: ['Login'],
	endpoints: builder => ({
		login: builder.mutation({
			query: (body) => ({
				url: '/token/',
				method: 'POST',
				body
			})
		}),
		refreshToken: builder.mutation({
			query: (token) => ({
				url: '/token/refresh/',
				method: 'POST',
				body: { refresh: token }
			})
		}),
	})
})

export const { useLoginMutation, useRefreshTokenMutation } = authApiSlice