import { Formik, Field } from 'formik'

import './PasswordRecovery.scss'
import Header from '../../reusableComponents/Header/Header'
import Button from '../../reusableComponents/Button/Button'
import Input from '../../reusableComponents/Input/Input'

function PasswordRecovery() {
	return (
		<>
			<Header
				back
				title="Восстановление пароля"
				backUrl={-1}
				border
			/>

			<main className="password-recovery">
				<Formik
					initialValues={{
						lastName: '',
					}}
					onSubmit={async (values) => {
						console.log('Send')
					}}
				>
					{({ values, errors, touched, handleSubmit, handleChange, setFieldError }) => (
						<form
							onSubmit={handleSubmit}
							noValidate
							className="password-recovery__form"
						>
							<Field
								as={Input}
								type="text"
								onChange={handleChange}
								value={values.lastName}
								placeholder="Введите номер телефона или Email"
							/>

							<p className="password-recovery__description">
								Мы отправим Вам код восстановления вашего пароля
							</p>

							<div className="password-recovery__button">
								<Button
									title="Отправить код"
									type="submit"
								/>
							</div>
						</form>
					)}
				</Formik>
			</main>
		</>
	)
}

export default PasswordRecovery