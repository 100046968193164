import { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import './Community.scss'
import Header from '../../reusableComponents/Header/Header'
import BottomMenu from '../../reusableComponents/BottomMenu/BottomMenu'
import ArrowLinkBlock from '../../reusableComponents/ArrowLinkBlock/ArrowLinkBlock'
import Input from '../../reusableComponents/Input/Input'
import Popup from '../../reusableComponents/Popup/Popup'
import Button from '../../reusableComponents/Button/Button'
import RadioButton from '../../reusableComponents/RadioButton/RadioButton'
import { useUpdateCommunityMutation } from '../../redux/communityService/communityApiSlice'

import { selectCommunity, selectCommunities } from '../../redux/selectors'
import { setCommunityName, setCommunityUid } from '../../redux/communityService/communitySlice'
import { setCommunities } from '../../redux/communitiesService/communitiesSlice'

function Community() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { communityName, communityUid } = useSelector(selectCommunity)
	const { communities } = useSelector(selectCommunities)
	const [updateCommunity] = useUpdateCommunityMutation()
	const [name, setName] = useState(communityName)
	const [nameEditionOpened, setNameEditionOpened] = useState(false)
	const [communitySwitcherOpened, setCommunitySwitcherOpened] = useState(false)
	const [chosenCommunity, setChosenCommunity] = useState(communityUid)

	const handleChangeName = useCallback((e) => {
		setName(e.target.value)
	}, [])

	const updateCommunityName = useCallback(async () => {
		if (!communityUid) return

		if (!name?.replace(' ', '') || name === communityName) {
			setName(communityName)

			return
		}

		const body = {
			name: name,
			country_code: 'ru'
		}

		try {
			const response = await updateCommunity({ community_uid: communityUid, body }).unwrap()

			if (response?.error) {
				return
			} else {
				const updatedCommunities = communities.map(item => {
					if (item.uid === response.uid) {
						return response
					} else {
						return item
					}
				})

				dispatch(setCommunities(updatedCommunities))
				dispatch(setCommunityName(name))
				setNameEditionOpened(false)
			}
		} catch (e) {
			console.log('Community update catched error', e)
		}
	}, [name, updateCommunity, communityUid, communityName, communities, dispatch])

	const handleCommunityChange = useCallback((e) => {
		setChosenCommunity(e.target.value)
	}, [])

	function switchCommunity() {
		const nameOfChosenCommunity = communities.find(item => item.uid === chosenCommunity).name
		dispatch(setCommunityName(nameOfChosenCommunity))
		dispatch(setCommunityUid(chosenCommunity))
		setName(nameOfChosenCommunity)
		setCommunitySwitcherOpened(false)
	}

	function cancelEdition() {
		setName(communityName)
		setNameEditionOpened(false)
	}

	return (
		<>
			<Header
				communityName={communityName}
				title={t('Community')}
				border
			/>

			<main className="community">
				<Input
					placeholder={t('Community name')}
					type="text"
					value={communityName}
					edit
					onChange={handleChangeName}
					readOnly
					onClick={() => setNameEditionOpened(true)}
				/>

				<ArrowLinkBlock
					to={'/venues'}
					borderBottom
					borderTop
				>
					{ t('Venues') }
				</ArrowLinkBlock>

				{/* <ArrowLinkBlock
					to={'#'}
					borderBottom
				>
					{ t('Организаторы турниров') }
				</ArrowLinkBlock> */}

				<ArrowLinkBlock
					to={'#'}
					borderBottom
					borderTop
					onClick={() => setCommunitySwitcherOpened(true)}
				>
					{ t('Сменить сообщество') }
				</ArrowLinkBlock>
			</main>

			{
				nameEditionOpened &&
					<Popup title={t('Community name change')}>
						<input
							className="community__popup-input"
							value={name}
							onChange={handleChangeName}
							maxLength={'16'}
						/>

						<div className="community__popup-buttons">
							<Button
								title={t('Save')}
								onClick={updateCommunityName}
								marginRight
							/>

							<Button
								title={t('Cancel')}
								onClick={cancelEdition}
								background={'white'}
							/>
						</div>
					</Popup>
			}

			{
				communitySwitcherOpened &&
					<Popup title={t('Community switch')}>
						{
							communities?.map((item, index) => (
								<RadioButton
									borderTop={index === 0}
									borderBottom
									key={index}
									name={'community'}
									id={`community-${index}`}
									label={item.name}
									value={item.uid}
									checked={item.uid === chosenCommunity}
									onChange={handleCommunityChange}
								/>
							))
						}

						<div className="community__popup-buttons">
							<Button
								title={t('Done')}
								onClick={switchCommunity}
								marginRight
							/>

							<Button
								title={t('Cancel')}
								onClick={() => setCommunitySwitcherOpened(false)}
								background={'white'}
							/>
						</div>
					</Popup>
			}

			<BottomMenu />
		</>
	)
}

export default Community