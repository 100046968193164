import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { jwtDecode } from 'jwt-decode'

import { checkTokenExpiration } from '../../utils/functions'

import { setAccessToken, setAccessTokenTimeExpiration } from './authSlice'

export async function refreshToken(
	token, api, extraOptions, dispatch, refreshTokenExpiresAt, communityUid
) {
	const refreshTokeIsAlive = checkTokenExpiration(refreshTokenExpiresAt)

	if (!refreshTokeIsAlive) {
		return { success: false }
	}

	const refreshQuery = fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		method: 'POST',
		body: { refresh: token }
	})

	try {
		const refreshResult = await refreshQuery('/token/refresh/', api, extraOptions)
		if (refreshResult?.data) {
			const token = refreshResult?.data.access
			dispatch(setAccessToken(token))

			const accessExpiresAtUTC = jwtDecode(token).exp * 1000
			dispatch(setAccessTokenTimeExpiration(accessExpiresAtUTC))
			return { access: token, success: true }
		} else {
			return { success: false }
		}
	} catch (e) {
		console.log('refreshToken catch error', e)
	}
}