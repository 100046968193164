import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import './PopupError.scss'
import { setError } from '../../redux/errorService/errorSlice'
import Button from '../Button/Button'
import { selectError } from '../../redux/selectors'
import { CONNECTION_ERROR } from '../../utils/constants'

function PopupError() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const error = useSelector(selectError)

	function sendReport() {
		dispatch(setError(false))
	}

	return (
		<div className="popup-error">
			<div className="popup-error__wrapper">
				<p className="popup-error__body">
					{
						error?.originalStatus === 500 ?
							t('The server is not responding. Please try later')
							:
							error.status === CONNECTION_ERROR ?
								'Ошибка соединения. Проверьте подключение к сети'
								: error ?
									t('Internal error. Please try later')
									:
									''
					}
				</p>

				<Button
					onClick={sendReport}
					title={t('Close')}
				/>
			</div>
		</div>
	)
}

export default PopupError