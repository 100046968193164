import { createSlice } from '@reduxjs/toolkit'

import { NEW_PLAYER_BY_LIST, TYPE_DOUBLES } from '../../utils/constants'
import { getSessionStorageItem, parseObject, removeItemInSessionStorage, setItemInSessionStorage } from '../../utils/functions'

function getBooleanValue(str) {
	const value = getSessionStorageItem(str)
	return value === 'false' || !value ? false : true
}

const tournamentParticipantsSlice = createSlice({
	name: 'participants',
	initialState: {
		participants: parseObject('participants', getSessionStorageItem) || [],
		optionsOfSingle: {
			uid: '',
			value: ''
		},
		optionsOfDouble: {
			uid: '',
			value1: '',
			value2: '',
			player1: {},
			player2: {}
		},
		add: parseObject('userActions', getSessionStorageItem)?.add || [],
		remove: parseObject('userActions', getSessionStorageItem)?.remove || [],
		seed: parseObject('userActions', getSessionStorageItem)?.seed || false,
		doublesAreChanged: parseObject('doublesAreChanged', getSessionStorageItem)?.seed || false,
		createNewPlayer: getBooleanValue('createNewPlayer'),
		createdPlayers: parseObject('createdPlayers', getSessionStorageItem) || [],
		addByList: getBooleanValue('addByList'),
		addedPlayersByList: [NEW_PLAYER_BY_LIST],
		parsedDoubles: parseObject('parsedDoubles', getSessionStorageItem) || [],
		parsedDoublesInitial: parseObject('parsedDoublesInitial', getSessionStorageItem) || []
	},
	reducers: {
		setParticipants(state, action) {
			const { participants } = action.payload
			setItemInSessionStorage('participants', JSON.stringify(participants))
			state.participants = participants
		},
		addDouble(state, action) {
			const { participant } = action.payload
			const updated = [...state.participants, participant]
			setItemInSessionStorage('participants', JSON.stringify(updated))
			state.participants = updated
		},
		setRemovedParticipants(state, action) {
			const { participants, participant, type } = action.payload

			const isSavedParticipant = participants?.some(p => {
				if (type === TYPE_DOUBLES) {
					const isSavedDouble = p.player1.uid === participant.player1.uid && p.player2.uid === participant.player2.uid

					if (!isSavedDouble) {
						return false
					}
				} else {
					const isSavedSingle = p.uid === participant.uid

					if (!isSavedSingle) {
						return false
					}
				}

				return true
			})

			if (isSavedParticipant) {
				state.remove.push(participant)
			}

			state.add = state.add.filter(item => item.uid !== participant.uid)

			const actions = { add: [...state.add], remove: [...state.remove], seed: state.seed }
			setItemInSessionStorage('userActions', JSON.stringify(actions))
		},
		setAddedParticipants(state, action) {
			const { participants, participant, type } = action.payload
			let isNewParticipant = true

			if (participants && participants.length > 0) {
				isNewParticipant = !participants?.some(p => {
					if (type === TYPE_DOUBLES) {
						return p.player1.uid === participant.player1.uid && p.player2.uid === participant.player2.uid
					} else {
						return p.uid === participant.uid
					}
				})
			}

			if (isNewParticipant) {
				const newAdd = [...state.add, participant]
				state.add = newAdd
			}

			state.remove = state.remove.filter(item => {
				return item.uid !== participant.uid
			})

			const actions = { add: [...state.add], remove: state.remove, seed: state.seed }
			setItemInSessionStorage('userActions', JSON.stringify(actions))
		},
		removeAddedParticipant(state, action) {
			const update = state.add.filter(item => {
				return item.uid !== action.payload
			})
			state.add = update
		},
		setSeedRequired(state, action) {
			state.seed = action.payload

			const actions = { add: [...state.add], remove: [...state.remove], seed: action.payload }
			setItemInSessionStorage('userActions', JSON.stringify(actions))
		},
		setOptionsOfSingle(state, action) {
			state.optionsOfSingle = action.payload
		},
		setOptionsOfDouble(state, action) {
			state.optionsOfDouble = action.payload
		},
		setCreateNewPlayer(state, action) {
			setItemInSessionStorage('createNewPlayer', action.payload)
			state.createNewPlayer = action.payload
		},
		setAddByList(state, action) {
			setItemInSessionStorage('addByList', action.payload)
			state.addByList = action.payload
		},
		setCreatedPlayers(state, action) {
			setItemInSessionStorage('createdPlayers', JSON.stringify(action.payload))
			state.createdPlayers = action.payload
		},
		setAddedPlayersByList(state, action) {
			state.addedPlayersByList = action.payload
		},
		resetDoneActions(state){
			removeItemInSessionStorage('userActions')
			state.add = []
			state.remove = []
			state.seed = false
			state.doublesAreChanged = false
		},
		setParsedDoubles(state, action) {
			setItemInSessionStorage('parsedDoubles', JSON.stringify(action.payload))
			state.parsedDoubles = action.payload
		},
		setParsedDoublesInitial(state, action) {
			setItemInSessionStorage('parsedDoublesInitial', JSON.stringify(action.payload))
			state.parsedDoublesInitial = action.payload
		},
		setDoublesAreChanged(state, action) {
			state.doublesAreChanged = action.payload
		},
	}
})

export const {
	setParticipants,
	addDouble,
	setRemovedParticipants,
	setAddedParticipants,
	removeAddedParticipant,
	setSeedRequired,
	setOptionsOfSingle,
	setOptionsOfDouble,
	resetDoneActions,
	setCreateNewPlayer,
	setAddByList,
	setCreatedPlayers,
	setAddedPlayersByList,
	setParsedDoubles,
	setParsedDoublesInitial,
	setDoublesAreChanged
} = tournamentParticipantsSlice.actions
export default tournamentParticipantsSlice.reducer