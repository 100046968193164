import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import './Players.scss'
import { useGetPlayersQuery } from '../../redux/playersService/playersApiSlice'
import { selectCommunity } from '../../redux/selectors'
import { findPlayers } from '../../utils/functions'
import Header from '../../reusableComponents/Header/Header'
import Loader from '../../reusableComponents/Loader/Loader'
import BottomMenu from '../../reusableComponents/BottomMenu/BottomMenu'
import Search from '../../reusableComponents/Search/Search'
import ArrowLinkBlock from '../../reusableComponents/ArrowLinkBlock/ArrowLinkBlock'
import { ReactComponent as PlayerIcon } from '../../icons/player.svg'
import AddButton from '../../reusableComponents/AddButton/AddButton'
import Plug from '../../reusableComponents/Plug/Plug'

import { sortArrayOfObjectsByName } from './externalFunctions'

function Players() {
	const { t } = useTranslation()
	const { communityUid, communityName } = useSelector(selectCommunity)
	const [players, setPlayers] = useState([])
	const [playerName, setPlayerName] = useState('')

	const { data = [], isLoading } = useGetPlayersQuery({ community_uid: communityUid })

	useEffect(() => {
		if (data.length !== 0) {
			const sortedPlayers = sortArrayOfObjectsByName(data)

			setPlayers(sortedPlayers)
		}
	}, [data])

	async function searchPlayer(value) {
		setPlayerName(value)

		if (value !== '') {
			const searchedPlayers = findPlayers(value, data)

			setPlayers(searchedPlayers)
		} else {
			setPlayers(data)
		}
	}

	return (
		<>
			<Header
				title={'ИГРОКИ'}
				communityName={communityName}
				border
			/>

			{data.length > 0 &&
				<div className="search__wrapper">
					<Search
						placeholder={t('Player search')}
						onChange={(e) => searchPlayer(e.target.value)}
						value={playerName}
					/>
				</div>
			}

			<main className="players">
				{
					isLoading ?
						<Loader />
						:
						<>
							{
								data?.length < 1 ?
									<Plug
										icon={<PlayerIcon className="icon" />}
										title={t('Add players')}
										description={t('Add a player using the plus button')}
									/>
									:
									<>
										{
											players.map((item, index) => (
												<ArrowLinkBlock
													key={index}
													to={`/player/info/${item.uid}`}
													borderTop={true}
													borderBottom={players?.length === index + 1}
													light
												>
													<div className="players__wrapper">
														<span className="players__number">
															{index + 1}
														</span>

														<span className="players__name">
															{item?.last_name} {item?.first_name}
														</span>
													</div>
												</ArrowLinkBlock>
											))
										}
									</>
							}
						</>
				}
			</main>

			<AddButton
				to={'/player/form'}
			/>

			<BottomMenu />
		</>
	)
}

export default Players