import { useCallback, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import './RemoveStageButton.scss'
import { useRemoveStageMutation } from '../../redux/stageService/stageApiSlice'
import { selectTournament, selectTournamentOptions } from '../../redux/selectors'
import { setTournamentData } from '../../redux/singleTournamentService/singleTournamentSlice'
import {
	setChosenPlayerInList,
	setCurrentStageNumber,
	setPlayersList,
	setProcessedStage,
	setStageCondition
} from '../../redux/stageService/stageSlice'
import Popup from '../Popup/Popup'
import RoundedButton from '../RoundedButton/RoundedButton'
import { CREATE } from '../../utils/constants'
import { setInitialStage } from '../../redux/stageService/stageSlice'

function RemoveStageButton({ tournamentRefetch }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()
	const { stageNumber } = useParams()
	const tournament = useSelector(selectTournament)
	const { stages_count, uid: tournamentUid } = tournament
	const { stage: options = {} } = useSelector(selectTournamentOptions)
	const [removeStageRequest] = useRemoveStageMutation()

	const [removeStageConfirmation, setRemoveStageConfirmation] = useState(false)
	const [requestIsActive, setRequestIsActive] = useState({ stageRemove: false, generateStage: false })

	const handleRemoveStage = useCallback(async () => {
		setRequestIsActive(prev => { return { ...prev, stageRemove: true } })
		try {
			let response = {}

			for (let i = stageNumber - 1; i < stages_count; i++) {
				response = await removeStageRequest({
					tournament_uid: tournamentUid
				}).unwrap()

				if (response?.error) {
					console.log(`Remove stage return error: ${response?.error}`)
				}
			}

			const updatedTournament = {
				...tournament,
				stages_count: response.stages_count
			}

			await tournamentRefetch()

			const targetStageNumber = response.stages_count > 0 ? response.stages_count : 1

			// очищаем состояние этапа/ов
			dispatch(setProcessedStage({}))
			dispatch(setTournamentData(updatedTournament))
			dispatch(setPlayersList([]))
			dispatch(setChosenPlayerInList({}))
			dispatch(setCurrentStageNumber(targetStageNumber))
			dispatch(setProcessedStage({}))

			if (response.stages_count === 0) {
				dispatch(setInitialStage({}))
				dispatch(setStageCondition({
					stageExists: false,
					stageStatus: CREATE
				}))
			}

			setRemoveStageConfirmation(false)
			setRequestIsActive(prev => { return { ...prev, stageRemove: false } })

			if (options?.modes?.tabsAreVisible) {
				navigate(`/${tournamentUid}/structure/${targetStageNumber}`)
			} else {
				const tournamentTab = location.pathname.split('/')[2]
				navigate(`/${tournamentUid}/${tournamentTab}/${targetStageNumber}`)
			}
		} catch (error) {
			console.log(`HandleRemoveStage stage catch error ${error}`)
			setRequestIsActive(prev => { return { ...prev, stageRemove: false } })
		}
	}, [tournamentUid, dispatch, stageNumber, location,
		stages_count, removeStageRequest, navigate, tournament, tournamentRefetch,
		options?.modes?.tabsAreVisible])

	return (
		<>
			<div className="remove-stage-button">
				<button
					className="remove-stage-button__delete-button"
					onClick={() => setRemoveStageConfirmation(true)}
				>
					{t('Remove stage')}
				</button>
			</div>

			{
				removeStageConfirmation &&
				<Popup title="Это нельзя будет отменить">
					<p className="remove-stage-button__confirmation">
						{t('Are you sure you want to remove stage?')}
					</p>

					<div className="remove-stage-button__confirmation-buttons">
						<RoundedButton
							title={t('Cancel')}
							onClick={() => setRemoveStageConfirmation(false)}
							border
							background="white"
						/>

						<RoundedButton
							title={t('Remove')}
							onClick={handleRemoveStage}
							loading={requestIsActive.stageRemove}
						/>
					</div>
				</Popup>
			}
		</>
	)
}

export default RemoveStageButton