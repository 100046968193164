import React, { memo, useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import './GroupNodes.scss'
import {
	GROUP_TYPE_OLYMPIC_PLUS,
	GROUP_TYPE_RR,
	GROUP_TYPE_NOT_PLAYING,
	// GROUP_TYPE_OLYMPIC,
	// GROUP_TYPE_OLYMPIC_THREE,
	// GROUP_TYPE_DROP_OUT
} from '../../../utils/constants'
import { ReactComponent as ArrowDownIcon } from '../../../icons/arrow_down.svg'
import { ReactComponent as RemoveIcon } from '../../../icons/remove.svg'
import { ReactComponent as BucketIcon } from '../../../icons/bucket.svg'
import { ReactComponent as PlusWhiteIcon } from '../../../icons/plus_white.svg'
import { setReturnLevelsCondition, setStructureIsChanged, setTotalQuantityNodesInGroups, updateGroup } from '../../../redux/stageService/stageSlice'
import { removeGroup } from '../../../redux/stageService/stageSlice'
import RadioButton from '../../../reusableComponents/RadioButton/RadioButton'
import Button from '../../../reusableComponents/Button/Button'
import Popup from '../../../reusableComponents/Popup/Popup'

import { selectReturnLevelsCondition, selectProcessedLevelsForBuildMode, selectTournamentOptions, selectTotalQuantityNodesInGroups } from '../../../redux/selectors'
import StageGroupIcon from '../../../reusableComponents/StageGroupIcon/StageGroupIcon'

const iconColor = { fill: 'var(--palette-live-grey-7)' }

const groupTypeArray = [
	{
		type: GROUP_TYPE_RR,
		name: 'Круговая',
		icon: <StageGroupIcon type={GROUP_TYPE_RR} styleProp={iconColor} />
	},
	{
		type: GROUP_TYPE_OLYMPIC_PLUS,
		name: 'Олимпийская+',
		icon: <StageGroupIcon type={GROUP_TYPE_OLYMPIC_PLUS} styleProp={iconColor} />
	},
	// {
	// 	type: GROUP_TYPE_OLYMPIC,
	// 	name: 'Олимпийская',
	// 	icon: <StageGroupIcon type={GROUP_TYPE_OLYMPIC} styleProp={iconColor}/>
	// },
	// {
	// 	type: GROUP_TYPE_OLYMPIC_THREE,
	// 	name: 'Олимпийская3',
	// 	icon: <StageGroupIcon type={GROUP_TYPE_OLYMPIC_THREE} styleProp={iconColor}/>
	// },
	{
		type: GROUP_TYPE_NOT_PLAYING,
		name: 'Проходная',
		icon: <StageGroupIcon type={GROUP_TYPE_NOT_PLAYING} styleProp={iconColor} />
	},
	// {
	// 	type: GROUP_TYPE_DROP_OUT,
	// 	name: 'Вылет',
	// 	icon: <StageGroupIcon type={GROUP_TYPE_DROP_OUT} styleProp={iconColor}/>
	// }
]

function getGroupType(types, typeName) {
	return types.find(item => item.type === typeName)
}

function GroupNodes({ levelIndex, groupIndex, group, nodesQuantity }) {
	const dispatch = useDispatch()
	const levelsForBuildMode = useSelector(selectProcessedLevelsForBuildMode)
	const conditionIsReturned = useSelector(selectReturnLevelsCondition)
	const { settings } = useSelector(selectTournamentOptions)
	const totalQuantityNodesInGroups = useSelector(selectTotalQuantityNodesInGroups)

	const [count, setCount] = useState(group?.participants_max)
	const [typeGroup, setTypeGroup] = useState(getGroupType(groupTypeArray, group.type))
	const [changeTypeGroup, setChangeTypeGroup] = useState(typeGroup)
	const [openedGroupTypeEdition, setOpenedGroupTypeEdition] = useState(false)

	useEffect(() => {
		if (conditionIsReturned) {
			setCount(group?.participants_max)
			setTypeGroup(getGroupType(groupTypeArray, group.type))
			setChangeTypeGroup(getGroupType(groupTypeArray, group.type))
			dispatch(setReturnLevelsCondition(false))
		}
	}, [conditionIsReturned])
	// только если был вызов очищения

	function updateCurrentGroup(count, type) {
		const updatedGroup = {
			...group,
			participants_max: count,
			type
		}
		dispatch(updateGroup({
			levelIndex: levelIndex,
			groupIndex: groupIndex,
			group: updatedGroup
		}))
	}

	const handleMinus = useCallback((value) => {
		if (value === 1) {
			return
		} else {
			const newValue = value - 1
			setCount(newValue)

			// собираем все ноды по группам во вкладке билд
			dispatch(setTotalQuantityNodesInGroups(totalQuantityNodesInGroups - 1))

			updateCurrentGroup(newValue, typeGroup.type)
		}
	}, [typeGroup.type, totalQuantityNodesInGroups, dispatch])

	const handlePlus = useCallback((value) => {
		if (totalQuantityNodesInGroups === settings?.maxParticipants) return

		const newValue = value + 1
		setCount(newValue)

		// собираем все ноды по группам во вкладке билд
		dispatch(setTotalQuantityNodesInGroups(totalQuantityNodesInGroups + 1))

		updateCurrentGroup(newValue, typeGroup.type)
	}, [typeGroup.type, totalQuantityNodesInGroups, dispatch])

	const handleRemoveGroup = useCallback(() => {
		// добавляем анимацию удаления
		document.getElementById(`stage-build-group-${group.uid}`).classList.add('remove')

		setTimeout(() => {
			dispatch(removeGroup({
				levels: levelsForBuildMode,
				levelIndex,
				groupIndex
			}))

			dispatch(setStructureIsChanged(true)) // оnмечаем что структура изменилась, чтобы вызвать анимацию структуры
		}, 500) // Время должно соответствовать продолжительности анимации в CSS
	}, [dispatch, levelIndex, groupIndex, levelsForBuildMode, group.uid])

	const handleGroupTypeChange = useCallback((e) => {
		const type = getGroupType(groupTypeArray, e.target.value)
		setChangeTypeGroup(type)
	}, [])

	const switchGroupType = useCallback((type) => {
		setTypeGroup(getGroupType(groupTypeArray, type))
		updateCurrentGroup(count, type)
	}, [count])

	return (
		<>
			<div className="change-group__wrapper">
				<button
					className="change-group-type__wrapper"
					onClick={() => setOpenedGroupTypeEdition(true)}
				>
					{typeGroup.icon}

					<p className="change-group-type__text">
						{typeGroup.name}
					</p>

					<ArrowDownIcon className="arrow-down__icon" />
				</button>

				<div className="counter-groupe__wrapper">
					<button
						className="counter-groupe__button"
						onClick={() => handleMinus(count)}
					>
						<RemoveIcon className="remove-icon" />
					</button>

					<p className="counter-groupe__text">
						{count}
					</p>

					<button
						className="counter-groupe__button"
						onClick={() => handlePlus(count)}
					>
						<PlusWhiteIcon className="plus-white__icon" />
					</button>
				</div>
			</div>

			<button
				className="delete-group__wrapper"
				onClick={() => handleRemoveGroup()}
			>
				<BucketIcon className="bucket__icon" />

				Удалить группу
			</button>

			{
				openedGroupTypeEdition &&
				<Popup title="Тип группы">
					{
						groupTypeArray?.map((item, index) => (
							<RadioButton
								borderTop={index === 0}
								borderBottom
								key={index}
								name={'group'}
								id={`group-${index}`}
								label={item.name}
								value={item.type}
								checked={item.type === changeTypeGroup.type}
								onChange={handleGroupTypeChange}
							/>
						))
					}

					<div className="group-players__popup-buttons">
						<Button
							title="Выбрать"
							onClick={() => {
								switchGroupType(changeTypeGroup.type)
								setOpenedGroupTypeEdition(false)
							}}
							marginRight
						/>

						<Button
							title="Отмена"
							onClick={() => setOpenedGroupTypeEdition(false)}
							background={'white'}
						/>
					</div>
				</Popup>
			}
		</>
	)
}

export default memo(GroupNodes)