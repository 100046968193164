export function calculateNumberOfGroups(numberOfParticipants) {
	if (!numberOfParticipants) {
		return 0
	} else if (numberOfParticipants < 6) {
		return 1
	} else if (numberOfParticipants > 5 && numberOfParticipants < 9) {
		return 2
	} else if (numberOfParticipants > 8 && numberOfParticipants < 12) {
		return 3
	} else if ((numberOfParticipants > 11 && numberOfParticipants < 15) || numberOfParticipants === 16) {
		return 4
	} else if (numberOfParticipants === 15 || numberOfParticipants === 17 || numberOfParticipants === 20) {
		return 5
	} else if (numberOfParticipants === 18 || numberOfParticipants === 19) {
		return 6
	} else {
		return 7
	}
}

export function calculateNumberOfPlayers(numberOfParticipants) {
	if (!numberOfParticipants) {
		return 0
	} else if (numberOfParticipants < 8) {
		return numberOfParticipants
	} else if (numberOfParticipants === 8) {
		return 4
	} else if (numberOfParticipants === 9) {
		return 6
	} else if ((numberOfParticipants > 9 && numberOfParticipants < 17)) {
		return 8
	} else if (numberOfParticipants > 16 && numberOfParticipants < 20) {
		return 12
	} else if (numberOfParticipants > 20 && numberOfParticipants < 33) {
		return 16
	} else if (numberOfParticipants > 32 && numberOfParticipants < 41) {
		return 24
	} else {
		return 32
	}
}