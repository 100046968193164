import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import './Tournaments.scss'
import { useGetCommunityQuery } from '../../redux/communityService/communityApiSlice'
import Header from '../../reusableComponents/Header/Header'
import TournamentsList from '../../extendedComponents/TournamentsList/TournamentsList'
import AddButton from '../../reusableComponents/AddButton/AddButton'
import { ReactComponent as RocketsIcon } from '../../icons/rokets.svg'
import BottomMenu from '../../reusableComponents/BottomMenu/BottomMenu'
import Plug from '../../reusableComponents/Plug/Plug'
import Loader from '../../reusableComponents/Loader/Loader'

import { selectCommunity } from '../../redux/selectors'
import {
	setCurrentStageNumber,
	setPlayersList,
	setProcessedStage,
	setShowPrestagerating,
	setStageCondition
} from '../../redux/stageService/stageSlice'
import { setTournamentData } from '../../redux/singleTournamentService/singleTournamentSlice'
import { CREATE } from '../../utils/constants'
import { setCommunity } from '../../redux/communityService/communitySlice'

function Tournaments() {
	sessionStorage.clear()
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { communityUid, communityName } = useSelector(selectCommunity)
	const [tournaments, setTournaments] = useState([])

	const { data = {}, status, error } = useGetCommunityQuery(
		{ community_uid: communityUid }, { skip: !communityUid, refetchOnMountOrArgChange: true }
	)

	useEffect(() => {
		dispatch(setCurrentStageNumber(1))
		dispatch(setTournamentData({}))
		dispatch(setProcessedStage({}))
		dispatch(setStageCondition({
			stageExists: false,
			stageStatus: CREATE,
		}))
		dispatch(setPlayersList([]))
		dispatch(setShowPrestagerating(null))
	}, [])

	useEffect(() => {
		if (Object.keys(data).length !== 0) {
			setTournaments(data?.tournaments)
			dispatch(setCommunity(data))
		}
	}, [data, dispatch])

	if (error) { console.log(error) }

	return (
		<>
			<Header
				title={t('Tournaments')}
				communityName={communityName}
				border
			/>

			<main className="tournaments">
				{
					status !== 'fulfilled' ?
						<Loader />
						:
						<>
							{
								tournaments?.length < 1 ?
									<Plug
										icon={<RocketsIcon className="icon" />}
										title={t('Create tournament')}
										description={t('Click on the plus for creating a tournament')}
									/>
									:
									<TournamentsList
										list={tournaments}
										categories={data.categories}
										venues={data.venues}
										managers={data.managers}
									/>
							}

							<AddButton
								to={'/form/'}
							/>
						</>
				}
			</main>

			<BottomMenu />
		</>
	)
}

export default Tournaments