import { TYPE_SINGLES } from '../../utils/constants'

function getTiebreakPoints(targetTiebreakScore, neighborTiebreakScore, setIndex, activeSetIndex, tiebreak, options) {
	const initialScore = options?.match?.initialScore
	const isActiveSet = Number(setIndex) === Number(activeSetIndex)
	const tiebreaksAreNull = targetTiebreakScore === null && neighborTiebreakScore === null
	const tiebreaksAreZero = targetTiebreakScore === 0 && neighborTiebreakScore === 0

	if (tiebreak && tiebreaksAreNull && isActiveSet) {
		return initialScore
	} else if (!tiebreak && tiebreaksAreNull) {
		return ''
	} else if (tiebreaksAreZero && !isActiveSet) {
		return ''
	} else if (neighborTiebreakScore !== null && neighborTiebreakScore >= 0 && targetTiebreakScore === null) {
		return initialScore
	} else {
		return targetTiebreakScore
	}
}

function getNameForPlaceholder(participant, type) {
	if (type === TYPE_SINGLES) {
		return participant.namePlayer
	} else {
		return `${participant.namePlayer1} / ${participant.namePlayer2}`
	}
}

export { getTiebreakPoints, getNameForPlaceholder }