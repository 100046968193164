import { createSelector } from '@reduxjs/toolkit'

export const selectCommunity = state => state.community
export const selectCommunities = state => state.communities

export const selectTournament = state => state.tournament.tournament
export const selectTournamentOptions = state => state.tournament.options

export const selectProcessedStage = state => state.stage.processedStage
export const selectPlayersList = state => state.stage.playersList
export const selectChosenPlayerInList = state => state.stage.chosenPlayerInList
export const selectDragState = state => state.stage.dragState
export const selectCurrentStageNumber = state => state.stage.currentStageNumber
export const selectInitialStage = state => state.stage.initialStage
export const selectStageCondition = state => state.stage.stageCondition
export const selectImmutableProcessedLevels = state => state.stage.immutableProcessedLevels
export const selectProcessedLevelsForBuildMode = state => state.stage.processedLevelsForBuildMode
export const selectShowPrestageRating= state => state.stage.showPrestageRating
export const selectReturnLevelsCondition = state => state.stage.cleanGroupNodesCount
export const selectStructureIsChanged = state => state.stage.structureIsChanged
export const selectTotalQuantityNodesInGroups = state => state.stage.totalQuantityNodesInGroups

export const selectParticipants = state => state.participants

export const selectError = state => state.error.error

export const selectAuth = state => state.auth

export const selectMatch = state => state.match

export const selectRoundRobin = state => state.roundRobin


export const selectData = createSelector(
	[
		selectTournament, selectProcessedStage, selectParticipants,
		selectPlayersList, selectChosenPlayerInList, selectDragState,
		selectError, selectAuth, selectMatch, selectCurrentStageNumber,
		selectCommunity, selectCommunities, selectTournamentOptions, selectRoundRobin,
		selectStageCondition, selectImmutableProcessedLevels, selectProcessedLevelsForBuildMode,
		selectInitialStage, selectReturnLevelsCondition, selectShowPrestageRating,
		selectStructureIsChanged, selectTotalQuantityNodesInGroups
	],
	(data) => {
		return data
	}
)