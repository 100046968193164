import { memo, useState } from 'react'
import './DoubleButton.scss'

import { ReactComponent as BackIcon } from '../../icons/back.svg'
import { ReactComponent as RollingIcon } from '../../icons/rolling.svg'

function DoubleButton({
	titleSmall,
	titleBig,
	onClickSmall,
	onClickBig,
	typeSmall,
	typeBig,
	arrow,
	fixed,
	loading
}) {
	const [isClicked, setIsClicked] = useState(false)

	const handleRightButtonClick = (e) => {
		onClickBig && onClickBig(e)

		setIsClicked(true)
		setTimeout(() => setIsClicked(false), 600)
	}

	const loader =
		<div className="loader">
			<RollingIcon className="loader-icon" />
		</div>

	return (
		<div className={`double-button ${fixed ? 'fixed' : ''}`}>
			<button
				className="double-button--left"
				onClick={onClickSmall}
				type={typeSmall}
			>
				{!arrow ?
					titleSmall
					:
					<BackIcon className="back-icon" />
				}
			</button>

			<button
				className={`double-button--right ${isClicked && !loading ? 'clicked' : ''}`}
				onClick={handleRightButtonClick}
				type={typeBig}
			>
				{loading ? loader : titleBig}
			</button>
		</div>
	)
}

export default memo(DoubleButton)