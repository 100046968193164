import { apiSlice } from '../apiSlice'

export const stageApi = apiSlice.injectEndpoints({
	reducerPath: 'stageApi',
	tagTypes: ['Stage'],
	endpoints: (build) => ({
		getStage: build.query({
			query: ({ tournament_uid, stageNumber }) => ({
				url: `tennis/tournaments/${tournament_uid}/stages/${stageNumber}/`,
				method: 'GET'
			}),
			providesTags: ['Stage']
		}),
		createStage: build.mutation({
			query: ({ tournament_uid }) => ({
				url: `tennis/tournaments/${tournament_uid}/stages/`,
				method: 'POST'
			}),
			invalidatesTags: ['Tournament', 'Stage', 'Results']
		}),
		generateStage: build.mutation({
			query: ({ tournament_uid, stageNumber, body }) => ({
				url: `tennis/tournaments/${tournament_uid}/stages/${stageNumber}/generate/`,
				method: 'POST',
				body
			}),
			invalidatesTags: ['Tournament', 'Stage', 'Results']
		}),
		removeStage: build.mutation({
			query: ({ tournament_uid }) => ({
				url: `tennis/tournaments/${tournament_uid}/stages/`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Results']
		}),
		fillStage: build.mutation({
			query: ({ tournament_uid, stageNumber, body }) => ({
				url: `tennis/tournaments/${tournament_uid}/stages/${stageNumber}/fill/`,
				method: 'PATCH',
				body
			}),
			invalidatesTags: ['Stage']
		}),
		fillAutoStage: build.mutation({
			query: ({ tournament_uid, stageNumber }) => ({
				url: `tennis/tournaments/${tournament_uid}/stages/${stageNumber}/fill/`,
				method: 'POST',
			}),
			invalidatesTags: ['Stage']
		}),
		savePrestageRating: build.mutation({
			query: ({ tournament_uid, stageNumber, body }) => ({
				url: `tennis/tournaments/${tournament_uid}/stages/${stageNumber}/initial/`,
				method: 'PUT',
				body
			}),
			invalidatesTags: ['Stage']
		}),
		updateStage: build.mutation({
			query: ({ body, tournament_uid, stageNumber }) => ({
				url: `tennis/tournaments/${tournament_uid}/stages/${stageNumber}/`,
				method: 'PUT',
				body
			}),
			invalidatesTags: ['Stage']
		}),
		clearStage: build.mutation({
			query: ({ tournament_uid, stageNumber }) => ({
				url: `tennis/tournaments/${tournament_uid}/stages/${stageNumber}/fill/`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Stage']
		}),
		recoverInitialRaiting: build.mutation({
			query: ({ tournament_uid, stageNumber }) => ({
				url: `tennis/tournaments/${tournament_uid}/stages/${stageNumber}/initial/`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Stage']
		}),
	})
})

export const {
	useGetStageQuery,
	useCreateStageMutation,
	useGenerateStageMutation,
	useRemoveStageMutation,
	useFillStageMutation,
	useFillAutoStageMutation,
	useSavePrestageRatingMutation,
	useUpdateStageMutation,
	useClearStageMutation,
	useRecoverInitialRaitingMutation
} = stageApi