import { NEW_SET_BASIC, NEW_SET_ADVANCE } from '../../utils/constants'
import { getActiveSet } from '../../utils/functions2'

function getInitialSlide(match, side) {
	if (!match || !match?.sets || Object.keys(match).length < 1) return 0

	const matchObj = {...match}

	const sets = [...matchObj?.sets]
	const sliderMaxIndex = 10

	if (sets.length > 0) {
		const lastIndex = sets.length - 1
		const activeSet = getActiveSet(sets, lastIndex)
		const { score1, score2 } = activeSet

		if (side === 1) {
			return score1 > sliderMaxIndex ? sliderMaxIndex : score1
		} else {
			return score2 > sliderMaxIndex ? sliderMaxIndex : score2
		}
	} else {
		return 0
	}
}

const addFirstSet = (match, options) => {
	if (match && Object.keys(match).length !== 0) {
		try {
			const currentMatch = {...match}
			if (currentMatch.sets.length < 1) {
				const newSet = !options?.match?.advanceLevel ? NEW_SET_BASIC : NEW_SET_ADVANCE
				currentMatch.sets = [{ ...newSet }]
			}

			return currentMatch
		} catch (e) {
			console.log(e)
		}
	} else {
		return
	}
}

function getActiveSetIndex(matchSession, tier) {
	const matchState = addFirstSet(matchSession, tier)
	if (!matchState) return 0

	const { sets } = matchState
	const lastIndex = sets.length > 0 ? sets.length - 1 : 0

	return lastIndex
}

function getWayBack(location) {
	if (location) {
		const wayBack = location.pathname.replace('match', '')
		return wayBack
	} else {
		return '/tournaments'
	}
}

function invertSets(sets, inverted) {
	if (inverted) {
		const invertedSets = sets.map((set) => {
			const { score2 , score1, tie_break_score1, tie_break_score2 } = set

			const invertedSet = {
				...set,
				score1: score2,
				score2: score1,
				tie_break_score1: tie_break_score2,
				tie_break_score2: tie_break_score1
			}

			return invertedSet
		})

		return invertedSets
	} else {
		return sets
	}
}

function getSlideNumber(currentSlide, lastSwiperIndex, tiebreak, options) {
	if (currentSlide > lastSwiperIndex) {
		return 10
	} else if (!currentSlide && tiebreak) {
		return options?.match?.initialScore
	} else {
		return currentSlide
	}
}

function getProcessedTiebreak(sets, activeSetIndex, tiebreak, options) {

	return sets.map((set, index) => {
		const updatedSet = {...set}

		const { tie_break_score1, tie_break_score2 } = updatedSet
		const initialScore = options?.match?.initialScore || 0
		const isNumber1 = typeof tie_break_score1 === 'number'
		const isNumber2 = typeof tie_break_score2 === 'number'

		if (!isNumber1 && isNumber2 && tie_break_score2 >= 0) {
			updatedSet.tie_break_score1 = initialScore

		} else if (!isNumber2 && isNumber1 && tie_break_score1 >= 0) {
			updatedSet.tie_break_score2 = initialScore

		} else if (isNumber1 && tie_break_score1 === 0 && isNumber2 && tie_break_score2 === 0) {
			updatedSet.tie_break_score1 = null
			updatedSet.tie_break_score2 = null

		} else if (!isNumber1 && !isNumber2
			&& initialScore > 0 && index === activeSetIndex && tiebreak) {

			updatedSet.tie_break_score1 = initialScore
			updatedSet.tie_break_score2 = initialScore
		}

		return updatedSet
	})
}

export {
	getInitialSlide,
	addFirstSet,
	getActiveSetIndex,
	getWayBack,
	invertSets,
	getSlideNumber,
	getProcessedTiebreak
}