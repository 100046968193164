import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import './Account.scss'
import Header from '../../reusableComponents/Header/Header'
import ArrowLinkBlock from '../../reusableComponents/ArrowLinkBlock/ArrowLinkBlock'
import BottomMenu from '../../reusableComponents/BottomMenu/BottomMenu'
import ProfileBlock from '../../reusableComponents/ProfileBlock/ProfileBlock'
import { useGetUserInfoQuery } from '../../redux/userService/userApiSlice'
import StringButton from '../../reusableComponents/StringButton/StringButton'
import { apiSlice } from '../../redux/apiSlice'
import { selectCommunity } from '../../redux/selectors'
import { resetAuthorization } from '../../utils/functions'

function Account() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const { communityUid } = useSelector(selectCommunity)
	const navigate = useNavigate()
	const { data = {}, isLoading } = useGetUserInfoQuery()

	function handleSignOut() {
		resetAuthorization(dispatch, communityUid, 'logout')
		dispatch(apiSlice.util.resetApiState())

		navigate('/auth')
	}

	return (
		<>
			<Header
				title={t('Account')}
			/>

			<main className="account">
				<ProfileBlock
					lastName={data?.last_name}
					firstName={data?.first_name}
					email={data?.email}
					loading={isLoading}
				/>

				<div className="account__link-wrapper">
					<ArrowLinkBlock
						to="/account/settings"
						borderTop
						borderBottom
					>
						{ t('Edit account') }
					</ArrowLinkBlock>

					<ArrowLinkBlock
						to="/account/password"
						borderBottom
					>
						{ t('Change password') }
					</ArrowLinkBlock>

					<ArrowLinkBlock
						to="/info"
						borderBottom
					>
						{ t('Information about website') }
					</ArrowLinkBlock>
				</div>

				<div className="account__logout-wrapper">
					<StringButton
						onClick={handleSignOut}
						title={t('Logout')}
					/>
				</div>
			</main>

			<BottomMenu />
		</>
	)
}

export default Account