import { NODE_TYPE_IN, NODE_TYPE_MIDDLE, RESULT_COLUMN_NAME } from '../../utils/constants'

export const formGrid = (group) => {
	const columns = getColumnsArray(group.nodes)
	const columnsWithMatches = buildMatchesInColumns(columns, group.matches)
	const grid = getMatchesWithDefinedWinners(columnsWithMatches)

	return grid
}

function getColumnsArray(nodes) {
	const outgoingNodes = []
	const incomingNodes = []
	const middleNodes = []

	nodes.forEach(item => {
		if (item.type === NODE_TYPE_IN) {
			incomingNodes.push(item)
		} else if (item.type === NODE_TYPE_MIDDLE) {
			middleNodes.push(item)
		} else {
			outgoingNodes.push(item)
		}
	})

	const fullResultColumn = getFullResultColumn(outgoingNodes, incomingNodes.length)

	const numberOfFirstRound = incomingNodes.length / 2
	const arrayOfMiddleColumns = middleNodes.length > 0 ? getMiddleColumns(middleNodes, numberOfFirstRound) : []

	return [
		{ roundName: numberOfFirstRound, matches: incomingNodes },
		...arrayOfMiddleColumns,
		{ roundName: RESULT_COLUMN_NAME, matches: fullResultColumn }
	]
}

function getFullResultColumn(nodes, maxNumber) {
	const fullResultArray = Array.from({ length: maxNumber }, (_) => { return {} })

	const fullNodesArray = fullResultArray.map((item, index) => {
		if (nodes[index]) {
			item = nodes[index]
		}
		return item
	})

	return fullNodesArray
}

function getMiddleColumns(middleNodes, numberOfFirstRound) {
	const objectOfMiddleColumns = {}

	middleNodes?.forEach(node => {
		const roundNumber = numberOfFirstRound / (2 ** node.round_number / 2)

		if (!objectOfMiddleColumns[roundNumber]) {
			objectOfMiddleColumns[roundNumber] = []
		}
		objectOfMiddleColumns[roundNumber].push(node)
	})

	return Object.entries(objectOfMiddleColumns)
		.map(([key, value]) => ({ roundName: Number(key), matches: value }))
		.sort((a, b) => b.roundName - a.roundName)
}

function buildMatchesInColumns(columns, matches) {
	return columns.map(column => {
		const { roundName, matches: nodes } = column

		if (roundName !== RESULT_COLUMN_NAME) {
			const arrayOfPairs = nodes.reduce((pairs, playerObj, index) => {
				const match = getMatch(playerObj, matches)

				const pair = {
					match: match ? true : false,
					matchNumber: match?.order_number || null,
					matchData: match || null
				}

				if (nodes.length < 2) {
					pairs.push({
						...pair,
						nodeOne: playerObj
					})
				} else {
					if (index % 2 === 1) {
						pairs.push({
							...pair,
							nodeOne: nodes[index - 1],
							nodeTwo: playerObj
						})
					}
				}
				return pairs
			}, [])

			return { ...column, matches: arrayOfPairs }
		}
		return { ...column, matches: nodes }
	})
}

function getMatch(node, matches) {
	return matches.find(match => node.uid === match.side1_uid || node.uid === match.side2_uid)
}

function getMatchesWithDefinedWinners(columns) {
	return columns.map((column, index) => {
		const count = column.roundName
		const matches = [...column.matches]

		if (index > 0 && index + 1 < columns.length) {
			const updatedMatches = markWinnerMatches(matches, count)
			return { ...column, matches: updatedMatches }
		}

		return column
	})
}

function markWinnerMatches(array, n) {
	const loopArray = Array.from({ length: array.length / n })

	return loopArray.reduce((arr, _, index) => {
		let cutArray = array?.splice(0, n)
		if (index % 2 === 0) {
			cutArray = cutArray.map(item => { return { ...item, winner: true } })
		}

		return arr.concat(cutArray)
	}, [])
}