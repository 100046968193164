import { Link } from 'react-router-dom'

import './CheckBox.scss'

function CheckBox({ error, touched, name, onChange, checked }) {
	return (
		<div className="checkbox">
			<div className="checkbox__wrapper">
				<div className="checkbox-input__wrapper">
					<input
						type="checkbox"
						className="checkbox-input"
						id={name}
						name={name}
						onChange={onChange}
						checked={checked}
					/>

					<label htmlFor={name}></label>
				</div>

				<label htmlFor={name} className="checkbox-label">
					Даю согласие на <Link to="">обработку персональных данных</Link>
				</label>
			</div>

			{
				error && touched &&
					<p className="checkbox-error">
						{ error }
					</p>
			}
		</div>
	)
}

export default CheckBox