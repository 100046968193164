import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Field, Formik } from 'formik'
import { useSelector } from 'react-redux'

import './VenuesForm.scss'
import {
	useAddVenueMutation,
	useGetVenueQuery,
	useRemoveVenueMutation,
	useUpdateVenueMutation
} from '../../redux/venuesService/venuesApiSlice'
import Header from '../../reusableComponents/Header/Header'
import Input from '../../reusableComponents/Input/Input'
import Button from '../../reusableComponents/Button/Button'
import BottomMenu from '../../reusableComponents/BottomMenu/BottomMenu'
import RoundedBlock from '../../reusableComponents/RoundedBlock/RoundedBlock'
import RadioButton from '../../reusableComponents/RadioButton/RadioButton'
import StringButton from '../../reusableComponents/StringButton/StringButton'
import Loader from '../../reusableComponents/Loader/Loader'

import { selectCommunity } from '../../redux/selectors'

function VenusForm() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { communityUid } = useSelector(selectCommunity)
	const { uid } = useParams()
	const [addVenue] = useAddVenueMutation()
	const [updateVenue] = useUpdateVenueMutation()
	const [removeVenue] = useRemoveVenueMutation()
	const { data = {}, isLoading } = useGetVenueQuery(uid, { skip: !uid })

	function validateName(valueName, setFieldError) {
		const name = valueName.trim()

		if (name.length < 1) {
			setFieldError('name', t('Enter name'))
			return false
		}

		return true
	}

	async function sendDataForm(values, setFieldError) {
		const { name, address, surface, indoorType } = values

		if(!validateName(name, setFieldError)) {
			return
		}

		try {
			const body = {
				'uid': uid || '',
				'name': name.trim(),
				'address_string': address.trim(),
				'indoor_type': indoorType || null,
				'surface': surface === 'other' ? null : surface,
				'community_uids': [communityUid]
			}

			let response

			if (uid) {
				response = await updateVenue({ body, venue_uid: uid }).unwrap()
			} else {
				response = await addVenue({ body }).unwrap()
			}

			if (response?.error) {
				return
			} else {
				navigate('/venues')
			}
		} catch (e) {
			console.log('Venues creation and updating catch error', e)
		}
	}

	async function deleteVenue() {
		try {
			const response = await removeVenue({ venue_uid: uid }).unwrap()

			if (response?.error) {
				return
			}
			navigate('/venues')
		}
		catch (e) {
			console.log('removeVenue catch error', e)
		}
	}

	return (
		<>
			<Header
				title={uid ? t('Edition') : t('Add venue')}
				back
				backUrl={uid ? `/venue/${uid}` : '/venues'}
				border
			/>

			<main className="venus-form">
				{
					isLoading ?
						<Loader />
						:
						<>
							<Formik
								initialValues={{
									name: data?.name || '',
									address: data?.address_string || '',
									surface: data?.surface || 'other',
									indoorType: data?.indoor_type || 'outdoor',
								}}
								onSubmit={async (values, { setFieldError }) => {
									await sendDataForm(values, setFieldError)
								}}
							>
								{({ values, errors, touched, handleSubmit, handleChange, setFieldError }) => (
									<form
										onSubmit={handleSubmit}
										noValidate
										className="venus-form__wrapper"
									>
										<Field
											value={values.name}
											onChange={(e) => {
												handleChange(e)
												setFieldError('name', '')
											}}
											onClick={() => setFieldError('name', '')}
											type="text"
											name="name"
											as={Input}
											placeholder={t('Name') + ' *'}
											error={errors.name}
											touched={touched.name}
										/>

										<Field
											value={values.address}
											onChange={handleChange}
											type="text"
											name="address"
											as={Input}
											placeholder={t('Address')}
											error={errors.address}
											touched={touched.address}
										/>

										<RoundedBlock background={true}>
											<Field
												id="radio-clay"
												type="radio"
												name="surface"
												value="clay"
												checked={values.surface === 'clay'}
												as={RadioButton}
												label={t('Clay')}
												onChange={handleChange}
												borderBottom={true}
											/>

											<Field
												id="radio-hard"
												type="radio"
												name="surface"
												value="hard"
												checked={values.surface === 'hard'}
												as={RadioButton}
												label={t('Hard')}
												onChange={handleChange}
												borderBottom={true}
											/>

											<Field
												id="radio-wood"
												type="radio"
												name="surface"
												value="wood"
												checked={values.surface === 'wood'}
												as={RadioButton}
												label={t('Wood')}
												onChange={handleChange}
												borderBottom={true}
											/>

											<Field
												id="radio-grass"
												type="radio"
												name="surface"
												value="grass"
												checked={values.surface === 'grass'}
												as={RadioButton}
												label={t('Grass')}
												onChange={handleChange}
												borderBottom={true}
											/>

											<Field
												id="radio-other"
												type="radio"
												name="surface"
												value="other"
												checked={values.surface === 'other'}
												as={RadioButton}
												label={t('Other')}
												onChange={handleChange}
											/>
										</RoundedBlock>

										<RoundedBlock className="venue-form__inputs-radio" background={true}>
											<Field
												id="venue__indoor"
												name="indoorType"
												type="radio"
												value="indoor"
												checked={values.indoorType === 'indoor'}
												as={RadioButton}
												label={t('Indoor')}
												onChange={handleChange}
												borderBottom={true}
											/>

											<Field
												id="venue__outdoor"
												name="indoorType"
												type="radio"
												value="outdoor"
												checked={values.indoorType === 'outdoor'}
												as={RadioButton}
												label={t('Outdoor')}
												onChange={handleChange}
											/>
										</RoundedBlock>

										<div className="venus-form__button">
											<Button
												type={'submit'}
												title={t('Save')}
											/>
										</div>
									</form>
								)}
							</Formik>

							{
								uid &&
									<div className="venus-form__delete-button">
										<StringButton
											onClick={deleteVenue}
											title={t('Delete venue')}
										/>
									</div>
							}
						</>
				}
			</main>

			<BottomMenu />
		</>
	)
}

export default VenusForm