import { memo, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ClipboardJS from 'clipboard'

import './HeaderTournament.scss'
import { ReactComponent as ChainIcon } from '../../icons/chain.svg'
import { getParameters } from '../../utils/functions.js'
import { selectAuth, selectTournament, selectTournamentOptions } from '../../redux/selectors.js'

function HeaderTournament({ date, category, copyLink, printComponent, background }) {
	const { t } = useTranslation()
	const { tournamentParams } = useParams()
	const { tournamentUid } = getParameters(tournamentParams)
	const { auth_code, name } = useSelector(selectTournament)
	const { authorized } = useSelector(selectAuth)
	const options = useSelector(selectTournamentOptions)
	const buttonRef = useRef(null)
	const guestUrl = `${window.location.origin}/${tournamentUid}-${auth_code}/draws/1`

	useEffect(() => {
		if (!guestUrl || !copyLink || !buttonRef.current) return

		const clipboard = new ClipboardJS(buttonRef.current, {
			text: () => guestUrl
		})

		clipboard.on('success', (e) => {
			alert(t('Link copied'))
			e.clearSelection()
		})

		clipboard.on('error', (e) => {
			alert(t('Error copying link'))
		})

		return () => clipboard.destroy()
	}, [guestUrl, t, copyLink])

	return (
		<div
			className="header-tournament"
			style={{
				backgroundColor: background ? 'var(--palette-live-grey-10)' : ''
			}}
		>
			<h1 className="header-tournament__title">
				{name || ''}
			</h1>

			<div className="header-tournament__wrapper">
				<div className="header-tournament__container">
					<p className="header-tournament__date">
						{date}
					</p>

					{
						category && (
							<p className="header-tournament__categories">
								{category}
							</p>
						)
					}
				</div>

				{authorized &&
					<div className="header-tournament__copy-wrapper">
						{options?.settings?.print && printComponent}

						{
							copyLink && (
								<button
									className="header-tournament__copy-button"
									ref={buttonRef}
								>
									<ChainIcon className="chain-icon" />
								</button>
							)
						}
					</div>
				}
			</div>
		</div>
	)
}

export default memo(HeaderTournament)
