import { memo } from 'react'
import { NavLink } from 'react-router-dom'

import './AddButton.scss'
import { ReactComponent as AddButtonIcon } from '../../icons/plus_white.svg'

function AddButton({ onClick, to }) {
	return (
		<NavLink
			onClick={onClick}
			to={to}
			className="add-button"
		>
			<AddButtonIcon className="add-button__icon" />
		</NavLink>
	)
}

export default memo(AddButton)