import './Match.scss'
import { useState, memo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation, Navigate } from 'react-router'
import SmoothCollapse from 'react-smooth-collapse'

import { useSaveSetMutation, useUpdateMatchMutation } from '../../redux/matchService/matchApiSlice'
import { NEW_SET_ADVANCE, NEW_SET_BASIC, TYPE_DOUBLES, TYPE_SINGLES } from '../../utils/constants'
import { selectMatch, selectTournament, selectTournamentOptions } from '../../redux/selectors'

import RoundedButton from '../../reusableComponents/RoundedButton/RoundedButton'
import DoubleButton from '../../reusableComponents/DoubleButton/DoubleButton'
import Popup from '../../reusableComponents/Popup/Popup'
import Header from '../../reusableComponents/Header/Header'
import Slider from '../../reusableComponents/Slider/Slider'
import SliderButtons from '../../reusableComponents/SliderButtons/SliderButtons'
import MatchSets from '../../extendedComponents/MatchSets/MatchSets'
import { setActiveSetIndex, setMatchData, setMatchSession, setTiebreak } from '../../redux/matchService/matchSlice'

import { getActiveSet } from '../../utils/functions2'

import {
	getInitialSlide,
	getProcessedTiebreak, getSlideNumber, getWayBack, invertSets
} from './externalFunctions'

function Match() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const location = useLocation()

	const { type, name } = useSelector(selectTournament)
	const { tiebreak, activeSetIndex, match } = useSelector(selectMatch)
	const { stage: options = {} } = useSelector(selectTournamentOptions)

	const [updateMatch] = useUpdateMatchMutation()
	const [saveSet] = useSaveSetMutation()

	const slidesAmount = 10

	const [loaded1, setLoaded1] = useState(false)
	const [loaded2, setLoaded2] = useState(false)
	const [indexOfSlider1, setIndexOfSlider1] = useState(getInitialSlide(match, 1))
	const [indexOfSlider2, setIndexOfSlider2] = useState(getInitialSlide(match, 2))
	const [touchedSlider, setTouchedSlider] = useState({ left: false, right: false })
	const [sliderIsMoving, setSliderIsMoving] = useState(false)
	const [slider1, setSlider1] = useState(null)
	const [slider2, setSlider2] = useState(null)
	const [openModal, setOpenModal] = useState(false)
	const [requestIsActive, setRequestIsActive] = useState({
		finish: false
	})

	const options1 = {
		animationEnded(slider) {
			setIndexOfSlider1(slider.track.details.rel)
			setSliderIsMoving(false)
		},
		animationStarted() {
			setSliderIsMoving(true)
		},
		dragStarted() {
			setTouchedSlider(prev => { return { ...prev, left: true } })
		},
		initial: getInitialSlide(match, 1)
	}

	const options2 = {
		animationEnded(slider) {
			setIndexOfSlider2(slider.track.details.rel)
			setSliderIsMoving(false)
		},
		animationStarted() {
			setSliderIsMoving(true)
		},
		dragStarted() {
			setTouchedSlider(prev => { return { ...prev, right: true } })
		},
		initial: getInitialSlide(match, 2)
	}

	useEffect(() => {
		dispatch(setTiebreak(false))

		if (match) {
			if (match?.sets?.length > 0) {
				dispatch(setActiveSetIndex(match?.sets?.length - 1))
			} else {
				dispatch(setActiveSetIndex(0))
			}
		}
	}, [])

	useEffect(() => {
		if (!match || !touchedSlider.left) return

		updateScore(1)
	}, [indexOfSlider1])

	useEffect(() => {
		if (!match || !touchedSlider.right) return

		updateScore(2)
	}, [indexOfSlider2])

	function updateScore(side) {
		const leftSlider = 1
		const updatedMatch = { ...match }
		let sets = [...updatedMatch.sets]

		if (sets.length < 1) {
			sets = !options?.match?.advanceLevel ? [NEW_SET_BASIC]: [NEW_SET_ADVANCE]
		}

		const activeSet = getActiveSet(sets, activeSetIndex)

		if (!tiebreak) {
			if (side === leftSlider) {
				activeSet.score1 = indexOfSlider1
			} else {
				activeSet.score2 = indexOfSlider2
			}
		} else {
			if (side === leftSlider) {
				activeSet.tie_break_score1 = indexOfSlider1
			} else {
				activeSet.tie_break_score2 = indexOfSlider2
			}
		}

		const serializeSet = [...sets]

		serializeSet[activeSetIndex] = activeSet
		updatedMatch.sets = serializeSet
		dispatch(setMatchData(updatedMatch))

		const key = side === leftSlider ? 'left' : 'right'
		setTouchedSlider(prev => { return { ...prev, [key]: false } })
	}

	const changeSlidesInSwiper = useCallback((sets, index, tiebreak) => {
		if (sets.length > 0) {
			const lastSwiperIndex = slidesAmount
			const activeSet = getActiveSet(sets, index)
			const { score1, score2, tie_break_score1, tie_break_score2 } = activeSet

			let leftCurrentSlide = tiebreak ? tie_break_score1 : score1
			let rightCurrentSlide = tiebreak ? tie_break_score2 : score2

			leftCurrentSlide = getSlideNumber(leftCurrentSlide, lastSwiperIndex, tiebreak, options)
			rightCurrentSlide = getSlideNumber(rightCurrentSlide, lastSwiperIndex, tiebreak, options)

			if (!leftCurrentSlide && !rightCurrentSlide) {
				callSlidersMove(0, 0)
			} else {
				callSlidersMove(leftCurrentSlide, rightCurrentSlide)
			}
		} else {
			callSlidersMove(0, 0)
		}
	}, [slidesAmount, slider1, slider2, options])

	function callSlidersMove(score1, score2) {
		setTimeout(() => {
			slider1.current.moveToIdx(score1)
			slider2.current.moveToIdx(score2)
		}, 100)
	}

	const activateTiebreak = useCallback(() => {
		const updatedTiebreak = !tiebreak
		dispatch(setTiebreak(updatedTiebreak))
		changeSlidesInSwiper(match.sets, activeSetIndex, updatedTiebreak)
	}, [changeSlidesInSwiper, match, activeSetIndex, tiebreak, dispatch])

	const handleSaveSet = async () => {
		if (!match || !match.sets || !match.uid) return

		if (sliderIsMoving) {
			setOpenModal(true)
			return
		}

		const sets = match.sets

		const invertedSets = invertSets(sets, match.inverted)
		const processedSets = getProcessedTiebreak(invertedSets, activeSetIndex, tiebreak, options)

		const body = {
			'is_finished': false,
			sets: processedSets
		}

		try {
			const response = await saveSet({ uid: match?.uid, body })

			if (!response?.error) {
				const sets = invertSets(response.data.sets, match.inverted)
				dispatch(setMatchData({ ...match, sets }))
				dispatch(setMatchSession({ ...match, sets }))
			}
		}
		catch (e) {
			console.log('handleSaveSet catched error', e)
		}
	}

	const handleFinishMatch = async () => {
		if (!match || !match.sets || !match.uid) return

		if (sliderIsMoving) {
			setOpenModal(true)
			return
		}

		setRequestIsActive(prev => {
			return {
				...prev,
				finish: true
			}
		})

		let sets = await invertSets(match.sets, match.inverted)
		sets = await getProcessedTiebreak(sets, activeSetIndex, tiebreak, options)
		const body = {
			'is_finished': match.sets.length > 0 ? true : false,
			'sets': sets
		}

		try {
			await updateMatch({ uid: match.uid, body })

			dispatch(setTiebreak(false))

			setRequestIsActive(prev => {
				return {
					...prev,
					finish: false
				}
			})

			const previousePath = location?.pathname.replace('/match', '')
			navigate(previousePath)
		} catch (e) {
			setRequestIsActive(prev => {
				return {
					...prev,
					finish: false
				}
			})
			console.log('updateMatch catched error', e)
		}
	}

	function exit() {
		dispatch(setTiebreak(false))
		navigate(match.pathBack)
	}

	const getTaibreakStyle = useCallback(() => {
		if (!tiebreak) {
			return {
				backgroundColor: 'var(--palette-live-bg-0-white)',
				border: '1px solid var(--palette-live-blue)',
				color: 'var(--palette-live-blue)'
			}
		} else {
			return {
				backgroundColor: 'var(--palette-live-blue)',
				border: '1px solid var(--palette-live-blue)',
				color: 'var(--palette-live-bg-0-white)'
			}
		}
	}, [tiebreak])

	const showHeader = () => {
		if ((type === TYPE_DOUBLES && match?.sets && match.sets.length > 2)
			|| (type === TYPE_SINGLES && match?.sets && match.sets.length > 3)) {
			return false
		} else {
			return true
		}
	}

	if (!match || Object.keys(match).length === 0) {
		return <Navigate to={getWayBack(location)} />
	}

	return (
		<>
			<SmoothCollapse expanded={showHeader()}>
				<Header communityName={name} background />
			</SmoothCollapse>

			<div className="match__wrapper">
				<div className="match__container">

					<div className="match__container-top">
						<div className="match__participants">
							<div className="match__set-participant--first">
								{
									type === TYPE_SINGLES ?
										match?.side1.namePlayer
										:
										<div className="match__set-participant-double">
											<p>
												{match?.side1.namePlayer1}
											</p>
											<p>
												{match?.side1.namePlayer2}
											</p>
										</div>

								}
							</div>

							<div className="match__set-participant--second">
								{
									type === TYPE_SINGLES ?
										match?.side2.namePlayer
										:
										<div className="match__set-participant-double">
											<p>
												{match?.side2.namePlayer1}
											</p>
											<p>
												{match?.side2.namePlayer2}
											</p>
										</div>
								}
							</div>
						</div>

						<MatchSets
							changeSlidesInSwiper={changeSlidesInSwiper}
							match={match}
						/>

					</div>

					<div className="match__sliders" >
						<div className="sliders_container">

							{loaded1 && slider1.current && (
								<SliderButtons
									side={'left'}
									setTouchedSlider={setTouchedSlider}
									slider={slider1.current}
								/>
							)}

							<div className="slider">
								<Slider
									initIdx={1}
									length={slidesAmount}
									width={25}
									loop={true}
									options={options1}
									setSlider={setSlider1}
									setLoaded={setLoaded1}
								/>
							</div>
						</div>

						<div className="sliders__separator"></div>

						<div className="sliders_container">
							<div className="slider">
								<Slider
									initIdx={1}
									length={slidesAmount}
									width={25}
									loop={true}
									options={options2}
									setSlider={setSlider2}
									setLoaded={setLoaded2}
								/>
							</div>

							{loaded2 && slider2.current && (
								<SliderButtons
									side={'right'}
									setTouchedSlider={setTouchedSlider}
									slider={slider2.current}
								/>
							)}
						</div>
					</div>

					<div className="match__buttons">
						<button
							className="match__button-tiebreak"
							style={getTaibreakStyle()}
							onClick={activateTiebreak}
						>
							{t('Tiebreak')}
						</button>

						<DoubleButton
							typeSmall="button"
							onClickSmall={exit}
							arrow

							typeBig="button"
							onClickBig={handleSaveSet}
							titleBig={t('Save score')}
						/>

						<RoundedButton
							type="button"
							onClick={handleFinishMatch}
							title={t('Finish match')}
							background={'grey'}
							loading={requestIsActive.finish}
						/>
					</div>
				</div>
			</div>

			{openModal &&
				<Popup title={'Внимание'}>
					<div className="match__popup-warning">
						<p>
							Дождитесь окончания вращения свайпера
						</p>

						<RoundedButton title={'OK'} onClick={() => setOpenModal(false)} />
					</div>
				</Popup>
			}
		</>
	)
}

export default memo(Match)