import './Plug.scss'

function Plug({ icon, title, description }) {
	return (
		<div className="plug">
			<h2 className="plug-title">
				{ title }
			</h2>

			<p className="plug-description">
				{ description }
			</p>

			{ icon }
		</div>
	)
}

export default Plug