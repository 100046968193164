import { memo } from 'react'
import { useTranslation } from 'react-i18next'

import './Popup.scss'
import { ReactComponent as CloseIcon } from '../../icons/close.svg'
import { ReactComponent as ArrowBack } from '../../icons/arrow_back.svg'

function Popup({ title, onClose, children, close, leftTitle, arrowBack, onBack }) {
	const { t } = useTranslation()

	return (
		<div className="popup">
			<div className="popup__wrapper">
				{(title || onClose) &&
					<div
						className="popup__header"
						style={{ justifyContent: close || arrowBack ? 'space-between' : leftTitle ? 'flex-start' : 'center' }}
					>
						{
							arrowBack &&
							<ArrowBack onClick={onBack} className="popup__arrow-icon"/>
						}
						<h2 className="popup__title">
							{t(title)}
						</h2>

						{
							close && !arrowBack ?
								<CloseIcon
									onClick={onClose}
									className="popup__close-icon"
								/>
								:
								<div></div>
						}
					</div>
				}

				<div className="popup__body">
					{children}
				</div>
			</div>
		</div>
	)
}

export default memo(Popup)