import { useEffect, useRef, useState } from 'react'

import ArrowUpDown from '../ArrowUpDown/ArrowUpDown'
import './Select.scss'

function Select({ onChange, value, defaultValue, options, placeholder, name }) {
	const ref = useRef(null)

	const [activeOption, setActiveOption] = useState(value?.label || '')
	const [opened, setOpened] = useState(false)

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setOpened(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		document.addEventListener('touchstart', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
			document.removeEventListener('touchstart', handleClickOutside)
		}
	}, [ref])

	function openSelect() {
		setOpened(prev => !prev)
	}

	function chooseOption(option) {
		setOpened(false)
		setActiveOption(option.value ? option?.label : '')
		onChange(option)
	}

	function getStyle() {
		if (value && String(value?.value).length > 0) {
			return {
				top: '7px',
				fontSize: '14px',
			}
		}
	}

	return (
		<div className="select" ref={ref}>
			<label className="select-label" style={getStyle()}>{placeholder}</label>

			<input
				value={activeOption || defaultValue?.label || ''}
				type="text"
				readOnly
				name={name}
				className="select-input"
				onClick={openSelect}
			/>

			<ArrowUpDown onClick={openSelect} isUp={opened} light />

			<div className={`select-options ${opened ? 'open' : ''}`}>
				{
					options?.map(option => (
						<div
							key={option?.value}
							className={`select-options__item ${value?.value === option?.value ? 'active' : ''}`}
							onClick={() => chooseOption(option)}
						>
							{option?.value ? option.label : 'Не выбрано'}
						</div>
					))
				}
			</div>
		</div>
	)
}

export default Select