import { apiSlice } from '../apiSlice'

export const playersApi = apiSlice.injectEndpoints({
	reducerPath: 'playersApi',
	tagTypes: ['Players'],
	endpoints: (build) => ({
		getPlayers: build.query({
			query: ({ community_uid }) => `tennis/communities/${community_uid}/players/`,
			providesTags: ['Players']
		}),
		getPlayer: build.query({
			query: ({ uid }) => `tennis/players/${uid}/`,
			providesTags: ['Player']
		}),
		addPlayer: build.mutation({
			query: ({ body }) => ({
				url: 'tennis/players/',
				method: 'POST',
				body
			}),
			invalidatesTags: ['Players']
		}),
		updatePlayer: build.mutation({
			query: ({ body, player_uid }) => ({
				url: `tennis/players/${player_uid}/`,
				method: 'PATCH',
				body
			}),
			invalidatesTags: ['Players', 'Player', 'Tournament']
		}),
		removePlayer: build.mutation({
			query: ({ player_uid }) => ({
				url: `tennis/players/${player_uid}/`,
				method: 'DELETE'
			}),
			invalidatesTags: ['Players']
		}),
	})
})

export const {
	useGetPlayersQuery,
	useGetPlayerQuery,
	useAddPlayerMutation,
	useUpdatePlayerMutation,
	useRemovePlayerMutation
} = playersApi