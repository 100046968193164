import { memo } from 'react'
import { Link } from 'react-router-dom'

import './ArrowLinkBlock.scss'
import { ReactComponent as ArrowBackIcon } from '../../icons/arrow_back.svg'

function ArrowLinkBlock({ children, to, borderTop, borderBottom, onClick, light }) {
	function getStyle() {
		let style = {}

		if (borderTop) {
			style = { ...style, borderTopWidth: '1px' }
		}

		if (borderBottom) {
			style = { ...style, borderBottomWidth: '1px' }
		}

		return style
	}

	return (
		<Link
			onClick={onClick}
			to={to}
			className="arrow-link"
			style={getStyle()}
		>
			{children}

			{
				to &&
				<ArrowBackIcon
					className="arrow-back__icon"
					style={{
						fill: light ? 'var(--palette-live-grey-7)' : 'var(--palette-live-grey-1)'
					}}
				/>
			}
		</Link>
	)
}

export default memo(ArrowLinkBlock)