import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import './AdditionPlayersByList.scss'
import { selectCommunity, selectParticipants, selectTournament } from '../../redux/selectors'
import InputLight from '../../reusableComponents/InputLight/InputLight'
import { setAddByList, setAddedParticipants, setAddedPlayersByList, setParticipants } from '../../redux/tournamentParticipantsService/tournamentParticipantsSlice'
import { useAddPlayerMutation } from '../../redux/playersService/playersApiSlice'
import { getParticipantsWithNewPlayer } from '../../utils/functions2'
import { NEW_PLAYER_BY_LIST, TYPE_DOUBLES, TYPE_SINGLES } from '../../utils/constants'
import RoundedButton from '../../reusableComponents/RoundedButton/RoundedButton'

import { getDoubles, validateNewPLayersList } from './externalFunctions'

function AdditionPlayersByList({ communtyPlayers }) {
	const dispatch = useDispatch()
	const { communityUid } = useSelector(selectCommunity)
	const { addedPlayersByList, participants } = useSelector(selectParticipants)
	const { players, doubles, type } = useSelector(selectTournament)

	const [createPlayer] = useAddPlayerMutation()

	const [currentPlayer, setCurrentPlayer] = useState({
		index: 0,
		value: ''
	})
	const [requestIsActive, setRequestIsActive] = useState(false)

	function handleOnChange(e) {
		const value = e.target.value

		const playerObj = {
			...currentPlayer,
			value: !value.length ? '' : value
		}

		setCurrentPlayer(playerObj)

		const updatedList = [...addedPlayersByList]
		playerObj.error = ''
		playerObj.inCommunity = false
		playerObj.isBusy = false

		updatedList[currentPlayer.index] = playerObj
		dispatch(setAddedPlayersByList(updatedList))
	}

	function handleOnClick(inputIndex) {
		const player = addedPlayersByList[inputIndex].value

		setCurrentPlayer({
			index: inputIndex,
			value: player
		})

		addNewInput()
	}

	function addNewInput() {
		const inputsArrayLegth = addedPlayersByList.length
		const emptyInputs = addedPlayersByList.filter(item => item.value.length < 1)

		if (inputsArrayLegth < 2 || emptyInputs.length < 2) {
			const updatedList = [...addedPlayersByList, NEW_PLAYER_BY_LIST]
			dispatch(setAddedPlayersByList(updatedList))
		}
	}

	function addInTournamentList(futureParticipants) {
		let updatedParticipants = futureParticipants

		if (type === TYPE_DOUBLES) {
			// фомируем пары если турнир парный
			const { pairs } = getDoubles(futureParticipants)
			updatedParticipants = pairs
		}

		dispatch(setParticipants({
			participants: [...participants, ...updatedParticipants]
		}))

		updateUserAction(updatedParticipants)
	}

	function updateUserAction(futureParticipants) {
		futureParticipants.forEach(player => {
			dispatch(setAddedParticipants({
				participants: players || doubles || [],
				participant: player,
				type: type === TYPE_SINGLES ? TYPE_SINGLES : TYPE_DOUBLES
			}))
		})
	}

	async function saveInDB(player, participantsState) {
		const [lastName, firstName, middleName] = player.value.split(' ')

		const body = {
			last_name: lastName,
			first_name: firstName ? firstName : '',
			middle_name: middleName ? middleName : '',
			community_uids: [communityUid]
		}

		try {
			const response = await createPlayer({ body }).unwrap()

			const { newPlayer } = getParticipantsWithNewPlayer(response, participantsState)

			return newPlayer
		} catch (e) {
			setRequestIsActive(false)
			console.log('save player in DB catch error', e)
		}
	}

	async function addPlayers() {
		const { checkedList, isValid, foundInCommunity } =
			validateNewPLayersList(addedPlayersByList, participants, type, communtyPlayers)

		if (!isValid) {
			dispatch(setAddedPlayersByList(checkedList))
			return
		}

		setRequestIsActive(true)
		let futureParticipants = []

		for (let i = 0; i < checkedList.length; i++) {
			const player = checkedList[i]

			if (player.inCommunity) {
				// если игрок существует в сообществе и не здействован в турнир
				const firstFoundedInCommunity = foundInCommunity[`${player.index}`]
				futureParticipants = [...futureParticipants, firstFoundedInCommunity[0]]
				continue
			}

			// если игрока еще нет в сообществе
			const newPlayer = await saveInDB(player, futureParticipants)
			futureParticipants = [...futureParticipants, newPlayer]
		}

		addInTournamentList(futureParticipants)

		setRequestIsActive(false)
		dispatch(setAddByList(false))
		dispatch(setAddedPlayersByList([NEW_PLAYER_BY_LIST]))
	}

	return (
		<div className="addition-players-bylist">
			<div className="addition-players-bylist__inputs">
				{
					addedPlayersByList.map((player, index) => (
						<InputLight
							key={index}
							value={currentPlayer.index === index ? currentPlayer.value : player.value}
							onChange={handleOnChange}
							onClick={() => handleOnClick(index)}
							maxLength={64}
							error={player.error}
							touched={true}
							styles={{
								marginBottom: player.error ? '20px' : ''
							}}
						/>
					))
				}
			</div>

			<RoundedButton
				title={'Сохранить'}
				onClick={addPlayers}
				loading={requestIsActive}
			/>
		</div>
	)
}

export default AdditionPlayersByList