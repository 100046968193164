import { memo } from 'react'

import './TournamentYearBlock.scss'

function TournamentYearBlock({ month, year }) {
	const monthName = new Date(2000, month - 1, 1).toLocaleString('ru', { month: 'long' })
	const formattedDate = `${monthName} ${year}`.toUpperCase()

	return (
		<div className="tournaments-year">
			{ formattedDate }
		</div>
	)
}

export default memo(TournamentYearBlock)