import { configureStore } from '@reduxjs/toolkit'

import stageReducer from './stageService/stageSlice'
import tournamentReducer from './singleTournamentService/singleTournamentSlice'
import matchReducer from './matchService/matchSlice'
import tournamentParticipantsReducer from './tournamentParticipantsService/tournamentParticipantsSlice'
import errorReducer from './errorService/errorSlice'
import authReducer from './authService/authSlice'
import communityReducer from './communityService/communitySlice'
import communitiesReducer from './communitiesService/communitiesSlice'
import roundRobinReducer from './roundRobinService/roundRobinSlice'
import { apiSlice } from './apiSlice'

export const store = configureStore({
	reducer: {
		[apiSlice.reducerPath]: apiSlice.reducer,
		stage: stageReducer,
		tournament: tournamentReducer,
		match: matchReducer,
		participants: tournamentParticipantsReducer,
		error: errorReducer,
		auth: authReducer,
		community: communityReducer,
		communities: communitiesReducer,
		roundRobin: roundRobinReducer
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware)
})