import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { selectAuth } from '../../redux/selectors'

const Home = () => {
	const { authorized } = useSelector(selectAuth)

	return (
		<>
			{authorized ?
				<Navigate to={'/tournaments'} />
				:
				<Navigate to={'/auth'} />
			}
		</>
	)
}

export default Home
