import { apiSlice } from '../apiSlice'

export const participantsApi = apiSlice.injectEndpoints({
	reducerPath: 'participantsApi',
	tagTypes: ['Participants'],
	endpoints: (build) => ({
		addSinglePlayers: build.mutation({
			query: ({ tournament_uid, body }) =>
				({
					url: `tennis/tournaments/${tournament_uid}/players/`,
					method: 'POST',
					body
				}),
			invalidatesTags: []
		}),
		seedParticipants: build.mutation({
			query: ({ tournament_uid, body }) =>
				({
					url: `tennis/tournaments/${tournament_uid}/players/seeds/`,
					method: 'PUT',
					body
				}),
			invalidatesTags: []
		}),
		removeParticipant: build.mutation({
			query: ({ tournament_uid, body }) =>
				({
					url: `tennis/tournaments/${tournament_uid}/players/`,
					method: 'DELETE',
					body
				}),
			invalidatesTags: []
		}),
		addDoubles: build.mutation({
			query: ({ tournament_uid, body }) =>
				({
					url: `tennis/tournaments/${tournament_uid}/doubles/`,
					method: 'POST',
					body
				}),
			invalidatesTags: []
		}),
		updateDoubleParticipant: build.mutation({
			query: ({ tournament_uid, body }) =>
				({
					url: `tennis/tournaments/${tournament_uid}/doubles/`,
					method: 'PATCH',
					body
				}),
			invalidatesTags: []
		}),
		seedDoubles: build.mutation({
			query: ({ tournament_uid, body }) =>
				({
					url: `tennis/tournaments/${tournament_uid}/doubles/seeds/`,
					method: 'PUT',
					body
				}),
			invalidatesTags: []
		}),
		removeDouble: build.mutation({
			query: ({ tournament_uid, body }) =>
				({
					url: `tennis/tournaments/${tournament_uid}/doubles/`,
					method: 'DELETE',
					body
				}),
			invalidatesTags: []
		})
	})
})

export const {
	useAddSinglePlayersMutation,
	useSeedParticipantsMutation,
	useRemoveParticipantMutation,
	useAddDoublesMutation,
	useUpdateDoubleParticipantMutation,
	useSeedDoublesMutation,
	useRemoveDoubleMutation
} = participantsApi