import { memo, useState, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import SmoothCollapse from 'react-smooth-collapse'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import './TournamentResult.scss'
import { useGetTournamentResultsQuery } from '../../redux/singleTournamentService/singleTournamentApiSlice'
import { selectAuth, selectTournament } from '../../redux/selectors'
import { ReactComponent as ArrowDown } from '../../icons/arrow_down.svg'
import { getParameters } from '../../utils/functions'
import TournamentMenu from '../../reusableComponents/TournamentMenu/TournamentMenu'
import HeaderTournament from '../../reusableComponents/HeaderTournament/HeaderTournament'
import Loader from '../../reusableComponents/Loader/Loader'
import { useGetStageQuery } from '../../redux/stageService/stageApiSlice'
import { levelsHaveGroups } from '../../utils/functions2'

function TournamentResult({ categories, tournamentStartDate, tournamentName }) {
	const { t } = useTranslation()
	const { tournamentParams } = useParams()
	const { tournamentUid } = getParameters(tournamentParams)
	const { authorized } = useSelector(selectAuth)
	const { players, doubles, stages_count } = useSelector(selectTournament)
	const [showParticipantsList, setShowParticipantsList] = useState(true)

	const {
		data: tournamentResult = {}, status
	} = useGetTournamentResultsQuery({ tournament_uid: tournamentUid }, { refetchOnMountOrArgChange: true })

	const { data: stageData = {} } = useGetStageQuery({
		tournament_uid: tournamentUid,
		stageNumber: stages_count
	}, {
		skip: !stages_count || stages_count < 1
			|| stages_count < 1,
		refetchOnMountOrArgChange: stages_count > 0
	})

	const handleParticipantsList = useCallback(() => {
		setShowParticipantsList(prev => !prev)
	}, [])

	const getPlayerName = useCallback((uid) => {
		const array = players || doubles
		return array?.find((playersValue) => {
			return playersValue?.uid === uid
		})
	}, [players, doubles])

	const resultPlayers = useMemo(() => {
		if (Object.keys(tournamentResult).length !== 0 || Object.keys(stageData).length !== 0) {
			return tournamentResult?.nodes?.map((node) => {
				const participant = getPlayerName(node?.player_uid || node?.double_uid)
				return {
					name: node?.name,
					player: players ? participant : null,
					double: doubles ? participant : null
				}
			})
		}
	}, [tournamentResult, getPlayerName, players, doubles, stageData])

	if ((status === 'pending') || (status === 'uninitialized')) {
		return <Loader />
	}

	function groupsHaveNodes() {
		if (!stageData?.levels) return

		return stageData?.levels?.some(level => level.groups?.some(grp => grp?.nodes?.length > 0))
	}

	return (
		<>
			<HeaderTournament
				name={tournamentName}
				date={tournamentStartDate}
				categories={categories}
				copyLink={authorized ? true : false}
			/>

			<div className="tournament-result__container">
				<div
					className="tournament-result__participants-result"
				>
					<div
						className={`tournament-result__participants-list-header ${showParticipantsList ? 'open' : ''}`}
						onClick={handleParticipantsList}
					>
						<p className="tournament-result__participants-list-title">
							{ t('Результаты') }
						</p>

						<ArrowDown className={showParticipantsList ? 'arrow--up' : 'arrow--down'} />
					</div>

					<SmoothCollapse expanded={showParticipantsList}>
						<div
							className="tournament-result__participants-list-container"
						>
							{
								!stageData.levels || (stageData.levels && stageData.levels.length < 1)
									|| !levelsHaveGroups(stageData.levels || []) || !groupsHaveNodes() ?
									<div className="tournament-result__participants-list">
										<p className="tournament-result__participants-list-name">
											Этапы еще не сформированы
										</p>
									</div>
									:
									!players && !doubles ?
										<div className="tournament-result__participants-list">
											<p className="tournament-result__participants-list-name">
												Нет участников в турнире
											</p>
										</div>
										:
										resultPlayers?.length !== 0 ?
											resultPlayers?.map((resultPlayersValue, index) =>
												<div className="tournament-result__participants-wrapper" key={index}>
													<div className="tournament-result__participants-list-raiting-container">
														<p className="tournament-result__participants-list-raiting">
															{ index + 1 }
														</p>
													</div>

													{
														resultPlayersValue?.player ?
															<p className="tournament-result__participants-list-name">
																{ resultPlayersValue?.player?.formedName }
															</p>
															:
															resultPlayersValue?.double ?
																<div className="tournament-result__participants-list-name">
																	<p>
																		{ resultPlayersValue?.double?.player1?.formedName }
																	</p>

																	<p>
																		{ resultPlayersValue?.double?.player2?.formedName }
																	</p>
																</div>
																:
																<p className="tournament-result__participants-list-name">
																	{ resultPlayersValue?.name }
																</p>
													}

													{/* <p className="tournament-result__participants-list-result-container">
														{ index + 1 }
													</p> */}
												</div>
											)
											:
											<div className="tournament-result__participants-list">
												<p className="tournament-result__participants-list-name">
													Результаты не определены
												</p>
											</div>
							}
						</div>
					</SmoothCollapse>
				</div>

				{/* <div className="tournament-result__description-container">
					<p className="tournament-result__description-title">
						{ t('Description of the tournament') }
					</p>

					<div className="tournament-result__description-block">
						{
							tournamentResult?.report ?
								<p className="description-block__text">
									{ tournamentResult?.report }
								</p>
								:
								<p className="description-block__text">
									{ t('No description') }
								</p>
						}
					</div>
				</div> */}
			</div>

			<TournamentMenu />
		</>
	)
}

export default memo(TournamentResult)